export const webServerLive = true;

// ppc version 2.1.1 - 24-02-2025:Now time in 24h format

const currentDate = new Date();
const year = currentDate.getFullYear();
const hours = String(currentDate.getHours()).padStart(2, '0');
const minutes = String(currentDate.getMinutes()).padStart(2, '0');

export const ppcVersion = `2.1.2 - 2025-02-24-9:59`; 
// export const ppcVersion = `2.1.2 - ${year}:${hours}:${minutes}`; \


