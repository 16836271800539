import { AuthContextProvider } from "./Hooks/useAuth";
// import SidebarComponent from "./Components/Shared/SidebarComponent"
import "./styles/bootstrap.scss";
import Main from "./Views/Main";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./reset.scss";
import "./styles/index.scss";

function App() {
    return (
        <>
            <AuthContextProvider>
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                {/* <WebConfigContextProvider> */}
                    <Main />
                {/* </WebConfigContextProvider> */}
            </AuthContextProvider>
        </>
    );
}

export default App;
