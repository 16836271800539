import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Table,
    Button,
    Badge,
} from "react-bootstrap";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import {
    testingReportsCollectionRef,
    testingStatusCollectionRef,
} from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import { format } from "date-fns";
import EditGameReviewModal from "./EditGameReviewModal";
import ViewGameReviewModal from "./ViewGameReviewModal";
import "./ViewGameTesting.scss";
import { useCollectionData } from "react-firebase-hooks/firestore";
const ViewGameTesting = () => {
    const { currentUser } = useAuth();
    const [reports, setReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedWeek, setSelectedWeek] = useState("all");
    const [selectedStatus, setSelectedStatus] = useState("all");
    const [uniqueWeeks, setUniqueWeeks] = useState([]);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [filters, setFilters] = useState({
        dateRange: {
            start: null,
            end: null,
        },
        status: "all",
    });
    const [fetchtTestingReport, fetchLoading, fetchError] = useCollectionData(
        testingReportsCollectionRef
            .where("assignedTo.email", "==", currentUser.email)
            .orderBy("createdAt", "desc"),
        { idField: "id" }
    );
    // Replace the useEffect fetch with real-time listener
    useEffect(() => {
        // Create real-time listener for reports
        if(fetchtTestingReport && fetchtTestingReport.length > 0){
            const weeks = [
                ...new Set(
                    fetchtTestingReport.map((report) => report.weekRange)
                ),
            ];
            setUniqueWeeks(weeks);
            setReports(fetchtTestingReport);
            setLoading(false);
        }
        // const unsubscribeReports = testingReportsCollectionRef
        //     .where("assignedTo.email", "==", currentUser.email)
        //     .orderBy("createdAt", "desc")
        //     .onSnapshot(
        //         async (reportsSnapshot) => {
        //             try {
        //                 const reportsData = await Promise.all(
        //                     reportsSnapshot.docs.map(async (doc) => {
        //                         const reportData = doc.data();

        //                         // Get status data
        //                         const statusSnapshot =
        //                             await testingStatusCollectionRef
        //                                 .where("reportId", "==", doc.id)
        //                                 .get();

        //                         let statusData = null;
        //                         if (!statusSnapshot.empty) {
        //                             statusData = statusSnapshot.docs[0].data();
        //                         }

        //                         return {
        //                             id: doc.id,
        //                             ...reportData,
        //                             statusData,
        //                         };
        //                     })
        //                 );

        //                 // Extract unique weeks
        //                 const weeks = [
        //                     ...new Set(
        //                         reportsData.map((report) => report.weekRange)
        //                     ),
        //                 ];
        //                 setUniqueWeeks(weeks);
        //                 setReports(reportsData);
        //                 setLoading(false);
        //             } catch (error) {
        //                 console.error("Error processing reports:", error);
        //                 setLoading(false);
        //             }
        //         },
        //         (error) => {
        //             console.error("Error fetching reports:", error);
        //             setLoading(false);
        //         }
        //     );

        // // Cleanup function to unsubscribe from listeners
        // return () => {
        //     unsubscribeReports();
        // };
    }, [currentUser.email, fetchtTestingReport]);

    // Filter reports based on selected week and status
    const filteredReports = reports.filter((report) => {
        const weekMatch =
            selectedWeek === "all" || report.weekRange === selectedWeek;
        const statusMatch =
            selectedStatus === "all" || report.status === selectedStatus;
        return weekMatch && statusMatch;
    });

    // Filter by date range
    let filtered = filteredReports;
    if (filters.dateRange.start && filters.dateRange.end) {
        filtered = filtered.filter((report) => {
            const reportDate = report.submittedAt?.toDate() || new Date();
            const startDate = new Date(filters.dateRange.start);
            const endDate = new Date(filters.dateRange.end);
            return reportDate >= startDate && reportDate <= endDate;
        });
    }

    const getStatusBadge = (status) => {
        const statusConfig = {
            pending_testing: { color: "warning", text: "Pending Testing" },
            test_report_available: {
                color: "info",
                text: "Test Report Available",
            },
            assigned_to_developer: {
                color: "primary",
                text: "Assigned to Developer",
            },
            tested_and_resolved: {
                color: "success",
                text: "Tested and Resolved",
            },
        };

        const config = statusConfig[status] || {
            color: "secondary",
            text: status,
        };
        return <Badge bg={config.color}>{config.text}</Badge>;
    };

    const handleEdit = (report) => {
        setSelectedReport(report);
        setShowEditModal(true);
    };

    const handleView = (report) => {
        setSelectedReport(report);
        setShowViewModal(true);
    };

    const handleCloseEditModal = () => {
        setShowEditModal(false);
        setSelectedReport(null);
    };

    const handleCloseViewModal = () => {
        setShowViewModal(false);
        setSelectedReport(null);
    };

    return (
        <SidebarComponent>
            <section className="page-heading">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="mb-0">Game/App Testing Reports</h3>
                    </div>
                </div>
            </section>
            <div className="view-game-testing">
                <section className="py-4">
                    <Container>
                        <Row className="filters-section">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Select Week</Form.Label>
                                    <Form.Select
                                        value={selectedWeek}
                                        onChange={(e) =>
                                            setSelectedWeek(e.target.value)
                                        }
                                    >
                                        <option value="all">All Weeks</option>
                                        {uniqueWeeks.map((week) => (
                                            <option key={week} value={week}>
                                                {week}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Select Status</Form.Label>
                                    <Form.Select
                                        value={selectedStatus}
                                        onChange={(e) =>
                                            setSelectedStatus(e.target.value)
                                        }
                                    >
                                        <option value="all">All Status</option>
                                        <option value="pending_testing">
                                            Pending Testing
                                        </option>
                                        <option value="test_report_available">
                                            Test Report Available
                                        </option>
                                        <option value="assigned_to_developer">
                                            Assigned to Developer
                                        </option>
                                        <option value="tested_and_resolved">
                                            Tested and Resolved
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        {loading ? (
                            <div className="text-center py-4">Loading...</div>
                        ) : (
                            <Table
                                responsive
                                striped
                                bordered
                                hover
                                className="reports-table"
                            >
                                <thead>
                                    <tr>
                                        <th>Week Range</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th>Last Updated</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredReports.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan="5"
                                                className="text-center py-4"
                                            >
                                                No reports found
                                            </td>
                                        </tr>
                                    ) : (
                                        filteredReports.map((report) => (
                                            <tr key={report.id}>
                                                <td>{report.weekRange}</td>
                                                <td>
                                                    {getStatusBadge(
                                                        report.status
                                                    )}
                                                </td>
                                                <td>
                                                    {report.submittedAt?.toDate()
                                                        ? format(
                                                              report.submittedAt.toDate(),
                                                              "MMM d, yyyy"
                                                          )
                                                        : "No date"}
                                                </td>
                                                <td>
                                                    {format(
                                                        report.lastUpdated.toDate(),
                                                        "MMM d, yyyy"
                                                    )}
                                                </td>
                                                <td className="action-buttons">
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={() =>
                                                            handleEdit(report)
                                                        }
                                                    >
                                                        Edit Report
                                                    </Button>
                                                    <Button
                                                        variant="outline-info"
                                                        size="sm"
                                                        onClick={() =>
                                                            handleView(report)
                                                        }
                                                    >
                                                        View Report
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        )}
                    </Container>
                </section>

                {selectedReport && showEditModal && (
                    <EditGameReviewModal
                        show={showEditModal}
                        onHide={handleCloseEditModal}
                        report={selectedReport}
                    />
                )}

                {selectedReport && showViewModal && (
                    <ViewGameReviewModal
                        show={showViewModal}
                        onHide={handleCloseViewModal}
                        report={selectedReport}
                    />
                )}
            </div>
        </SidebarComponent>
    );
};

export default ViewGameTesting;
