export const weeklyReportReminderMessage = (data) => {
    const mailSubject = `Weekly Progress Report | Please Submit Your Updates within 24 hours | MyelinZ HR`;
    const lineOne = `<p>Hello team,</p>`;
    const lineTwo = `<p>It's time to submit your weekly progress report. Please log into your PPC account and fill out the report for the week. Your report must be in bullet points and include the following:</p>`;
    const lineThree = `<ul> 
    <li>Five important measurable achievements from the week (use links to show demos)</li>
    <li>Three achievements you are most proud of from the week</li>
    <li>What could you have done differently?</li>
    <li>Your top three objectives for the following week</li>
    </ul>`;

    const lineFour = `<p>You can complete the report directly on the PPC platform. You have 24 hours from the date of receiving this email to fill out the report.</p>`;
    const lineFive = `<p>Once submitted, your manager will review and score your report, providing feedback as needed.</p>`;
    const lineSix = `<p>Please visit: <a href="https://ppc.myelinz.com/weekly-report" target="_blank" rel="noopener noreferrer">https://ppc.myelinz.com/weekly-report</a>
    <br/><br/>Thank you!</p> <p><br/><br/> Regards, <br/>MyelinZ HR</p> `;

    const mailBody = lineOne + lineTwo + lineThree + lineFour + lineFive + lineSix;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};

// monthly score message update
export const monthlyScoreMessage = (data) => {
    const mailSubject = `Monthly Score Report | MyelinZ PPC`;
    const lineOne = `<p>Hello ${data.name},</p>`;
    const lineTwo = `<p>Your final monthly score is ${data.score}/20. Your line manager will be in touch regarding the next steps.</p>`;
    const lineThree = `<p>The MyelinZ Team</p>`;
    const mailBody = lineOne + lineTwo + lineThree;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};

export const weeklyReportReviewedMessage = (data) => {
    const mailSubject = `Weekly Progress Report Reviewed | MyelinZ PPC`;
    const lineOne = `<p>Hello ${data.name},</p>`;
    const lineTwo = `<p>Your weekly progress report has been reviewed, and scored ${data.score}/20. Please log into your PPC account and view the report.</p>`;
    
    const lineThree = `<p>The MyelinZ Team</p>`;
    const mailBody = lineOne + lineTwo + lineThree;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};

export const gameTestingReminderMessage = (data) => {
    const mailSubject = `Game/App Testing Reminder | MyelinZ PPC`;
    const lineOne = `<p>Hello ${data.name},</p>`;
    const lineTwo = `<p>It's time to submit your Game/App Testing report. Please log into your PPC account and fill out the report for the week.</p>`;
    const lineThree = `<p>Log into your PPC account at <a href="https://ppc.myelinz.com/game-testing" target="_blank" rel="noopener noreferrer">https://ppc.myelinz.com/game-testing</a></p>`;

    const lineFour = `<p>You can complete the report directly on the PPC platform. You have 24 hours from the date of receiving this email to fill out the report.</p>`;
    const lineFive = `<p>Thank you!</p>`;

    const mailBody = lineOne + lineTwo + lineThree + lineFour + lineFive;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};


export const gameTestingReportMessage = (data) => {
    const mailSubject = `Game/App Testing Report | ${data.name}`;
    const lineOne = `<p>Dear concerned,</p>`;
    const lineTwo = `<p>The Game/App Testing report is ready. Please log into your PPC account and view the report.</p>`;
    const lineThree = `<p>Log into your PPC account at <a href="https://ppc.myelinz.com/game-testing" target="_blank" rel="noopener noreferrer">https://ppc.myelinz.com/game-testing</a></p>`;
    const lineFour = `<p>Thank you!</p>`;

    const mailBody = lineOne + lineTwo + lineThree + lineFour;
    const mailDetails = { ...data, mailBody, mailSubject };

    return mailDetails;
};
