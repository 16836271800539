import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";
import { assignedTasksCollectionRef } from "../../firebase/fire-app";
import CompTaskCardEmployee from "../AdminAssignTask/CompTaskCardEmployee";
import { Button, Spinner, Alert, Badge } from "react-bootstrap";

const ViewMyTasks = () => {
    const { currentUser } = useAuth();
    const [empEmail, setEmpEmail] = useState("");
    const [liveStatus, setLiveStatus] = useState("active");
    
    // Task data state
    const [tasksList, setTasksList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    // Pagination state
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [itemsPerPage] = useState(10);
    
    // Task counts for display
    const [taskCounts, setTaskCounts] = useState({
        new: 0,
        inProgress: 0,
        completed: 0
    });

    useEffect(() => {
        if (currentUser) {
            setEmpEmail(currentUser.email);
        }
    }, [currentUser]);

    // Initial load of tasks
    useEffect(() => {
        if (empEmail) {
            fetchTasks();
        }
    }, [empEmail, liveStatus]);

    // Update task counts when list changes
    useEffect(() => {
        if (tasksList) {
            setTaskCounts({
                new: tasksList.filter(item => item.status === "New task").length,
                inProgress: tasksList.filter(item => item.status === "In progress").length,
                completed: tasksList.filter(item => item.status === "Completed").length
            });
        }
    }, [tasksList]);

    const fetchTasks = async (loadMore = false) => {
        try {
            if (!empEmail) {
                setError("User email not found. Please try again later.");
                setLoading(false);
                return;
            }

            if (loadMore) {
                setLoadingMore(true);
            } else {
                setLoading(true);
                setError(null);
            }

            // Create a query that filters tasks by assignees array
            let query = assignedTasksCollectionRef
                .orderBy("createdAt", "desc")
                .where("liveStatus", "==", liveStatus)
                .where("assignees", "array-contains", empEmail);
            
            // Add pagination
            if (loadMore && lastVisible) {
                query = query.startAfter(lastVisible);
            }
            
            // Limit the number of documents fetched
            query = query.limit(itemsPerPage);
            
            const snapshot = await query.get();
            
            if (snapshot.empty && !loadMore) {
                setTasksList([]);
                setHasMore(false);
                setLoading(false);
                setLoadingMore(false);
                return;
            }

            if (snapshot.empty && loadMore) {
                setHasMore(false);
                setLoadingMore(false);
                return;
            }

            // Get the last document for pagination
            const lastDoc = snapshot.docs[snapshot.docs.length - 1];
            setLastVisible(lastDoc);
            
            // Check if there are more documents
            const moreQuery = assignedTasksCollectionRef
                .orderBy("createdAt", "desc")
                .where("liveStatus", "==", liveStatus)
                .where("assignees", "array-contains", empEmail)
                .startAfter(lastDoc)
                .limit(1);
                
            const moreSnapshot = await moreQuery.get();
            setHasMore(!moreSnapshot.empty);
            
            // Map the documents to data objects
            const tasksData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            // Update the tasks list
            if (loadMore) {
                setTasksList(prev => [...prev, ...tasksData]);
            } else {
                setTasksList(tasksData);
            }
        } catch (err) {
            console.error("Error fetching tasks:", err);
            setError("Failed to load tasks. Please try again.");
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    const loadMoreTasks = () => {
        if (!hasMore || loadingMore) return;
        fetchTasks(true);
    };

    // Filter tasks by status
    const filterTasksByStatus = (status) => {
        return tasksList.filter(item => item.status === status);
    };

    // Loading state
    if (loading && !loadingMore) {
        return (
            <SidebarComponent>
                <section className="sticky-top page-heading">
                    <div className="container">
                        <h3>My Tasks</h3>
                    </div>
                </section>
                <div className="container mt-5 text-center">
                    <Spinner animation="border" role="status" />
                    <p className="mt-3">Loading your tasks...</p>
                </div>
            </SidebarComponent>
        );
    }

    return (
        <SidebarComponent>
            <section className="sticky-top page-heading">
                <div className="container">
                    <h3>My Tasks</h3>
                </div>
            </section>
            
            {error && (
                <div className="container mt-3">
                    <Alert variant="danger">{error}</Alert>
                </div>
            )}
            
            <div className="container mt-3 d-none d-md-block">
                <div className="row">
                    <div className="col-md-4">
                        <h5 className="mb-0 font-bold py-">
                            New Tasks <Badge bg="danger">{taskCounts.new}</Badge>
                        </h5>
                    </div>
                    <div className="col-md-4">
                        <h5 className="mb-0 font-bold py-">
                            In progress <Badge bg="warning" text="dark">{taskCounts.inProgress}</Badge>
                        </h5>
                    </div>
                    <div className="col-md-4">
                        <h5 className="mb-0 font-bold py-">
                            Completed <Badge bg="primary">{taskCounts.completed}</Badge>
                        </h5>
                    </div>
                </div>
            </div>
            
            <section className="task-container pt-4 bg-light mt-3">
                <div className="container">
                    {tasksList.length === 0 && !loading ? (
                        <div className="text-center py-5">
                            <p className="mb-0">No tasks assigned to you.</p>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-4">
                                {filterTasksByStatus("New task").length > 0 ? (
                                    filterTasksByStatus("New task").map((item) => (
                                        <CompTaskCardEmployee
                                            data={item}
                                            key={item.id}
                                            empEmail={empEmail}
                                        />
                                    ))
                                ) : (
                                    <div className="p-3 text-center text-muted">
                                        <p>No new tasks</p>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-4">
                                {filterTasksByStatus("In progress").length > 0 ? (
                                    filterTasksByStatus("In progress").map((item) => (
                                        <CompTaskCardEmployee
                                            data={item}
                                            key={item.id}
                                            empEmail={empEmail}
                                        />
                                    ))
                                ) : (
                                    <div className="p-3 text-center text-muted">
                                        <p>No tasks in progress</p>
                                    </div>
                                )}
                            </div>
                            <div className="col-md-4">
                                {filterTasksByStatus("Completed").length > 0 ? (
                                    filterTasksByStatus("Completed").map((item) => (
                                        <CompTaskCardEmployee
                                            data={item}
                                            key={item.id}
                                            empEmail={empEmail}
                                        />
                                    ))
                                ) : (
                                    <div className="p-3 text-center text-muted">
                                        <p>No completed tasks</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    
                    {hasMore && (
                        <div className="text-center py-4">
                            <Button 
                                variant="outline-primary" 
                                onClick={loadMoreTasks}
                                disabled={loadingMore}
                            >
                                {loadingMore ? (
                                    <>
                                        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                                        Loading more...
                                    </>
                                ) : (
                                    'Load More Tasks'
                                )}
                            </Button>
                        </div>
                    )}
                </div>
            </section>
        </SidebarComponent>
    );
};

export default ViewMyTasks;
