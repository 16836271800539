import React, { useState, useEffect } from "react";
import { Form, Button, Card, Alert, Badge } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./WeeklyReportForm.scss";
import { format } from "date-fns";

const WeeklyReportForm = ({
    selectedWeek,
    onSubmit,
    existingReport = null,
    isSubmitting,
    submitSuccess,
    reportStatus,
    isEditable,
}) => {


    const [formData, setFormData] = useState({
        measurableAchievements: "",
        proudAchievements: "",
        improvements: "",
        objectives: "",
    });

    useEffect(() => {
        // Reset form when selectedWeek or existingReport changes
        if (existingReport) {
            setFormData({
                measurableAchievements:
                    existingReport.measurableAchievements || "",
                proudAchievements: existingReport.proudAchievements || "",
                improvements: existingReport.improvements || "",
                objectives: existingReport.objectives || "",
            });
        } else {
            // Clear form when no existing report
            setFormData({
                measurableAchievements: "",
                proudAchievements: "",
                improvements: "",
                objectives: "",
            });
        }
    }, [existingReport, selectedWeek]); // Added selectedWeek as dependency

    const handleQuillChange = (field) => (content) => {
        setFormData((prev) => ({
            ...prev,
            [field]: content,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Clean up empty fields (replace empty Quill content with empty string)
        const cleanedFormData = Object.entries(formData).reduce(
            (acc, [key, value]) => {
                acc[key] = value === "<p><br></p>" ? "" : value;
                return acc;
            },
            {}
        );

        onSubmit(cleanedFormData);
    };

    const modules = {
        toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            ["clean"],
        ],
    };

    const formats = [
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "list",
        "bullet",
    ];

    const isFormEmpty = Object.values(formData).every(
        (value) => !value || value === "<p><br></p>"
    );

    const getStatusMessage = () => {
        if (!isEditable) {
            if (reportStatus === "reviewed") {
                return (
                    <Alert variant="info" className="mb-4">
                        This report has been reviewed and cannot be edited.
                    </Alert>
                );
            } else if (!existingReport) {
                return (
                    <Alert variant="warning" className="mb-4">
                        You can submit a report for this week.
                    </Alert>
                );
            }
        } else if (reportStatus === "pending") {
            return (
                <Alert variant="warning" className="mb-4">
                    This report is pending review. You can still make changes.
                </Alert>
            );
        }
        return null;
    };

    return (
        <Card className="weekly-report-form">
            <Card.Body>
                {getStatusMessage()}
                {isEditable && (
                    <Form onSubmit={handleSubmit}>
                        <section className="form-section">
                            <h4>Five Measurable Achievements</h4>
                            <p className="text-muted mb-3">
                                List your five key measurable achievements for
                                this week
                            </p>
                            {isEditable && (
                                <ReactQuill
                                    value={formData.measurableAchievements}
                                    onChange={handleQuillChange(
                                        "measurableAchievements"
                                    )}
                                    modules={
                                        isEditable
                                            ? modules
                                            : { toolbar: false }
                                    }
                                    formats={formats}
                                    className={`quill-editor ${
                                        !isEditable ? "readonly" : ""
                                    }`}
                                    readOnly={!isEditable}
                                    preserveWhitespace={true}
                                />
                            )}
                        </section>

                        <section className="form-section">
                            <h4>Three Achievements You Are Most Proud Of</h4>
                            <p className="text-muted mb-3">
                                Describe three achievements that you're
                                particularly proud of
                            </p>
                            {isEditable && (
                                <ReactQuill
                                    value={formData.proudAchievements}
                                    onChange={handleQuillChange(
                                        "proudAchievements"
                                    )}
                                    modules={
                                        isEditable
                                            ? modules
                                            : { toolbar: false }
                                    }
                                    formats={formats}
                                    className={`quill-editor ${
                                        !isEditable ? "readonly" : ""
                                    }`}
                                    readOnly={!isEditable}
                                    preserveWhitespace={true}
                                />
                            )}
                        </section>

                        <section className="form-section">
                            <h4>What Could You Have Done Differently?</h4>
                            <p className="text-muted mb-3">
                                Reflect on areas for improvement and what could
                                have been done better
                            </p>
                            {isEditable && (
                                <ReactQuill
                                    value={formData.improvements}
                                    onChange={handleQuillChange("improvements")}
                                    modules={
                                        isEditable
                                            ? modules
                                            : { toolbar: false }
                                    }
                                    formats={formats}
                                    className={`quill-editor ${
                                        !isEditable ? "readonly" : ""
                                    }`}
                                    readOnly={!isEditable}
                                    preserveWhitespace={true}
                                />
                            )}
                        </section>

                        <section className="form-section">
                            <h4>Your Top Three Objectives</h4>
                            <p className="text-muted mb-3">
                                List your main objectives for the upcoming week
                            </p>
                            {isEditable && (
                                <ReactQuill
                                    value={formData.objectives}
                                    onChange={handleQuillChange("objectives")}
                                    modules={
                                        isEditable
                                            ? modules
                                            : { toolbar: false }
                                    }
                                    formats={formats}
                                    className={`quill-editor ${
                                        !isEditable ? "readonly" : ""
                                    }`}
                                    readOnly={!isEditable}
                                    preserveWhitespace={true}
                                />
                            )}
                        </section>

                        {isEditable && (
                            <Button
                                variant="primary"
                                type="submit"
                                className="submit-button"
                                disabled={isSubmitting || isFormEmpty}
                            >
                                {isSubmitting
                                    ? "Submitting..."
                                    : existingReport
                                    ? "Update Report"
                                    : "Submit Report"}
                            </Button>
                        )}
                    </Form>
                )}
                {!isEditable && (
                    <div className="report-display">
                        {existingReport?.status === 'reviewed' && (
                            <Alert variant="info" className="reviewer-info mb-4">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <strong>Reviewed by:</strong> {existingReport.reviewer?.name || 'Unknown'}
                                    </div>
                                    <Badge bg="primary" className="score-badge">
                                        Score: {existingReport.score}/20
                                    </Badge>
                                </div>
                                {existingReport.feedback && (
                                    <div className="mt-2">
                                        <strong>Feedback:</strong>
                                        <p className="mb-0">{existingReport.feedback}</p>
                                    </div>
                                )}
                                <div className="mt-2 text-muted">
                                    <small>
                                        Reviewed on: {format(existingReport.reviewedAt.toDate(), 'MMM d, yyyy')}
                                    </small>
                                </div>
                            </Alert>
                        )}
                        <h4>Five Measurable Achievements</h4>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: formData.measurableAchievements,
                            }}
                        />
                        <h4>Three Achievements You Are Most Proud Of</h4>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: formData.proudAchievements,
                            }}
                        />
                        <h4>What Could You Have Done Differently?</h4>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: formData.improvements,
                            }}
                        />
                        <h4>Your Top Three Objectives</h4>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: formData.objectives,
                            }}
                        />  
                        
                        
                        
                    </div>
                )}
            </Card.Body>
        </Card>
    );
};

export default WeeklyReportForm;
