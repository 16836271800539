import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { GoHome } from "react-icons/go";
import { FaHome } from "react-icons/fa";
import dashboardMenu from "../../Views/ViewDashboard.js/DataDashboardMenu";
import { Link, useLocation } from "react-router-dom";
import adminSidebarMenus from "../../Views/ViewDashboard.js/DataAdminMenus";
import { useAuth } from "../../Hooks/useAuth";
import SectionLoading from "../SectionLoading";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "./responsive.scss";
import { BsArrowLeft } from "react-icons/bs";
import { HiMenuAlt1 } from "react-icons/hi";
import { webServerLive } from "../../firebase/liveServerStatus";

const SidebarComponent = ({ children }) => {
    const { currentUser, isAdmin, setIsAdmin, showSidebar, setShowSidebar } =
        useAuth();
    const onLogOut = () => {
        firebase
            .auth()
            .signOut()
            .then((res) => {
                sessionStorage.removeItem("token");
                setIsAdmin(false);
                window.location.href = "/";
            })
            .catch((err) => {
                alert(err);
            });
    };
    const [sidebarActiveClass, setSidebarActiveClass] = useState(false);

    const onToggleSidebar = () => {
        setSidebarActiveClass(!sidebarActiveClass);
    };

    // useEffect(() => {
    //     setSidebarActiveClass(showSidebar)
    // }, [showSidebar])

    return (
        <>
            <button
                onClick={onToggleSidebar}
                className="btn font-30 px-2 py-2 light-shadow btn-primary btn-customized open-menu"
            >
                {/* just commented */}
                <i className="fas fa-align-left"></i>{" "}
                <span>
                    <HiMenuAlt1 />
                </span>
            </button>

            {currentUser ? (
                <main className="d-flex flex-nowrap">
                    <div
                        className={` px-1 py-3 ${
                            webServerLive ? "bg-primary" : "bg-info"
                        } sidebar-container ${sidebarActiveClass && "active"}`}
                    >
                        <div className="d-flex justify-content-between">
                            <a
                                href="/"
                                className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
                            >
<img src="https://www.myelinz.com/myelinz-logo-white.svg" alt="" className="img-fluid" />
                            </a>
                            <button
                                onClick={onToggleSidebar}
                                className="close-menu light-shadow d-md-none btn-sm px-3 py-0 btn-white btn"
                            >
                                <BsArrowLeft />
                            </button>
                        </div>
                        <hr />
                        <ul className="nav nav-pills flex-column mb-auto">
                            {dashboardMenu.map((item) => (
                                <SidebarItem key={item.id} data={item} />
                            ))}
                            <hr />
                            {isAdmin &&
                                adminSidebarMenus.map((item) => (
                                    <SidebarItem key={item.id} data={item} />
                                ))}
                        </ul>
                        <button
                            onClick={onLogOut}
                            className="btn btn-sm btn-white"
                        >
                            Logout: {currentUser.empID}
                        </button>
                        {/* <div className="dropdown">
                        <a href="#" className="d-flex align-items-center text-white text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
                            <strong>mdo</strong>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                            <li><a className="dropdown-item" href="#">New project...</a></li>
                            <li><a className="dropdown-item" href="#">Settings</a></li>
                            <li><a className="dropdown-item" href="#">Profile</a></li>
                            <li><hr className="dropdown-divider" /></li>
                            <li><a className="dropdown-item" href="#">Sign out</a></li>
                        </ul>
                    </div> */}
                    </div>

                    <div className="b-example-divider b-example-vr"></div>
                    <div className="site-content pt-5 pt-md-0">{children}</div>
                </main>
            ) : (
                <SectionLoading />
            )}
        </>
    );
};

export default SidebarComponent;

const SidebarItem = ({ data }) => {
    const location = useLocation();
    const [activeMenu, setActiveMenu] = useState(false);

    useEffect(() => {
        if (location.pathname === data.href) {
            setActiveMenu(true);
        } else {
            setActiveMenu(false);
        }
    }, [location]);

    return (
        <li className="nav-item mb-0    ">
            <Link
                to={`${data.href}`}
                className={`nav-link font-bold ${
                    activeMenu && "active"
                } d-flex align-items-center`}
                aria-current="page"
            >
                <span className="bi pe-none me-2">
                    <GoHome />
                </span>
                {data.text}
            </Link>
        </li>
    );
};
