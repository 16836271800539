import React, { useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";
import "./hrpolicy.scss";
import EditorCultureLeadershipPrinciplePopup from "./EditorCultureLeadershipPrinciplePopup";

const ViewLeadershipCulturePrinciple = () => {
    const { isAdmin } = useAuth();
    const [pageContent, setPageContent] = useState("--");

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top page-heading">
                    <div className="container ">
                        <div className="d-flex justify-content-between">
                            <h3 className="mb-0">MyelinZ Culture & Tenets</h3>
                            <div>
                                {
                                    <EditorCultureLeadershipPrinciplePopup
                                        setPageContent={setPageContent} isAdmin={isAdmin}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light">
                    <div className="container">
                        <div className="row d-flex justify-content-center">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: pageContent,
                                }}
                                className="col-md-10 bg-white policy-details p-5"
                            ></div>
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewLeadershipCulturePrinciple;
