import React, { useState, useEffect } from "react";
import { Table, Badge, Spinner, Form } from "react-bootstrap";
import { bugReportsCollectionRef } from "../../firebase/fire-app";
import { format } from "date-fns";
import BugDetailsModal from "./BugDetailsModal";
import "./ViewAllBugs.scss";
import SidebarComponent from "../../Components/Shared/SidebarComponent";

const ViewAllBugs = () => {
    const [bugs, setBugs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [filters, setFilters] = useState({
        status: "",
        sensitivity: "",
        game: "",
    });
    const [games, setGames] = useState(new Set());

    useEffect(() => {
        const unsubscribe = bugReportsCollectionRef
            .orderBy("submittedAt", "desc")
            .onSnapshot((snapshot) => {
                const bugsData = [];
                const gamesSet = new Set();

                snapshot.forEach((doc) => {
                    const data = doc.data();
                    bugsData.push({ id: doc.id, ...data });
                    gamesSet.add(data.gameName);
                });

                setBugs(bugsData);
                setGames(gamesSet);
                setLoading(false);
            });

        return () => unsubscribe();
    }, []);

    const getSensitivityColor = (sensitivity) => {
        switch (sensitivity) {
            case "low":
                return "success";
            case "medium":
                return "warning";
            case "high":
                return "orange";
            case "critical":
                return "danger";
            default:
                return "secondary";
        }
    };

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const filteredBugs = bugs.filter((bug) => {
        return (
            (filters.status === "" || bug.status === filters.status) &&
            (filters.sensitivity === "" ||
                bug.sensitivity === filters.sensitivity) &&
            (filters.game === "" || bug.gameName === filters.game)
        );
    });

    if (loading) {
        return (
            <div className="text-center p-5">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }

    return (
        <SidebarComponent>
            <section className="sticky-top page-heading">
                <div className="container">
                    <div className="d-">
                        <h3 className="mb-0">All Bug Reports</h3>
                    </div>
                </div>
            </section>

            <div className="bug-reports-container p-4">
                <div className="filters-section mb-4">
                    <div className="row g-3">
                        <div className="col-md-4">
                            <Form.Group>
                                <Form.Label>Filter by Game</Form.Label>
                                <Form.Select
                                    name="game"
                                    value={filters.game}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All Games</option>
                                    {Array.from(games).map((game) => (
                                        <option key={game} value={game}>
                                            {game}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group>
                                <Form.Label>Filter by Status</Form.Label>
                                <Form.Select
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All Status</option>
                                    <option value="pending">Pending</option>
                                    <option value="resolved">Resolved</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                        <div className="col-md-4">
                            <Form.Group>
                                <Form.Label>Filter by Sensitivity</Form.Label>
                                <Form.Select
                                    name="sensitivity"
                                    value={filters.sensitivity}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All Sensitivity</option>
                                    <option value="low">Low</option>
                                    <option value="medium">Medium</option>
                                    <option value="high">High</option>
                                    <option value="critical">Critical</option>
                                </Form.Select>
                            </Form.Group>
                        </div>
                    </div>
                </div>

                <div className="table-responsive">
                    <Table hover className="bug-reports-table">
                        <thead>
                            <tr>
                                <th>Title</th>
                                <th>Game</th>
                                <th>Submitted By</th>
                                <th>Submitted On</th>
                                <th>Sensitivity</th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredBugs.map((bug) => (
                                <tr key={bug.id}>
                                    <td>{bug.title}</td>
                                    <td>{bug.gameName}</td>
                                    <td>{bug.submittedBy.name}</td>
                                    <td>
                                        {format(
                                            bug.submittedAt.toDate(),
                                            "MMM d, yyyy 'at' h:mm a"
                                        )}
                                    </td>
                                    <td>
                                        <Badge
                                            bg={getSensitivityColor(
                                                bug.sensitivity
                                            )}
                                        >
                                            {bug.sensitivity}
                                        </Badge>
                                    </td>
                                    <td>
                                        <Badge
                                            bg={
                                                bug.status === "pending"
                                                    ? "warning"
                                                    : "success"
                                            }
                                        >
                                            {bug.status}
                                        </Badge>
                                    </td>
                                    <td>
                                        <BugDetailsModal bug={bug} />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        </SidebarComponent>
    );
};

export default ViewAllBugs;
