import React from "react";
import { Container } from "react-bootstrap";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import "./AdminGameTesting.scss";
import GameTestingReminderNotificationModal from "./GameTestingReminderNotificationModal";
import TestingReportsList from "./TestingReportsList";
import { useAuth } from "../../Hooks/useAuth";

const AdminGameTesting = ({adminadmin}) => {
    const {isAdmin} = useAuth();

    return (
        <SidebarComponent>
            <section className="sticky-top page-heading">
                <div className="container">
                   {adminadmin ? <div className="d-flex justify-content-between align-items-center">
                        <h3 className="mb-0">Game/App Testing Dashboard</h3>
                        <GameTestingReminderNotificationModal />
                    </div> : <h3 className="mb-0">Game/App Testing Dashboard</h3>}
                </div>
            </section>

            <div className="analytics-container p-4">
                <Container fluid className="admin-game-testing">
                    {/* Weekly Reports List */}
                    <TestingReportsList />
                </Container>
            </div>
        </SidebarComponent>
    );
};

export default AdminGameTesting;
