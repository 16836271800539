import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import ReactQuill from "react-quill";
import { portalFeedbacksCollectionRef } from "../../firebase/fire-app";
import { webServerLive } from "../../firebase/liveServerStatus";
import emailjs from "@emailjs/browser";
import { assignedNewTaskMessage } from "../ViewClaimManagement/messageGenerator";
import { Spinner } from "react-bootstrap";

const PopupAddPortalFeedback = ({ currentUser }) => {
    const [show, setShow] = useState(false);
    const { register, handleSubmit, watch, errors } = useForm();
    const [content, setContent] = useState("");
    const [systemLoading, setSystemLoading] = useState(false);
    const handleChange = (value) => {
        setContent(value);
    };
    const onSubmit = (data, e) => {
        setSystemLoading(true);
        data.createdAt = new Date();
        data.lastUpdated = new Date();
        data.assignedBy = currentUser.email;
        data.assignedByName = currentUser.name;
        data.resolved = false;
        const mailData = {
            title: data.title,
            details: data.details,
            name: currentUser.name,

            status: "Approved",
            mailOne: currentUser.id,
            mailTwo: "zied.tayeb@myelinh.com",
            from_name: "MyelinZ HR",
        };
        if (!webServerLive) {
            mailData.mailOne = "shahjahan.swajan@myelinh.com";
            mailData.mailTwo = "shahjahan.swajan@myelinh.com";
        }
        const mailDetails = assignedNewTaskMessage(mailData);
        console.log(data);
        e.target.reset();

        portalFeedbacksCollectionRef.add(data).then(() => {
            setSystemLoading(false);
            // if (webServerLive) {
            //     emailjs.send(
            //         "service_zinxo6v",
            //         "template_eqvm7mf",
            //         mailDetails,
            //         "ygk5Yo8QhiJUWbVZM"
            //     );
            // }
            setShow(false);
        });

        // alert(JSON.stringify(data));
    };
    return (
        <>
            {currentUser ? (
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => setShow(true)}
                >
                    Add New Feedback
                </Button>
            ) : (
                <Button disabled variant="danger" size="sm">
                    Add New Feedback
                </Button>
            )}

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Add New Feedback on the HR Portal
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit(onSubmit)} className="row">
                        <InputWrapper col="7" label="Title">
                            <input
                                {...register("title")}
                                type="text"
                                name="title"
                                className="form-control"
                            />
                        </InputWrapper>
                        <InputWrapper col="4" label="Priority">
                            <select
                                {...register("priority")}
                                id="disabledSelect"
                                name="priority"
                                className="form-select"
                            >
                                <option>Select priority</option>
                                <option>High</option>
                                <option>Medium</option>
                            </select>
                        </InputWrapper>
                        <InputWrapper col="8" label="Details">
                            <textarea
                                {...register("details")}
                                type="text"
                                name="details"
                                className="form-control"
                                rows={5}
                            ></textarea>
                        </InputWrapper>

                        <div className="col-12 text-center mt-4">
                            {systemLoading ? (
                                <button
                                    disabled
                                    className="btn btn-sm btn-primary"
                                >
                                    Add Feedback{" "}
                                    <Spinner animation="grow" size="sm" />
                                </button>
                            ) : (
                                <button className="btn btn-sm btn-primary">
                                    Add Feedback
                                </button>
                            )}
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAddPortalFeedback;
