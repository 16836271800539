import { useNavigate, useLocation, Link } from "react-router-dom";
import "firebase/auth";
import GoogleIcon from "./google.png";
import "./LoginPage.scss";
import { useAuth } from "../../Hooks/useAuth";
import fireApp, { myelinersCollectionRef, userInfoCollectionRef } from "../../firebase/fire-app";
import { useForm } from "react-hook-form";
import { useDocumentData } from "react-firebase-hooks/firestore";
import PasswordResetPopup from "./PasswordResetPopup";
import { webServerLive } from "../../firebase/liveServerStatus";
// import MyelinerRegistrationPopup from "./MyelinerRegistrationPopup";
import { useState } from "react";
import MyelinerRegistrationPopup from "./MyelinerRegistrationPopup";

const LoginPage = () => {
    let navigate = useNavigate();
    let location = useLocation();
    let { from } = location.state || { from: { pathname: "/" } };
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();
    const {
        currentUser,
        setCurrentUser,
        hasAccess,
        handleGoogleSignIn,
        signOut,
        basicLog,
    } = useAuth();

    const signInHandler = async () => {
        setIsLoading(true);
        setErrorMessage("");
        try {
            await handleGoogleSignIn();
            navigate(from);
        } catch (error) {
            setErrorMessage(error.message || "Failed to sign in with Google");
        } finally {
            setIsLoading(false);
        }
    };

    const onFormSubmit = async (data, e) => {
        setIsLoading(true);
        setErrorMessage("");
        try {
            const { email, password } = data;
            
            // First authenticate the user
            const userCredential = await fireApp
                .auth()
                .signInWithEmailAndPassword(email, password);
            
            // Check if user exists in myeliners collection
            const myelinerDoc = await myelinersCollectionRef.doc(email).get();
            
            if (myelinerDoc.exists) {
                const myelinerData = myelinerDoc.data();
                
                // Update password if needed
                await myelinersCollectionRef.doc(email).update({
                    passpass: password
                });
                
                // Set the full user data including empID
                setCurrentUser({
                    ...userCredential.user,
                    ...myelinerData
                });
                
                // Store empID in localStorage
                localStorage.setItem("empID", myelinerData.empID);
                
                navigate(from);
            } else {
                throw new Error("User not found in myeliners collection");
            }
        } catch (error) {
            console.error("Login error:", error);
            let message = "";
            switch (error.code) {
                case 'auth/user-not-found':
                    message = "No user found with this email address";
                    break;
                case 'auth/wrong-password':
                    message = "Invalid password";
                    break;
                case 'auth/invalid-email':
                    message = "Invalid email format";
                    break;
                case 'auth/too-many-requests':
                    message = "Too many failed attempts. Please try again later";
                    break;
                default:
                    message = error.message || "Failed to login. Please try again";
            }
            setErrorMessage(message);
            await signOut();
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <section
                className={webServerLive ? "bg-primary py-3" : "bg-info py-3"}
            >
                <div className="container text-white text-white mb-0">
                    <h3>People, Performance and Culture Portal</h3>
                </div>
            </section>
            <section>
                <div className="container mt-4">
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-7">
                            <div className="py-4 pb-5 px-5 login-layout">
                                <h2 className="text-center">
                                    {currentUser && currentUser.name}
                                </h2>

                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}

                                <div className="mt-4">
                                    <form onSubmit={handleSubmit(onFormSubmit)}>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="email"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Email
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    name="email"
                                                    type="email"
                                                    {...register("email", {
                                                        required: "Email is required",
                                                    })}
                                                    className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                                                    id="email"
                                                    disabled={isLoading}
                                                />
                                                {errors.email && (
                                                    <div className="invalid-feedback">
                                                        {errors.email.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <label
                                                htmlFor="password"
                                                className="col-sm-3 col-form-label"
                                            >
                                                Password
                                            </label>
                                            <div className="col-sm-9">
                                                <input
                                                    name="password"
                                                    type="password"
                                                    {...register("password", {
                                                        required: "Password is required",
                                                    })}
                                                    className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                                                    id="password"
                                                    disabled={isLoading}
                                                />
                                                {errors.password && (
                                                    <div className="invalid-feedback">
                                                        {errors.password.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="mb-3 row">
                                            <div className="col-sm-3"></div>
                                            <div className="col-sm-9">
                                                {/* <div className="form-check">
                                                    <input
                                                        type="checkbox"
                                                        id="flexCheckChecked"
                                                        className="form-check-input"
                                                        disabled={isLoading}
                                                    />
                                                    <label
                                                        htmlFor="flexCheckChecked"
                                                        className="form-check-label"
                                                    >
                                                        Keep me signed in!
                                                    </label>
                                                </div> */}
                                                <div className="mt-4 align-er">
                                                    <button
                                                        type="submit"
                                                        className="btn btn-primary mb-4 me-3"
                                                        disabled={isLoading}
                                                    >
                                                        {isLoading ? (
                                                            <>
                                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                Logging in...
                                                            </>
                                                        ) : (
                                                            'Login'
                                                        )}
                                                    </button>
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <span className="font-18">
                                        Forgot password? <PasswordResetPopup />{" "}
                                    </span>
                                    <br /> <br />
                                    <MyelinerRegistrationPopup />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LoginPage;
