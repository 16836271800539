import React, { useState, useEffect } from 'react';
import { Table, Badge, Button, Modal, Form, Card, Row, Col, InputGroup, Spinner } from 'react-bootstrap';
import { format, startOfWeek, endOfWeek } from 'date-fns';
import { testingReportsCollectionRef, testingStatusCollectionRef } from '../../firebase/fire-app';
import { useAuth } from '../../Hooks/useAuth';
import './TestingReportsList.scss';
import ViewGameReviewModal from './ViewGameReviewModal';

const TestingReportsList = () => {
    const [reports, setReports] = useState([]);
    const [filteredReports, setFilteredReports] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const [newStatus, setNewStatus] = useState('');
    const { myelinners } = useAuth();

    // Pagination states
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [itemsPerPage] = useState(10);

    // Filter states
    const [filters, setFilters] = useState({
        status: '',
        assignedTo: '',
        weekRange: '',
        dateRange: {
            start: '',
            end: ''
        },
        searchTerm: ''
    });

    useEffect(() => {
        fetchReports();
    }, []);

    const fetchReports = async (loadMore = false) => {
        try {
            if (loadMore) {
                setLoadingMore(true);
            } else {
                setLoading(true);
            }

            let query = testingReportsCollectionRef
                .orderBy('createdAt', 'desc')
                .limit(itemsPerPage);

            if (loadMore && lastVisible) {
                query = query.startAfter(lastVisible);
            }

            const snapshot = await query.get();
            
            if (snapshot.empty) {
                setHasMore(false);
                setLoading(false);
                setLoadingMore(false);
                return;
            }

            const lastDoc = snapshot.docs[snapshot.docs.length - 1];
            setLastVisible(lastDoc);

            const reportsData = await Promise.all(
                snapshot.docs.map(async (doc) => {
                    const reportData = doc.data();
                    const statusSnapshot = await testingStatusCollectionRef
                        .where('reportId', '==', doc.id)
                        .get();
                    
                    let statusData = null;
                    if (!statusSnapshot.empty) {
                        statusData = statusSnapshot.docs[0].data();
                    }

                    return {
                        id: doc.id,
                        ...reportData,
                        statusData
                    };
                })
            );

            // Check if there are more documents
            const nextQuery = testingReportsCollectionRef
                .orderBy('createdAt', 'desc')
                .startAfter(lastDoc)
                .limit(1);
                
            const nextSnapshot = await nextQuery.get();
            setHasMore(!nextSnapshot.empty);

            if (loadMore) {
                setReports(prev => [...prev, ...reportsData]);
                setFilteredReports(prev => [...prev, ...reportsData]);
            } else {
                setReports(reportsData);
                setFilteredReports(reportsData);
            }
        } catch (error) {
            console.error('Error fetching reports:', error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    // Apply filters whenever filters state changes
    useEffect(() => {
        let filtered = [...reports];

        // Filter by status
        if (filters.status) {
            filtered = filtered.filter(report => report.status === filters.status);
        }

        // Filter by assigned user
        if (filters.assignedTo) {
            filtered = filtered.filter(report => 
                report.assignedTo?.email === filters.assignedTo
            );
        }

        // Filter by week range
        if (filters.weekRange) {
            filtered = filtered.filter(report => report.weekRange === filters.weekRange);
        }

        // Filter by date range
        if (filters.dateRange.start && filters.dateRange.end) {
            filtered = filtered.filter(report => {
                if (!report.createdAt) return false;
                const reportDate = report.createdAt.toDate?.() || new Date(report.createdAt);
                return reportDate >= new Date(filters.dateRange.start) && 
                       reportDate <= new Date(filters.dateRange.end);
            });
        }

        // Filter by search term
        if (filters.searchTerm) {
            const searchLower = filters.searchTerm.toLowerCase();
            filtered = filtered.filter(report => 
                (report.assignedTo?.name?.toLowerCase()?.includes(searchLower)) ||
                (report.assignedTo?.email?.toLowerCase()?.includes(searchLower)) ||
                (report.weekRange?.toLowerCase()?.includes(searchLower))
            );
        }

        setFilteredReports(filtered);
    }, [filters, reports]);

    const getStatusBadge = (status) => {
        const statusConfig = {
            pending_testing: { color: 'warning', text: 'Pending Testing' },
            test_report_available: { color: 'info', text: 'Test Report Available' },
            assigned_to_developer: { color: 'primary', text: 'Assigned to Developer' },
            tested_and_resolved: { color: 'success', text: 'Tested and Resolved' }
        };

        const config = statusConfig[status] || { color: 'secondary', text: status };
        return <Badge bg={config.color}>{config.text}</Badge>;
    };

    const handleStatusUpdate = async () => {
        if (!selectedReport || !newStatus) return;

        try {
            // Update status document
            const statusSnapshot = await testingStatusCollectionRef
                .where('reportId', '==', selectedReport.id)
                .get();

            if (!statusSnapshot.empty) {
                const statusDoc = statusSnapshot.docs[0];
                const currentStatus = statusDoc.data();
                
                await statusDoc.ref.update({
                    status: newStatus,
                    updatedAt: new Date(),
                    updatedBy: {
                        email: myelinners[0].email,
                        name: myelinners[0].name
                    },
                    history: [
                        ...currentStatus.history,
                        {
                            status: newStatus,
                            timestamp: new Date(),
                            updatedBy: {
                                email: myelinners[0].email,
                                name: myelinners[0].name
                            }
                        }
                    ]
                });
            }

            // Update report document
            await testingReportsCollectionRef.doc(selectedReport.id).update({
                status: newStatus,
                lastUpdated: new Date()
            });

            setShowStatusModal(false);
            setSelectedReport(null);
            setNewStatus('');
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    const handleFilterChange = (key, value) => {
        setFilters(prev => ({
            ...prev,
            [key]: value
        }));
    };

    const handleDateRangeChange = (key, value) => {
        setFilters(prev => ({
            ...prev,
            dateRange: {
                ...prev.dateRange,
                [key]: value
            }
        }));
    };

    const resetFilters = () => {
        setFilters({
            status: '',
            assignedTo: '',
            weekRange: '',
            dateRange: {
                start: '',
                end: ''
            },
            searchTerm: ''
        });
    };

    // Get unique week ranges for filter
    const weekRanges = [...new Set(reports.map(report => report.weekRange))].sort().reverse();

    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        try {
            const date = timestamp.toDate?.() || new Date(timestamp);
            return format(date, 'MMM d, yyyy');
        } catch (error) {
            console.error('Error formatting date:', error);
            return 'Invalid date';
        }
    };

    return (
        <Card className="mb-4">
            <Card.Header>
                <h5 className="mb-0">Testing Reports</h5>
            </Card.Header>
            <Card.Body>
                {/* Filters Section */}
                <div className="filters-section mb-4">
                    <Row className="g-3">
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Select
                                    value={filters.status}
                                    onChange={(e) => handleFilterChange('status', e.target.value)}
                                >
                                    <option value="">All Statuses</option>
                                    <option value="pending_testing">Pending Testing</option>
                                    <option value="test_report_available">Test Report Available</option>
                                    <option value="assigned_to_developer">Assigned to Developer</option>
                                    <option value="tested_and_resolved">Tested and Resolved</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Assigned To</Form.Label>
                                <Form.Select
                                    value={filters.assignedTo}
                                    onChange={(e) => handleFilterChange('assignedTo', e.target.value)}
                                >
                                    <option value="">All Users</option>
                                    {myelinners.map(user => (
                                        <option key={user.email} value={user.email}>
                                            {user.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Week Range</Form.Label>
                                <Form.Select
                                    value={filters.weekRange}
                                    onChange={(e) => handleFilterChange('weekRange', e.target.value)}
                                >
                                    <option value="">All Weeks</option>
                                    {weekRanges.map(week => (
                                        <option key={week} value={week}>{week}</option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col md={3}>
                            <Form.Group>
                                <Form.Label>Search</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="Search reports..."
                                    value={filters.searchTerm}
                                    onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Date Range</Form.Label>
                                <Row className="g-2">
                                    <Col>
                                        <Form.Control
                                            type="date"
                                            value={filters.dateRange.start}
                                            onChange={(e) => handleDateRangeChange('start', e.target.value)}
                                        />
                                    </Col>
                                    <Col>
                                        <Form.Control
                                            type="date"
                                            value={filters.dateRange.end}
                                            onChange={(e) => handleDateRangeChange('end', e.target.value)}
                                        />
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Col>
                        <Col md={6} className="d-flex align-items-end">
                            <Button 
                                variant="outline-secondary" 
                                onClick={resetFilters}
                                className="w-100"
                            >
                                Reset Filters
                            </Button>
                        </Col>
                    </Row>
                </div>

                {/* Results Summary */}
                <div className="results-summary mb-3">
                    <Badge bg="info">
                        Showing {filteredReports.length} of {reports.length} reports
                    </Badge>
                </div>

                {/* Reports Table */}
                {loading ? (
                    <div className="text-center py-4">
                        <Spinner animation="border" role="status" />
                        <p className="mt-2">Loading reports...</p>
                    </div>
                ) : (
                    <>
                        <div className="table-responsive">
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Assigned To</th>
                                        <th>Week Range</th>
                                        <th>Status</th>
                                        <th>Created At</th>
                                        <th>Last Updated</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredReports.length > 0 ? (
                                        filteredReports.map((report) => (
                                            <tr key={report.id}>
                                                <td>
                                                    {report.assignedTo?.name || 'N/A'}
                                                    <br />
                                                    <small className="text-muted">{report.assignedTo?.email || 'N/A'}</small>
                                                </td>
                                                <td>{report.weekRange || 'N/A'}</td>
                                                <td>{getStatusBadge(report.status)}</td>
                                                <td>{formatDate(report.createdAt)}</td>
                                                <td>{formatDate(report.lastUpdated)}</td>
                                                <td>
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={() => {
                                                            setSelectedReport(report);
                                                            setShowStatusModal(true);
                                                        }}
                                                    >
                                                        View Report
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan="6" className="text-center py-4">
                                                No reports found matching the selected filters.
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>

                        {/* Load More Button */}
                        {hasMore && (
                            <div className="text-center mt-3">
                                <Button 
                                    variant="outline-primary" 
                                    onClick={() => fetchReports(true)}
                                    disabled={loadingMore}
                                >
                                    {loadingMore ? (
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                                            Loading more...
                                        </>
                                    ) : (
                                        'Load More Reports'
                                    )}
                                </Button>
                            </div>
                        )}
                    </>
                )}

                {selectedReport && showStatusModal && (
                    <ViewGameReviewModal
                        show={showStatusModal}
                        onHide={() => setShowStatusModal(false)}
                        report={selectedReport}
                    />
                )}
            </Card.Body>
        </Card>
    );
};

export default TestingReportsList; 