import React, { useState, useEffect } from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Card,
    Alert,
    Tab,
    Nav,
    Button,
} from "react-bootstrap";
import { startOfWeek, endOfWeek, format, getMonth, getYear } from "date-fns";
import {
    progressReportsCollectionRef,
    monthlyScoresCollectionRef,
    notificationsCollectionRef,
} from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import WeeklyReportForm from "./components/WeeklyReportForm";
import ReviewForm from "./components/ReviewForm";
import MonthlyScoreCard from "./components/MonthlyScoreCard";
import MonthlyScoreEmailPopup from "./components/MonthlyScoreEmailPopup";
import "./ViewAdminPerformanceScoring.scss";
import { weeklyReportReminderMessage } from "../../utilities/emailGenerator";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { webServerLive } from "../../firebase/liveServerStatus";
import { weeklyReportReviewedMessage } from "../../utilities/emailGenerator";

const ViewAdminPerformanceScoring = () => {
    const { myelinners, currentUser } = useAuth();
    const [selectedEmployee, setSelectedEmployee] = useState("");
    const [employeeReports, setEmployeeReports] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [monthlyScores, setMonthlyScores] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState("reports");

    // Fetch reports when employee is selected
    useEffect(() => {
        const fetchEmployeeReports = async () => {
            if (!selectedEmployee) {
                setEmployeeReports([]);
                return;
            }

            setLoading(true);
            try {
                const reportsSnapshot = await progressReportsCollectionRef
                    .where("email", "==", selectedEmployee)
                    .orderBy("weekStartDate", "desc")
                    .get();

                const reports = reportsSnapshot.docs.map((doc) => {
                    const data = doc.data();
                    const weekStartDate = data.weekStartDate.toDate();
                    // Ensure week ends on Sunday
                    const weekEndDate = endOfWeek(weekStartDate, {
                        weekStartsOn: 1,
                    });

                    return {
                        id: doc.id,
                        ...data,
                        weekStartDate,
                        weekEndDate,
                    };
                });

                setEmployeeReports(reports);
            } catch (err) {
                setError("Failed to fetch employee reports");
                console.error(err);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployeeReports();
    }, [selectedEmployee]);

    // Fetch monthly scores when employee is selected
    useEffect(() => {
        const fetchMonthlyScores = async () => {
            if (!selectedEmployee) {
                setMonthlyScores([]);
                return;
            }

            try {
                const scoresSnapshot = await monthlyScoresCollectionRef
                    .where("email", "==", selectedEmployee)
                    .orderBy("year", "desc")
                    .orderBy("month", "desc")
                    .limit(3)
                    .get();

                const scores = scoresSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setMonthlyScores(scores);
            } catch (err) {
                setError("Failed to fetch monthly scores");
                console.error(err);
            }
        };

        fetchMonthlyScores();
    }, [selectedEmployee]);

    const handleEmployeeChange = (e) => {
        setSelectedEmployee(e.target.value);
        setSelectedReport(null);
        setActiveTab("reports");
    };

    const handleReportSelect = (report) => {
        setSelectedReport(report);
    };

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    const updateMonthlyScores = async (email, reportDate, newScore) => {
        const month = getMonth(reportDate) + 1;
        const year = getYear(reportDate);

        try {
            const monthReportsSnapshot = await progressReportsCollectionRef
                .where("email", "==", email)
                .where("status", "==", "reviewed")
                .get();

            const monthReports = monthReportsSnapshot.docs
                .map((doc) => ({
                    ...doc.data(),
                    weekStartDate: doc.data().weekStartDate.toDate(),
                    score: doc.data().score || 0,
                }))
                .filter(
                    (report) =>
                        getMonth(report.weekStartDate) === month - 1 &&
                        getYear(report.weekStartDate) === year
                );

            const totalScore = monthReports.reduce(
                (sum, report) => sum + Number(report.score),
                0
            );
            const averageScore =
                monthReports.length > 0 ? totalScore / monthReports.length : 0;

            const weeklyScores = monthReports
                .map((report) => ({
                    weekStartDate: report.weekStartDate,
                    score: Number(report.score),
                }))
                .sort((a, b) => a.weekStartDate - b.weekStartDate);

            const monthlyScoreRef = monthlyScoresCollectionRef
                .where("email", "==", email)
                .where("month", "==", month)
                .where("year", "==", year);

            const monthlyScoreDoc = await monthlyScoreRef.get();

            if (monthlyScoreDoc.empty) {
                await monthlyScoresCollectionRef.add({
                    email,
                    month,
                    year,
                    averageScore,
                    weeklyScores,
                    lastUpdated: new Date(),
                });
            } else {
                await monthlyScoresCollectionRef
                    .doc(monthlyScoreDoc.docs[0].id)
                    .update({
                        averageScore,
                        weeklyScores,
                        lastUpdated: new Date(),
                    });
            }

            // Refresh monthly scores after update
            const updatedScoresSnapshot = await monthlyScoresCollectionRef
                .where("email", "==", email)
                .orderBy("year", "desc")
                .orderBy("month", "desc")
                .limit(3)
                .get();

            const updatedScores = updatedScoresSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            setMonthlyScores(updatedScores);
        } catch (err) {
            console.error("Error updating monthly scores:", err);
            throw new Error("Failed to update monthly scores");
        }
    };

    const handleReviewSubmit = async (reviewData) => {
        if (!selectedReport) return;

        setLoading(true);
        try {
            // Get reviewer info from myelinners array
            const reviewer = myelinners.find(
                (m) => m.email === currentUser.email
            ) || {
                name: "Unknown Reviewer",
                email: currentUser.email,
            };

            await progressReportsCollectionRef.doc(selectedReport.id).update({
                score: reviewData.score,
                feedback: reviewData.feedback,
                status: "reviewed",
                reviewedAt: new Date(),
                reviewer: {
                    name: reviewer.name,
                    email: reviewer.email,
                },
            });
            const reportOwner = myelinners.find(
                (m) => m.email === selectedEmployee
            );
            // now sending email notification to the employee
            const mailDetails = weeklyReportReviewedMessage({
                name: reportOwner.name,
                score: reviewData.score,
                mailOne: selectedEmployee,
                mailTwo: reviewer.email,
                mailThree: "shahjahan.swajan@myelinh.com",
            });
            emailjs
            .send(
                "service_zinxo6v",
                "template_eqvm7mf",
                mailDetails,
                "ygk5Yo8QhiJUWbVZM"
            )
            .then(() => {
                toast.success(
                    `Weekly report score confirmation email sent to ${reportOwner.name}`
                );
            })
            .catch((err) => {
                toast.error(
                    `Error sending score confirmation email to ${reportOwner.name}`
                );
                console.error(err);
            });
            await updateMonthlyScores(
                selectedEmployee,
                selectedReport.weekStartDate,
                Number(reviewData.score)
            );

            const updatedSnapshot = await progressReportsCollectionRef
                .where("email", "==", selectedEmployee)
                .orderBy("weekStartDate", "desc")
                .get();

            const updatedReports = updatedSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                weekStartDate: doc.data().weekStartDate.toDate(),
                weekEndDate: doc.data().weekEndDate.toDate(),
            }));

            setEmployeeReports(updatedReports);
            setSelectedReport(null);
            setError(null);
        } catch (err) {
            setError("Failed to submit review");
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const formatDateRange = (startDate, endDate) => {
        return `${format(startDate, "MMM d")} - ${format(
            endDate,
            "MMM d, yyyy"
        )}`;
    };
    // send weekly report reminder message to all myelinners

    // test dummy myellinners for testing
    const testMyelinners = [
        {
            name: "Swajan",
            email: "shahjahan.swajan@myelinh.com",
        },
    ];
    // logging myelinners useEffect
    useEffect(() => {
        console.log(myelinners);
    }, [myelinners]);
    const sendWeeklyReportReminderMessage = () => {
        if (webServerLive) {
            const allEmployeesEmails = myelinners.map(
                (employee) => employee.email
            );
            const currentWeekStart = startOfWeek(new Date(), {
                weekStartsOn: 1,
            });
            const currentWeekEnd = endOfWeek(currentWeekStart, {
                weekStartsOn: 1,
            });
            const mailDetails = weeklyReportReminderMessage({
                mailOne: allEmployeesEmails,
                mailTwo: "zied.tayeb@myelinh.com",
                mailThree: "samaher.garbaya@myelinh.com",
                mailFour: "shahjahan.swajan@myelinh.com",
                weekStart: currentWeekStart,
                weekEnd: currentWeekEnd,
            });
            // console.log(mailDetails);
            emailjs
            .send(
                "service_zinxo6v",
                "template_eqvm7mf",
                mailDetails,
                "ygk5Yo8QhiJUWbVZM"
            )
            .then(() => {
                toast.success(
                    `Weekly report reminder sent to all selected employees`
                );
            })
            .catch((err) => {
                toast.error(
                    `Error sending reminder to all selected employees`
                );
                console.error(err);
            });
        } else {
            const allEmployeesEmails = myelinners.map(
                (employee) => employee.email
            );
            const currentWeekStart = startOfWeek(new Date(), {
                weekStartsOn: 1,
            });
            const currentWeekEnd = endOfWeek(currentWeekStart, {
                weekStartsOn: 1,
            });
            const mailDetails = weeklyReportReminderMessage({
                mailOne: ["shahjahan.swajan@myelinh.com", "smswajan@gmail.com"],
                mailTwo: "shahjahan.swajan@myelinh.com",
                mailThree: "smswajan@gmail.com",
                mailFour: "shahjahan.swajan@myelinh.com",
                weekStart: currentWeekStart,
                weekEnd: currentWeekEnd,
            });
            console.log(mailDetails);
            emailjs
            .send(
                "service_zinxo6v",
                "template_eqvm7mf",
                mailDetails,
                "ygk5Yo8QhiJUWbVZM"
            )
            .then(() => {
                toast.success(
                    `Weekly report reminder sent to all selected employees`
                );
            })
            .catch((err) => {
                toast.error(
                    `Error sending reminder to all selected employees`
                );
                console.error(err);
            });
            
        }

        notificationsCollectionRef.add({
            details: "Weekly report reminder sent",
            type: "weeklyReportReminder",
            createdAt: new Date(),
            status: "unread",
            whoCanSee: "all",
        });
    };
    return (
        <SidebarComponent>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <section className="sticky-top page-heading">
                <div className="container">
                    <div className="d-flex justify-content-between">
                        <h3 className="mb-0">Performance Review</h3>
                        <div>
                            <Button
                                variant="primary"
                                size="sm"
                                onClick={() =>
                                    sendWeeklyReportReminderMessage()
                                }
                            >
                                Send Weekly Report Reminder
                            </Button>
                            <MonthlyScoreEmailPopup myelinners={myelinners} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="bg-light admin-review-section">
                <Container fluid>
                    {error && (
                        <Alert
                            variant="danger"
                            onClose={() => setError(null)}
                            dismissible
                        >
                            {error}
                        </Alert>
                    )}

                    <Row className="mb-4">
                        <Col md={6}>
                            <Form.Group>
                                <Form.Label>Select Employee</Form.Label>
                                <Form.Select
                                    value={selectedEmployee}
                                    onChange={handleEmployeeChange}
                                    className="employee-select"
                                >
                                    <option value="">
                                        Choose an employee...
                                    </option>
                                    {myelinners?.map((employee) => (
                                        <option
                                            key={employee.email}
                                            value={employee.email}
                                        >
                                            {employee.name} ({employee.email})
                                        </option>
                                    ))}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>

                    {selectedEmployee && (
                        <Tab.Container
                            activeKey={activeTab}
                            onSelect={handleTabSelect}
                        >
                            <Row>
                                <Col
                                    md={3}
                                    lg={2}
                                    className="vertical-tabs admin-tabs"
                                >
                                    <Nav
                                        variant="pills"
                                        className="flex-column"
                                    >
                                        <Nav.Item>
                                            <Nav.Link eventKey="reports">
                                                Weekly Reports
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="overview">
                                                Score Overview
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>

                                <Col
                                    md={9}
                                    lg={10}
                                    className="tab-content-wrapper"
                                >
                                    <Tab.Content>
                                        <Tab.Pane eventKey="reports">
                                            <Row>
                                                <Col md={4} lg={3}>
                                                    <Card className="reports-list">
                                                        <Card.Header>
                                                            <h5 className="mb-0">
                                                                Weekly Reports
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            {loading ? (
                                                                <div className="text-center py-3">
                                                                    Loading...
                                                                </div>
                                                            ) : employeeReports.length ===
                                                              0 ? (
                                                                <div className="text-center py-3">
                                                                    No reports
                                                                    found
                                                                </div>
                                                            ) : (
                                                                <Nav
                                                                    variant="pills"
                                                                    className="flex-column"
                                                                >
                                                                    {employeeReports.map(
                                                                        (
                                                                            report
                                                                        ) => (
                                                                            <Nav.Item
                                                                                key={
                                                                                    report.id
                                                                                }
                                                                            >
                                                                                <Nav.Link
                                                                                    active={
                                                                                        selectedReport?.id ===
                                                                                        report.id
                                                                                    }
                                                                                    onClick={() =>
                                                                                        handleReportSelect(
                                                                                            report
                                                                                        )
                                                                                    }
                                                                                    className={`report-item ${report.status}`}
                                                                                >
                                                                                    <div className="report-date">
                                                                                        {formatDateRange(
                                                                                            report.weekStartDate,
                                                                                            report.weekEndDate
                                                                                        )}
                                                                                    </div>
                                                                                    <div className="report-status">
                                                                                        {report.status ===
                                                                                        "reviewed"
                                                                                            ? "Reviewed"
                                                                                            : "Pending"}
                                                                                    </div>
                                                                                </Nav.Link>
                                                                            </Nav.Item>
                                                                        )
                                                                    )}
                                                                </Nav>
                                                            )}
                                                        </Card.Body>
                                                    </Card>
                                                </Col>

                                                <Col md={8} lg={9}>
                                                    {selectedReport ? (
                                                        <div className="report-review">
                                                            <WeeklyReportForm
                                                                existingReport={
                                                                    selectedReport
                                                                }
                                                                isEditable={
                                                                    false
                                                                }
                                                            />
                                                            <ReviewForm
                                                                onSubmit={
                                                                    handleReviewSubmit
                                                                }
                                                                existingReview={
                                                                    selectedReport
                                                                }
                                                                isSubmitting={
                                                                    loading
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <Card className="text-center py-5">
                                                            <Card.Body>
                                                                <h5>
                                                                    Select a
                                                                    report to
                                                                    review
                                                                </h5>
                                                                <p className="text-muted">
                                                                    Choose a
                                                                    report from
                                                                    the list to
                                                                    view and
                                                                    review it
                                                                </p>
                                                            </Card.Body>
                                                        </Card>
                                                    )}
                                                </Col>
                                            </Row>
                                        </Tab.Pane>

                                        <Tab.Pane eventKey="overview">
                                            {monthlyScores.length > 0 ? (
                                                monthlyScores.map(
                                                    (monthData) => (
                                                        <MonthlyScoreCard
                                                            key={monthData.id}
                                                            monthlyData={
                                                                monthData
                                                            }
                                                        />
                                                    )
                                                )
                                            ) : (
                                                <Card className="text-center py-5">
                                                    <Card.Body>
                                                        <h5>
                                                            No scores available
                                                        </h5>
                                                        <p className="text-muted">
                                                            This employee has no
                                                            monthly scores yet
                                                        </p>
                                                    </Card.Body>
                                                </Card>
                                            )}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    )}
                </Container>
            </section>
        </SidebarComponent>
    );
};

export default ViewAdminPerformanceScoring;
