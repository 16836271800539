import React from 'react';
import { Card, Button, Badge } from 'react-bootstrap';
import { format, startOfWeek, endOfWeek, subWeeks, isAfter } from 'date-fns';
import './ReportCalendar.scss';

const ReportCalendar = ({ selectedRange, onRangeChange, submittedWeeks }) => {
    const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });

    const getWeekStatus = (weekStart) => {
        const report = submittedWeeks.find(
            week => week.startDate.getTime() === weekStart.getTime()
        );
        return report ? report.status : 'missing';
    };

    const formatDateRange = (start, end) => {
        return `${format(start, 'MMM d')} - ${format(end, 'MMM d, yyyy')}`;
    };

    const handleWeekSelect = (weekStart, weekEnd) => {
        if (!isAfter(weekStart, currentWeekStart)) {
            onRangeChange({
                startDate: weekStart,
                endDate: weekEnd
            });
        }
    };

    const getStatusBadgeVariant = (status, isSelected) => {
        if (isSelected) return 'light';
        switch (status) {
            case 'pending':
                return 'warning';
            case 'reviewed':
                return 'success';
            default:
                return 'secondary';
        }
    };

    const renderWeekButton = (weekStart, weekEnd, isSelected) => {
        const status = getWeekStatus(weekStart);
        const isFutureWeek = isAfter(weekStart, currentWeekStart);
        
        let label = 'Not Submitted';
        let actionText = 'Click to submit';
        
        if (status === 'pending') {
            label = 'Pending Review';
            actionText = 'Click to edit';
        } else if (status === 'reviewed') {
            label = 'Reviewed';
            actionText = 'View only';
        } else if (isFutureWeek) {
            label = 'Future Week';
            actionText = '';
        }

        const buttonClass = `week-button ${status} ${isSelected ? 'selected' : ''}`;
        const badgeVariant = getStatusBadgeVariant(status, isSelected);

        return (
            <Button
                key={weekStart.getTime()}
                variant="light"
                onClick={() => !isFutureWeek && handleWeekSelect(weekStart, weekEnd)}
                disabled={isFutureWeek}
                className={buttonClass}
            >
                <div className="date-range">{formatDateRange(weekStart, weekEnd)}</div>
                <Badge bg={badgeVariant} className="status">{label}</Badge>
                {actionText && <div className="action-text">{actionText}</div>}
            </Button>
        );
    };

    // Generate last 4 weeks including current week
    const weeks = Array.from({ length: 4 }, (_, i) => {
        const weekStart = startOfWeek(subWeeks(new Date(), i), { weekStartsOn: 1 });
        const weekEnd = endOfWeek(weekStart, { weekStartsOn: 1 });
        return { start: weekStart, end: weekEnd };
    }).reverse();

    return (
        <Card className="report-calendar">
            <Card.Body>
                <h5 className="mb-3">Select Week to Report</h5>
                <div className="weeks-container">
                    {weeks.map(week => renderWeekButton(
                        week.start,
                        week.end,
                        selectedRange.startDate.getTime() === week.start.getTime()
                    ))}
                </div>
                <div className="calendar-legend">
                    <div className="legend-item">
                        <div className="legend-dot pending"></div>
                        <span>Pending Review</span>
                    </div>
                    <div className="legend-item">
                        <div className="legend-dot reviewed"></div>
                        <span>Reviewed</span>
                    </div>
                    <div className="legend-item">
                        <div className="legend-dot missing"></div>
                        <span>Not Submitted</span>
                    </div>
                </div>
            </Card.Body>
        </Card>
    );
};

export default ReportCalendar; 