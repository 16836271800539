import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";
import {
    assignedTasksCollectionRef,
    portalFeedbacksCollectionRef,
    ppcVersionDocRef
} from "../../firebase/fire-app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import CompTaskCard from "../AdminAssignTask/CompTaskCard";
import PopupAddPortalFeedback from "./PopupAddPortalFeedback";
import CompPortalFeedback from "./CompPortalFeedback";
import { ppcVersion } from "../../firebase/liveServerStatus";
const ViewPortalFeedback = () => {
    const { currentUser } = useAuth();
    const [empEmail, setEmpEmail] = useState("");

    useEffect(() => {
        if (currentUser) {
            setEmpEmail(currentUser.email);
        }
    }, [currentUser]);
    const [tasksList, setTasksList] = useState(null);

    const [assignedTasksList, listLoading, listError] = useCollectionData(
        portalFeedbacksCollectionRef
            .where("assignedBy", "==", empEmail)
            .orderBy("createdAt", "desc"),
        { idField: "id" }
    );

    useEffect(() => {
        if (assignedTasksList) {
            setTasksList(assignedTasksList);
        }
    }, [assignedTasksList]);

    const updateVersion = () => {
        ppcVersionDocRef.update({
            version: ppcVersion
        })
    }

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top page-heading">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-between">
                        <div>
                        <h3 className="mt">Portal Feedback</h3>
                            <p className="font-14">
                            v: {ppcVersion}
                            </p>
                        </div>
                            {
                                currentUser?.email === "shahjahan.swajan@myelinh.com" && (
                                    <button onClick={() => updateVersion()} className="btn btn-sm btn-danger">Update Version</button>
                                )
                            }
                            <div className="-3">
                                {currentUser && (
                                    <PopupAddPortalFeedback
                                        currentUser={currentUser}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile-card-container bg-light mt-3">
                    <div className="container">
                        <div className="row">
                            {tasksList &&
                                tasksList.map((item) => (
                                    <CompPortalFeedback data={item} key={item.id} />
                                ))}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewPortalFeedback;
