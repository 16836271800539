import React, { useState, useEffect } from 'react';
import { Modal, Button, Table, Form, Spinner, Badge } from 'react-bootstrap';
import { format, subDays, startOfWeek, endOfWeek } from 'date-fns';
import { toast } from 'react-toastify';
import emailjs from '@emailjs/browser';
import { gameTestingReminderMessage } from '../../utilities/emailGenerator';
import {  testingReportsCollectionRef, testingStatusCollectionRef } from '../../firebase/fire-app';
import { useAuth } from "../../Hooks/useAuth";
import './GameTestingReminderNotificationModal.scss';
import { webServerLive } from '../../firebase/liveServerStatus';

const GameTestingReminderNotificationModal = () => {
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [userSubmissions, setUserSubmissions] = useState({});
    const { myelinners } = useAuth();

    useEffect(() => {
        if (show) {
            fetchWeeklySubmissions();
        }
    }, [show]);

    const fetchWeeklySubmissions = async () => {
        try {
            const currentDate = new Date();
            const weekStart = startOfWeek(currentDate);
            const weekEnd = endOfWeek(currentDate);
            const currentWeekRange = `${format(weekStart, 'MMM d, yyyy')} - ${format(weekEnd, 'MMM d, yyyy')}`;

            // Get submissions for current week
            const submissionsSnapshot = await testingReportsCollectionRef
                .where('weekRange', '==', currentWeekRange)
                .get();

            const submissionsByUser = {};
            submissionsSnapshot.forEach(doc => {
                const data = doc.data();
                const userEmail = data.submittedBy?.email;
                if (userEmail) {
                    if (!submissionsByUser[userEmail]) {
                        submissionsByUser[userEmail] = {
                            submissions: 0,
                            pendingReports: 0
                        };
                    }
                    submissionsByUser[userEmail].submissions++;
                }
            });

            // Get pending reports for current week
            const pendingSnapshot = await testingStatusCollectionRef
                .where('weekRange', '==', currentWeekRange)
                .where('status', '==', 'pending_testing')
                .get();

            pendingSnapshot.forEach(doc => {
                const data = doc.data();
                const userEmail = data.assignedTo?.email;
                if (userEmail) {
                    if (!submissionsByUser[userEmail]) {
                        submissionsByUser[userEmail] = {
                            submissions: 0,
                            pendingReports: 0
                        };
                    }
                    submissionsByUser[userEmail].pendingReports++;
                }
            });

            setUserSubmissions(submissionsByUser);
        } catch (error) {
            console.error('Error fetching weekly submissions:', error);
            toast.error('Failed to fetch weekly submissions');
        }
    };

    const handleClose = () => {
        setShow(false);
        setSelectedUsers([]);
        setSelectAll(false);
    };

    const handleShow = () => setShow(true);

    const handleSelectAll = (e) => {
        setSelectAll(e.target.checked);
        setSelectedUsers(e.target.checked ? myelinners.map(user => user.email) : []);
    };

    const handleUserSelect = (email) => {
        setSelectedUsers(prev => {
            const newSelection = prev.includes(email)
                ? prev.filter(e => e !== email)
                : [...prev, email];
            
            setSelectAll(newSelection.length === myelinners.length);
            return newSelection;
        });
    };

    const getSubmissionStatus = (email) => {
        const submission = userSubmissions[email];
        if (!submission) {
            return (
                <div>
                    <Badge bg="danger">No Submission This Week</Badge>
                    <div className="small text-muted mt-1">
                        Pending Reports: 0
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="d-flex align-items-center gap-2">
                    <Badge bg={submission.submissions >= 2 ? "success" : "warning"}>
                        {submission.submissions} Submission{submission.submissions !== 1 ? 's' : ''}
                    </Badge>
                    {submission.pendingReports > 0 && (
                        <Badge bg="danger">
                            {submission.pendingReports} Pending
                        </Badge>
                    )}
                </div>
                <div className="small text-muted mt-1">
                    Last: {format(new Date(), "MMM d, yyyy")}
                </div>
                <div className="small text-muted">
                    Games: {new Set(selectedUsers.map(email => userSubmissions[email]?.submissions).flat()).size}
                </div>
            </div>
        );
    };

    const handleSendReminders = async () => {
        if (selectedUsers.length === 0) {
            toast.warning('Please select at least one user');
            return;
        }

        setLoading(true);
        const currentWeekStart = format(startOfWeek(new Date()), 'MMM d, yyyy');
        const currentWeekEnd = format(endOfWeek(new Date()), 'MMM d, yyyy');
        const currentWeekRange = `${currentWeekStart} - ${currentWeekEnd}`;

        try {
            for (const email of selectedUsers) {
                const user = myelinners.find(u => u.email === email);
                if (!user) continue;

                // Check if user already has a report for this week
                // const existingReport = await testingReportsCollectionRef
                //     .where('weekRange', '==', currentWeekRange)
                //     .where('assignedTo.email', '==', user.email)
                //     .get();

                // if (!existingReport.empty) {
                //     toast.info(`${user.email} already has a report for this week`);
                //     continue;
                // }

                // Create empty testing report
                const reportData = {
                    assignedTo: {
                        email: user.email,
                        name: user.name
                    },
                    status: 'pending_testing',
                    createdAt: new Date(),
                    weekRange: currentWeekRange,
                    selectedGames: [],
                    tutorialFeedback: '',
                    levelsFeedback: '',
                    positiveAspects: '',
                    improvementSuggestions: '',
                    overallExperience: '',
                    bugReports: [],
                    lastUpdated: new Date()
                };

                // Create the report document
                const reportRef = await testingReportsCollectionRef.add(reportData);

                // Create status tracking document
                const statusData = {
                    reportId: reportRef.id,
                    status: 'pending_testing',
                    updatedAt: new Date(),
                    updatedBy: {
                        email: user.email,
                        name: user.name
                    },
                    history: [{
                        status: 'pending_testing',
                        timestamp: new Date(),
                        updatedBy: {
                            email: user.email,
                            name: user.name
                        }
                    }]
                };

                await testingStatusCollectionRef.add(statusData);

                const mailDetails = gameTestingReminderMessage({
                    name: user.name,
                    email: user.email,
                    mailOne: user.email,
                    mailTwo: user.email === "zied.tayeb@myelinh.com" ? "" : "zied.tayeb@myelinh.com",
                    mailThree: user.email === "samaher.garbaya@myelinh.com" ? "" : "samaher.garbaya@myelinh.com",
                    mailFour: user.email === "shahjahan.swajan@myelinh.com" ? "" : "shahjahan.swajan@myelinh.com",
                    weekStart: currentWeekStart,
                    weekEnd: currentWeekEnd
                });

                try {
                    if (webServerLive) {
                        await emailjs.send(
                            "service_zinxo6v",
                            "template_eqvm7mf",
                            mailDetails,
                            "ygk5Yo8QhiJUWbVZM"
                        );
                        toast.success(`Weekly report reminder sent to ${user.email}`);
                    } else {
                        toast.info(`Email would be sent to ${user.email} (Development Mode)`);
                    }
                } catch (error) {
                    console.error('Error sending email to', user.email, error);
                    toast.error(`Error sending reminder to ${user.email}`);
                }
            }

            handleClose();
            // Refresh the submissions data after sending reminders
            fetchWeeklySubmissions();
        } catch (error) {
            console.error('Error sending reminders:', error);
            toast.error('Failed to send reminders');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                Send Weekly Reminders
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Send Weekly Testing Reminders</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="reminder-modal-content">
                        <div className="select-all-container mb-3">
                            <Form.Check
                                type="checkbox"
                                id="select-all"
                                label="Select All Users"
                                checked={selectAll}
                                onChange={handleSelectAll}
                            />
                        </div>

                        <div className="users-table-container">
                            <Table hover responsive>
                                <thead>
                                    <tr>
                                        <th style={{ width: '50px' }}></th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Weekly Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myelinners.map((user) => (
                                        <tr key={user.email}>
                                            <td>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={selectedUsers.includes(user.email)}
                                                    onChange={() => handleUserSelect(user.email)}
                                                />
                                            </td>
                                            <td>{user.name}</td>
                                            <td>{user.email}</td>
                                            <td>
                                                {getSubmissionStatus(user.email)}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button 
                        variant="primary" 
                        onClick={handleSendReminders}
                        disabled={loading || selectedUsers.length === 0}
                    >
                        {loading ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-2"
                                />
                                Sending...
                            </>
                        ) : (
                            `Send Reminder${selectedUsers.length > 1 ? 's' : ''}`
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default GameTestingReminderNotificationModal;