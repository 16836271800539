import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Image } from 'react-bootstrap';
import { bugReportsCollectionRef, testingReportsCollectionRef, fireStorage } from '../../firebase/fire-app';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { useAuth } from '../../Hooks/useAuth';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import gameNames from './data-game-names';
import './BugReportSubmissionModal.scss';

const SENSITIVITY_LEVELS = [
    { value: 'low', label: 'Low - Minor visual or non-functional issues' },
    { value: 'medium', label: 'Medium - Functional issues with workarounds' },
    { value: 'high', label: 'High - Major functional issues' },
    { value: 'critical', label: 'Critical - Game breaking issues' }
];

const ACCEPTED_FILE_TYPES = {
    images: ['image/jpeg', 'image/png', 'image/gif', 'image/webp'],
    documents: ['text/plain', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']
};

const BugReportSubmissionModal = ({ reportId, selectedGames }) => {
    const { currentUser } = useAuth();
    const reportDoc = testingReportsCollectionRef.doc(reportId);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);

    const [formData, setFormData] = useState({
        selectedGame: '',
        sensitivity: '',
        title: '',
        description: '',
        stepsToReproduce: '',
        expectedBehavior: '',
        actualBehavior: ''
    });

    useEffect(() => {
        if (selectedGames?.length > 0) {
            setFormData(prev => ({
                ...prev,
                selectedGame: selectedGames[0]
            }));
        }
    }, [selectedGames]);

    const handleClose = () => {
        setShow(false);
        setFormData({
            selectedGame: selectedGames?.[0] || '',
            sensitivity: '',
            title: '',
            description: '',
            stepsToReproduce: '',
            expectedBehavior: '',
            actualBehavior: ''
        });
        setSelectedFiles([]);
        setFilesToUpload([]);
    };

    const handleShow = () => setShow(true);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        
        const fileObjects = files.map(file => ({
            file,
            preview: file.type.includes('image') ? URL.createObjectURL(file) : null,
            type: file.type,
            name: file.name
        }));

        setSelectedFiles(prev => [...prev, ...fileObjects]);
        setFilesToUpload(prev => [...prev, ...files]);
    };

    const removeFile = (index) => {
        setSelectedFiles(prev => prev.filter((_, i) => i !== index));
        setFilesToUpload(prev => prev.filter((_, i) => i !== index));
    };

    const uploadFiles = async () => {
        const uploadPromises = filesToUpload.map(async (file) => {
            const fileExtension = file.name.split('.').pop();
            const fileName = `bug-reports/${currentUser.email}/${uuidv4()}.${fileExtension}`;
            const storageRef = fireStorage.ref(fileName);
            
            await storageRef.put(file);
            const downloadURL = await storageRef.getDownloadURL();
            return {
                url: downloadURL,
                fileName: fileName,
                originalName: file.name,
                type: file.type
            };
        });

        return Promise.all(uploadPromises);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.selectedGame || !formData.sensitivity || !formData.title || !formData.description) {
            toast.error('Please fill in all required fields');
            return;
        }

        setLoading(true);
        try {
            const uploadedFiles = filesToUpload.length > 0 ? await uploadFiles() : [];

            const selectedGameInfo = gameNames.find(g => g.name === formData.selectedGame);
            
            const bugReportData = {
                gameName: formData.selectedGame,
                gameSlug: selectedGameInfo?.slug || '',
                reportId,
                type: selectedGameInfo?.type || 'game',
                sensitivity: formData.sensitivity,
                title: formData.title,
                description: formData.description.trim(),
                stepsToReproduce: formData.stepsToReproduce
                    .split('\n')
                    .map(step => step.trim())
                    .filter(step => step !== ''),
                expectedBehavior: formData.expectedBehavior.trim(),
                actualBehavior: formData.actualBehavior.trim(),
                images: uploadedFiles,
                submittedBy: {
                    email: currentUser.email,
                    name: currentUser.name || 'Unknown User'
                },
                submittedAt: new Date(),
                status: 'pending'
            };

            const bugReportRef = await bugReportsCollectionRef.add(bugReportData);

            if (reportDoc) {
                await reportDoc.update({
                    bugReports: firebase.firestore.FieldValue.arrayUnion({
                        ...bugReportData,
                        id: bugReportRef.id,
                        createdAt: new Date(),
                        updatedAt: new Date()
                    }),
                    lastUpdated: new Date()
                });
            }

            toast.success('Bug report submitted successfully');
            handleClose();
        } catch (error) {
            console.error('Error submitting bug report:', error);
            toast.error('Failed to submit bug report');
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button 
                variant="danger" 
                size="sm" 
                onClick={handleShow}
                className="bug-report-btn"
                disabled={!selectedGames?.length}
            >
                Report Bug
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="bg-danger text-white" closeButton>
                    <Modal.Title>Report Bug</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Select Game/App*</Form.Label>
                            <Form.Select
                                name="selectedGame"
                                value={formData.selectedGame}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select a game/app...</option>
                                {selectedGames?.map(gameName => (
                                    <option key={gameName} value={gameName}>
                                        {gameName} ({gameNames.find(g => g.name === gameName)?.type === 'app' ? 'App' : 'Game'})
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Bug Sensitivity Level*</Form.Label>
                            <Form.Select
                                name="sensitivity"
                                value={formData.sensitivity}
                                onChange={handleInputChange}
                                required
                            >
                                <option value="">Select sensitivity level...</option>
                                {SENSITIVITY_LEVELS.map(level => (
                                    <option key={level.value} value={level.value}>
                                        {level.label}
                                    </option>
                                ))}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Bug Title*</Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleInputChange}
                                placeholder="Brief description of the bug"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Detailed Description*</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                placeholder="Please provide steps to reproduce and any additional details..."
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Steps to Reproduce*</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={4}
                                name="stepsToReproduce"
                                value={formData.stepsToReproduce}
                                onChange={handleInputChange}
                                placeholder="1. Open the game
2. Click on start button
3. Navigate to settings
..."
                                required
                            />
                            <Form.Text className="text-muted">
                                Enter each step on a new line. Empty lines will be ignored.
                            </Form.Text>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Expected Behavior*</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                name="expectedBehavior"
                                value={formData.expectedBehavior}
                                onChange={handleInputChange}
                                placeholder="Please describe the expected behavior"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Actual Behavior*</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={2}
                                name="actualBehavior"
                                value={formData.actualBehavior}
                                onChange={handleInputChange}
                                placeholder="Please describe the actual behavior"
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Upload Files</Form.Label>
                            <Form.Control
                                type="file"
                                accept={[...ACCEPTED_FILE_TYPES.images, ...ACCEPTED_FILE_TYPES.documents].join(',')}
                                multiple
                                onChange={handleFileChange}
                            />
                            <Form.Text className="text-muted">
                                Upload screenshots or documents to help illustrate the bug
                            </Form.Text>
                        </Form.Group>

                        {selectedFiles.length > 0 && (
                            <div className="selected-files mb-3">
                                <div className="file-preview-container">
                                    {selectedFiles.map((file, index) => (
                                        <div key={index} className="file-preview">
                                            {file.preview ? (
                                                <Image src={file.preview} thumbnail />
                                            ) : (
                                                <div className="document-preview">
                                                    <i className="fas fa-file-alt"></i>
                                                    <span>{file.name}</span>
                                                </div>
                                            )}
                                            <Button
                                                variant="danger"
                                                size="sm"
                                                className="remove-file"
                                                onClick={() => removeFile(index)}
                                            >
                                                ×
                                            </Button>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button 
                        variant="danger" 
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? 'Submitting...' : 'Submit Bug Report'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BugReportSubmissionModal;