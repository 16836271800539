import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Image, Badge, Accordion } from "react-bootstrap";
import gameNames from "./data-game-names";
import {
    testingReportsCollectionRef,
    testingStatusCollectionRef,
    fireStorage,
} from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import BugReportSubmissionModal from "./BugReportSubmissionModal";
import "./EditGameReviewModal.scss";
import { gameTestingReportMessage } from "../../utilities/emailGenerator";
import emailjs from "@emailjs/browser";
import { webServerLive } from "../../firebase/liveServerStatus";
import BugEditModal from "./Components/BugEditModal";
import BugDetailsModal from "./BugDetailsModal";
import BugReportSummaryTable from "./Components/BugReportSummaryTable";
const ACCEPTED_FILE_TYPES = {
    images: ["image/jpeg", "image/png", "image/gif", "image/webp"],
    documents: [
        "text/plain",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
};

const EditGameReviewModal = ({ show, onHide, report }) => {
    const { currentUser } = useAuth();
    const [loading, setLoading] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const [bugReports, setBugReports] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const formatDate = (timestamp) => {
        if (!timestamp) return "N/A";
        try {
            return timestamp.toDate().toLocaleDateString();
        } catch (error) {
            return "Invalid Date";
        }
    };
    const [formData, setFormData] = useState({
        selectedGames: [],
        tutorialFeedback: "",
        levelsFeedback: "",
        positiveAspects: "",
        improvementSuggestions: "",
        overallExperience: "",
        images: [],
    });

    useEffect(() => {
        if (report) {
            console.log("Loading report data:", report);
            setFormData({
                selectedGames: report.selectedGames || [],
                tutorialFeedback: report.tutorialFeedback || "",
                levelsFeedback: report.levelsFeedback || "",
                positiveAspects: report.positiveAspects || "",
                improvementSuggestions: report.improvementSuggestions || "",
                overallExperience: report.overallExperience || "",
                images: report.images || [],
            });

            console.log("Bug reports from report:", report.bugReports);
            setBugReports(report.bugReports || []);
            setSelectedImages(report.images?.map((img) => img.url) || []);
        }
    }, [report]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        const fileObjects = files.map((file) => ({
            file,
            preview: ACCEPTED_FILE_TYPES.images.includes(file.type)
                ? URL.createObjectURL(file)
                : null,
            type: file.type,
            name: file.name,
        }));

        setSelectedFiles((prev) => [...prev, ...fileObjects]);
        setFilesToUpload((prev) => [...prev, ...files]);
    };

    const removeFile = (index) => {
        setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
        setFilesToUpload((prev) => prev.filter((_, i) => i !== index));
    };

    const uploadFiles = async () => {
        const uploadPromises = filesToUpload.map(async (file) => {
            const fileExtension = file.name.split(".").pop();
            const fileName = `game-testing/${
                currentUser.email
            }/${uuidv4()}.${fileExtension}`;
            const storageRef = fireStorage.ref(fileName);

            await storageRef.put(file);
            const downloadURL = await storageRef.getDownloadURL();
            return {
                url: downloadURL,
                fileName: fileName,
                originalName: file.name,
                type: file.type,
            };
        });

        return Promise.all(uploadPromises);
    };

    const handleGameSelection = (gameName) => {
        setFormData((prev) => ({
            ...prev,
            selectedGames: prev.selectedGames.includes(gameName)
                ? prev.selectedGames.filter((g) => g !== gameName)
                : [...prev.selectedGames, gameName],
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formData.selectedGames.length === 0) {
            toast.error("Please select at least one game or app");
            return;
        }

        setLoading(true);
        try {
            // Upload new files
            const newFiles =
                filesToUpload.length > 0 ? await uploadFiles() : [];

            // Combine existing and new files
            const existingFiles = formData.images || [];
            const allFiles = [...existingFiles, ...newFiles];

            // Get the current report data to preserve bugReports
            const currentReport = await testingReportsCollectionRef
                .doc(report.id)
                .get();
            const currentData = currentReport.data();

            // Update report document
            await testingReportsCollectionRef.doc(report.id).update({
                selectedGames: formData.selectedGames,
                tutorialFeedback: formData.tutorialFeedback,
                levelsFeedback: formData.levelsFeedback,
                positiveAspects: formData.positiveAspects,
                improvementSuggestions: formData.improvementSuggestions,
                overallExperience: formData.overallExperience,
                images: allFiles,
                bugReports: currentData.bugReports || [],
                lastUpdated: new Date(),
                status: "test_report_available",
            });

            const mailDetails = gameTestingReportMessage({
                name: currentUser.name,
                email: currentUser.email,
                mailOne: currentUser.email,
                mailTwo:
                    currentUser.email === "zied.tayeb@myelinh.com"
                        ? ""
                        : "zied.tayeb@myelinh.com",
                mailThree:
                    currentUser.email === "samaher.garbaya@myelinh.com"
                        ? ""
                        : "samaher.garbaya@myelinh.com",
                mailFour:
                    currentUser.email === "shahjahan.swajan@myelinh.com"
                        ? ""
                        : "shahjahan.swajan@myelinh.com",
                mailFour:
                    currentUser.email === "bernhard.specht@myelinh.com"
                        ? ""
                        : "bernhard.specht@myelinh.com",
            });
            try {
                if (webServerLive) {
                    await emailjs.send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetails,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                    toast.success(
                        `Weekly report reminder sent to ${mailDetails.mailThree}`
                    );
                } else {
                    toast.info(
                        `Email would be sent to ${mailDetails.mailThree} (Development Mode)`
                    );
                }
            } catch (error) {
                console.error(
                    "Error sending email to",
                    mailDetails.mailThree,
                    error
                );
                toast.error(
                    `Error sending reminder to ${mailDetails.mailThree}`
                );
            }

            // Log the update for debugging
            console.log(
                "Updated report with bug reports:",
                currentData.bugReports || []
            );

            // Update status document
            const statusSnapshot = await testingStatusCollectionRef
                .where("reportId", "==", report.id)
                .get();

            if (!statusSnapshot.empty) {
                const statusDoc = statusSnapshot.docs[0];
                const currentStatus = statusDoc.data();

                await statusDoc.ref.update({
                    status: "test_report_available",
                    updatedAt: new Date(),
                    updatedBy: {
                        email: currentUser.email,
                        name: currentUser.name,
                    },
                    history: [
                        ...currentStatus.history,
                        {
                            status: "test_report_available",
                            timestamp: new Date(),
                            updatedBy: {
                                email: currentUser.email,
                                name: currentUser.name,
                            },
                        },
                    ],
                });
            }

            toast.success("Game/App review updated successfully");
            onHide();
        } catch (error) {
            console.error("Error updating game/app review:", error);
            toast.error("Failed to update game/app review");
        } finally {
            setLoading(false);
        }
    };

    const getSensitivityColor = (sensitivity) => {
        switch (sensitivity) {
            case "low":
                return "success";
            case "medium":
                return "warning";
            case "high":
                return "orange";
            case "critical":
                return "danger";
            default:
                return "secondary";
        }
    };

    const handleBugDelete = async (bugId) => {
        if (
            window.confirm("Are you sure you want to delete this bug report?")
        ) {
            try {
                const updatedBugReports = bugReports.filter(
                    (bug) => bug.id !== bugId
                );
                setBugReports(updatedBugReports);

                // Update the report document
                await testingReportsCollectionRef.doc(report.id).update({
                    bugReports: updatedBugReports,
                    lastUpdated: new Date(),
                });

                toast.success("Bug report deleted successfully");
            } catch (error) {
                console.error("Error deleting bug report:", error);
                toast.error("Failed to delete bug report");
            }
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header className="d-flex align-items-center" closeButton>
                <div className="d-flex w-100 justify-content-around">
                    <Modal.Title>Edit Game/App Review</Modal.Title>
                    {formData.selectedGames.length > 0 ? (
                        <div className="text-right mb-3">
                            <BugReportSubmissionModal
                                reportId={report.id}
                                selectedGames={formData.selectedGames}
                            />
                        </div>
                    ) : (
                        <button disabled className="btn btn-danger">
                            Report Bug
                        </button>
                    )}
                </div>
            </Modal.Header>
            <Modal.Body>
                {report.bugReports?.length > 0 && (
                    <BugReportSummaryTable report={report} />
                )}
             

                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3">
                        <Form.Label>Select Games/Apps*</Form.Label>
                        <div className="game-selection-grid">
                            {gameNames.map((game) => (
                                <Form.Check
                                    key={game.id}
                                    type="checkbox"
                                    id={`game-${game.id}`}
                                    label={`${game.name} ${
                                        game.type === "app" ? "(App)" : "(Game)"
                                    }`}
                                    checked={formData.selectedGames.includes(
                                        game.name
                                    )}
                                    onChange={() =>
                                        handleGameSelection(game.name)
                                    }
                                />
                            ))}
                        </div>
                        <Form.Text className="text-muted">
                            Select one or more games/apps to review
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Feedback on Game Tutorial</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="tutorialFeedback"
                            value={formData.tutorialFeedback}
                            onChange={handleInputChange}
                            placeholder="Provide feedback about the game tutorial..."
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>
                            Feedback on Game Levels & Difficulty
                        </Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="levelsFeedback"
                            value={formData.levelsFeedback}
                            onChange={handleInputChange}
                            placeholder="Provide feedback about game levels and difficulty..."
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Positive Aspects</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="positiveAspects"
                            value={formData.positiveAspects}
                            onChange={handleInputChange}
                            placeholder="What aspects of the game worked well?"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Suggestions for Improvement</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="improvementSuggestions"
                            value={formData.improvementSuggestions}
                            onChange={handleInputChange}
                            placeholder="What could be improved?"
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Overall Experience</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            name="overallExperience"
                            value={formData.overallExperience}
                            onChange={handleInputChange}
                            placeholder="Describe your overall experience with the game..."
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Upload Files</Form.Label>
                        <Form.Control
                            type="file"
                            accept={[
                                ...ACCEPTED_FILE_TYPES.images,
                                ...ACCEPTED_FILE_TYPES.documents,
                            ].join(",")}
                            multiple
                            onChange={handleFileChange}
                        />
                        <Form.Text className="text-muted">
                            You can upload images (.jpg, .png, .gif) and
                            documents (.txt, .pdf, .doc, .docx)
                        </Form.Text>
                    </Form.Group>

                    {selectedFiles.length > 0 && (
                        <div className="selected-files mb-3">
                            <div className="file-preview-container">
                                {selectedFiles.map((file, index) => (
                                    <div key={index} className="file-preview">
                                        {file.preview ? (
                                            <Image
                                                src={file.preview}
                                                thumbnail
                                            />
                                        ) : (
                                            <div className="document-preview">
                                                <i className="fas fa-file-alt"></i>
                                                <span>{file.name}</span>
                                            </div>
                                        )}
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="remove-file"
                                            onClick={() => removeFile(index)}
                                        >
                                            ×
                                        </Button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    disabled={loading}
                >
                    {loading ? "Updating..." : "Update Review"}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EditGameReviewModal;
