import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Nav, Alert, Spinner } from "react-bootstrap";
import { startOfWeek, endOfWeek, isBefore, isAfter, addWeeks, subWeeks } from "date-fns";
import {
    progressReportsCollectionRef,
    monthlyScoresCollectionRef,
} from "../../firebase/fire-app";
import ReportCalendar from "./components/ReportCalendar";
import WeeklyReportForm from "./components/WeeklyReportForm";
import MonthlyScoreCard from "./components/MonthlyScoreCard";
import "./PerformanceManagementReview.scss";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";

const PerformanceManagementReview = () => {
    // week starts on monday

    const [selectedRange, setSelectedRange] = useState({
        startDate: startOfWeek(new Date(), { weekStartsOn: 1 }),
        endDate: endOfWeek(new Date(), { weekStartsOn: 1 }),
    });
    const { currentUser } = useAuth();
    const [currentReport, setCurrentReport] = useState(null);
    const [submittedWeeks, setSubmittedWeeks] = useState([]);
    const [monthlyScores, setMonthlyScores] = useState([]);
    const [error, setError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(false);
    const [reportStatus, setReportStatus] = useState(null);

    useEffect(() => {
        // Fetch submitted reports for the calendar
        const fetchSubmittedReports = async () => {
            try {
                const reportsSnapshot = await progressReportsCollectionRef
                    .where("email", "==", currentUser.email)
                    .get();

                const submittedDates = reportsSnapshot.docs.map((doc) => ({
                    startDate: doc.data().weekStartDate.toDate(),
                    endDate: doc.data().weekEndDate.toDate(),
                    status: doc.data().status,
                }));

                setSubmittedWeeks(submittedDates);
                
                // Check if there's a report for the currently selected week
                const currentWeekReport = submittedDates.find(
                    week => week.startDate.getTime() === selectedRange.startDate.getTime()
                );
                
                if (currentWeekReport) {
                    fetchReportForWeek(selectedRange);
                } else {
                    setCurrentReport(null);
                    setReportStatus(null);
                }
            } catch (err) {
                setError("Failed to fetch submitted reports");
                console.error(err);
            }
        };

        fetchSubmittedReports();
    }, [currentUser.email, selectedRange.startDate]);

    useEffect(() => {
        // Reset success message after 3 seconds
        if (submitSuccess) {
            const timer = setTimeout(() => {
                setSubmitSuccess(false);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [submitSuccess]);

    useEffect(() => {
        // Fetch monthly scores
        const fetchMonthlyScores = async () => {
            try {
                const scoresSnapshot = await monthlyScoresCollectionRef
                    .where("email", "==", currentUser.email)
                    .orderBy("year", "desc")
                    .orderBy("month", "desc")
                    .limit(3)
                    .get();

                const scores = scoresSnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                setMonthlyScores(scores);
            } catch (err) {
                setError("Failed to fetch monthly scores");
                console.error(err);
            }
        };

        fetchMonthlyScores();
    }, [currentUser.email]);

    const isWeekEditable = (weekStart) => {
        // Don't allow future weeks
        const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
        if (isAfter(weekStart, currentWeekStart)) {
            return false;
        }

        // Find existing report for this week
        const existingReport = submittedWeeks.find(
            week => week.startDate.getTime() === weekStart.getTime()
        );

        // Allow editing if:
        // 1. No report exists (can submit new report)
        // 2. Report exists and status is "pending" (can edit)
        return !existingReport || existingReport.status === "pending";
    };

    const handleRangeChange = async (range) => {
        // Don't allow selecting future weeks
        const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
        if (isAfter(range.startDate, currentWeekStart)) {
            setError("Cannot select future weeks");
            return;
        }

        setSelectedRange(range);
        setError(null);
        setSubmitSuccess(false);

        try {
            // Fetch report for selected week
            const reportSnapshot = await progressReportsCollectionRef
                .where("weekStartDate", "==", range.startDate)
                .where("email", "==", currentUser.email)
                .get();

            if (!reportSnapshot.empty) {
                const reportData = reportSnapshot.docs[0].data();
                setCurrentReport({
                    id: reportSnapshot.docs[0].id,
                    ...reportData,
                });
                setReportStatus(reportData.status || "pending");
            } else {
                setCurrentReport(null);
                setReportStatus(null);
            }
        } catch (err) {
            setError("Failed to fetch report");
            console.error(err);
        }
    };

    const fetchReportForWeek = async (range) => {
        try {
            const reportSnapshot = await progressReportsCollectionRef
                .where("weekStartDate", "==", range.startDate)
                .where("email", "==", currentUser.email)
                .get();

            if (!reportSnapshot.empty) {
                const reportData = reportSnapshot.docs[0].data();
                setCurrentReport({
                    id: reportSnapshot.docs[0].id,
                    ...reportData,
                });
                setReportStatus(reportData.status);
            } else {
                setCurrentReport(null);
                setReportStatus(null);
            }
        } catch (err) {
            setError("Failed to fetch report");
            console.error(err);
        }
    };

    const handleReportSubmit = async (formData) => {
        // Don't allow future dates
        if (isAfter(selectedRange.startDate, startOfWeek(new Date(), { weekStartsOn: 1 }))) {
            console.log("start date", selectedRange.startDate);
            console.log("start of week", startOfWeek(new Date(), { weekStartsOn: 1 }));
            setError("Cannot submit reports for future weeks");
            return;
        }

        setIsSubmitting(true);
        setError(null);
        setSubmitSuccess(false);

        try {
            const reportData = {
                email: currentUser.email,
                weekStartDate: selectedRange.startDate,
                weekEndDate: selectedRange.endDate,
                ...formData,
                status: "pending",
                submittedAt: new Date(),
            };

            if (currentReport?.id) {
                // Update existing report
                await progressReportsCollectionRef
                    .doc(currentReport.id)
                    .update(reportData);
            } else {
                // Create new report
                await progressReportsCollectionRef.add(reportData);
            }

            // Refresh the submitted weeks
            const reportsSnapshot = await progressReportsCollectionRef
                .where("email", "==", currentUser.email)
                .get();

            const submittedDates = reportsSnapshot.docs.map((doc) => ({
                startDate: doc.data().weekStartDate.toDate(),
                endDate: doc.data().weekEndDate.toDate(),
                status: doc.data().status || "pending",
            }));

            setSubmittedWeeks(submittedDates);
            
            // Refresh current report data
            const currentReportSnapshot = await progressReportsCollectionRef
                .where("weekStartDate", "==", selectedRange.startDate)
                .where("email", "==", currentUser.email)
                .get();

            if (!currentReportSnapshot.empty) {
                const reportData = currentReportSnapshot.docs[0].data();
                setCurrentReport({
                    id: currentReportSnapshot.docs[0].id,
                    ...reportData,
                });
                setReportStatus(reportData.status || "pending");
            }
            
            setSubmitSuccess(true);
        } catch (err) {
            setError("Failed to submit report");
            console.error(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <SidebarComponent>
            <section className="sticky-top page-heading">
                <div className="container">
                    <div className="">
                        <h3 className="mb-0">Weekly Progress Report</h3>
                    </div>
                </div>
            </section>
            <section className="bg-light performance-review-section">
                <Container fluid className="performance-review">
                    {error && (
                        <Alert
                            variant="danger"
                            onClose={() => setError(null)}
                            dismissible
                        >
                            {error}
                        </Alert>
                    )}
                    {submitSuccess && (
                        <Alert
                            variant="success"
                            onClose={() => setSubmitSuccess(false)}
                            dismissible
                        >
                            Report submitted successfully!
                        </Alert>
                    )}
                    {isSubmitting && (
                        <Alert variant="info" className="d-flex align-items-center">
                            <Spinner
                                animation="border"
                                size="sm"
                                className="me-2"
                            />
                            Submitting your report...
                        </Alert>
                    )}

                    <Tab.Container defaultActiveKey="submit">
                        <Row>
                            <Col md={3} lg={2} className="vertical-tabs">
                                <Nav variant="pills" className="flex-column">
                                    <Nav.Item>
                                        <Nav.Link eventKey="submit">
                                            Submit Report
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="monthly">
                                            Score Overview
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Col>

                            <Col md={9} lg={10} className="tab-content-wrapper">
                                <Tab.Content>
                                    <Tab.Pane eventKey="submit">
                                        <ReportCalendar
                                            selectedRange={selectedRange}
                                            onRangeChange={handleRangeChange}
                                            submittedWeeks={submittedWeeks}
                                        />
                                        <WeeklyReportForm
                                            selectedWeek={selectedRange}
                                            onSubmit={handleReportSubmit}
                                            existingReport={currentReport}
                                            isSubmitting={isSubmitting}
                                            submitSuccess={submitSuccess}
                                            reportStatus={reportStatus}
                                            isEditable={isWeekEditable(selectedRange.startDate)}
                                        />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="monthly">
                                        {monthlyScores.map((monthData) => (
                                            <MonthlyScoreCard
                                                key={monthData.id}
                                                monthlyData={monthData}
                                            />
                                        ))}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Container>
            </section>
        </SidebarComponent>
    );
};

export default PerformanceManagementReview;
