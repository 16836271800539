import React, { useState } from "react";
import { Modal, Button, Form, Badge, Image } from "react-bootstrap";
import {
    bugReportsCollectionRef,
    testingReportsCollectionRef,
    fireStorage,
} from "../../../firebase/fire-app";
import { toast } from "react-toastify";
import { useAuth } from "../../../Hooks/useAuth";
import { v4 as uuidv4 } from "uuid";

const SENSITIVITY_LEVELS = [
    { value: "low", label: "Low - Minor visual or non-functional issues" },
    { value: "medium", label: "Medium - Functional issues with workarounds" },
    { value: "high", label: "High - Major functional issues" },
    { value: "critical", label: "Critical - Game breaking issues" },
];

const ACCEPTED_FILE_TYPES = {
    images: ["image/jpeg", "image/png", "image/gif", "image/webp"],
    documents: [
        "text/plain",
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
};

const BugEditModal = ({ bug, reportId }) => {
    const { currentUser } = useAuth();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [filesToUpload, setFilesToUpload] = useState([]);
    const [formData, setFormData] = useState({
        title: bug?.title || "",
        description: bug?.description || "",
        sensitivity: bug?.sensitivity || "low",
        stepsToReproduce: bug?.stepsToReproduce?.join("\n") || "",
        expectedBehavior: bug?.expectedBehavior || "",
        actualBehavior: bug?.actualBehavior || "",
        status: bug?.status || "pending",
        images: bug?.images || [],
    });

    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setSelectedFiles([]);
        setFilesToUpload([]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);

        const fileObjects = files.map((file) => ({
            file,
            preview: URL.createObjectURL(file),
            type: file.type,
            name: file.name,
        }));

        setSelectedFiles((prev) => [...prev, ...fileObjects]);
        setFilesToUpload((prev) => [...prev, ...files]);
    };

    const removeFile = (index) => {
        setSelectedFiles((prev) => prev.filter((_, i) => i !== index));
        setFilesToUpload((prev) => prev.filter((_, i) => i !== index));
    };

    const removeExistingFile = (index) => {
        setFormData((prev) => ({
            ...prev,
            images: prev.images.filter((_, i) => i !== index),
        }));
    };

    const uploadFiles = async () => {
        const uploadPromises = filesToUpload.map(async (file) => {
            const fileExtension = file.name.split(".").pop();
            const fileName = `bug-reports/${
                currentUser.email
            }/${uuidv4()}.${fileExtension}`;
            const storageRef = fireStorage.ref(fileName);

            await storageRef.put(file);
            const downloadURL = await storageRef.getDownloadURL();
            return {
                url: downloadURL,
                fileName: fileName,
                originalName: file.name,
                type: file.type,
            };
        });

        return Promise.all(uploadPromises);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            // Upload new files
            const newFiles =
                filesToUpload.length > 0 ? await uploadFiles() : [];

            // Combine existing and new files
            const allFiles = [...formData.images, ...newFiles];

            // Convert steps to array
            const stepsArray = formData.stepsToReproduce
                .split("\n")
                .map((step) => step.trim())
                .filter((step) => step !== "");

            // Prepare update data
            const updateData = {
                ...formData,
                stepsToReproduce: stepsArray,
                images: allFiles,
                lastUpdated: new Date(),
                updatedBy: {
                    name: currentUser.name,
                    email: currentUser.email,
                },
            };

            // Update individual bug document
            await bugReportsCollectionRef.doc(bug.id).update(updateData);

            // Update bug in the report's bugReports array
            const reportRef = testingReportsCollectionRef.doc(reportId);
            const reportDoc = await reportRef.get();
            const reportData = reportDoc.data();

            const updatedBugReports = reportData.bugReports.map((reportBug) =>
                reportBug.id === bug.id
                    ? {
                          ...reportBug,
                          ...updateData,
                      }
                    : reportBug
            );

            await reportRef.update({
                bugReports: updatedBugReports,
                lastUpdated: new Date(),
            });

            toast.success("Bug report updated successfully");
            handleClose();
        } catch (error) {
            console.error("Error updating bug report:", error);
            toast.error("Failed to update bug report");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                variant="outline-primary"
                size="sm"
                onClick={handleShow}
                className="me-2"
            >
                Edit Bug
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Edit Bug Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={handleSubmit}>
                        <div className="row">
                            {/* First Column */}
                            <div className="col-md-6">
                                <Form.Group className="mb-3">
                                    <Form.Label>Bug Title*</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <div className="row">
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>
                                                Sensitivity*
                                            </Form.Label>
                                            <Form.Select
                                                name="sensitivity"
                                                value={formData.sensitivity}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                {SENSITIVITY_LEVELS.map(
                                                    (level) => (
                                                        <option
                                                            key={level.value}
                                                            value={level.value}
                                                        >
                                                            {level.value
                                                                .charAt(0)
                                                                .toUpperCase() +
                                                                level.value.slice(
                                                                    1
                                                                )}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                    <div className="col-md-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Status*</Form.Label>
                                            <Form.Select
                                                name="status"
                                                value={formData.status}
                                                onChange={handleInputChange}
                                                required
                                            >
                                                <option value="pending">
                                                    Pending
                                                </option>
                                                <option value="in_progress">
                                                    In Progress
                                                </option>
                                                <option value="resolved">
                                                    Resolved
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Description*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="description"
                                        value={formData.description}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Steps to Reproduce*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={3}
                                        name="stepsToReproduce"
                                        value={formData.stepsToReproduce}
                                        onChange={handleInputChange}
                                        placeholder="Enter each step on a new line"
                                        required
                                    />
                                </Form.Group>
                            </div>

                            {/* Second Column */}
                            <div className="col-md-12">
                                <Form.Group className="mb-3">
                                    <Form.Label>Expected Behavior*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        name="expectedBehavior"
                                        value={formData.expectedBehavior}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>Actual Behavior*</Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        rows={2}
                                        name="actualBehavior"
                                        value={formData.actualBehavior}
                                        onChange={handleInputChange}
                                        required
                                    />
                                </Form.Group>

                                {/* File Upload Section */}
                                <Form.Group className="mb-3">
                                    <Form.Label>Current Files</Form.Label>
                                    <div className="existing-files mb-2">
                                        {formData.images.length > 0 ? (
                                            <div className="file-preview-container">
                                                {formData.images.map(
                                                    (file, index) => (
                                                        <div
                                                            key={index}
                                                            className="file-preview"
                                                        >
                                                            {file.type?.includes(
                                                                "image"
                                                            ) ? (
                                                                <Image
                                                                    src={
                                                                        file.url
                                                                    }
                                                                    thumbnail
                                                                    className="preview-image"
                                                                />
                                                            ) : (
                                                                <div className="document-preview">
                                                                    <i className="fas fa-file-alt"></i>
                                                                    <span>
                                                                        {
                                                                            file.originalName
                                                                        }
                                                                    </span>
                                                                </div>
                                                            )}
                                                            <Button
                                                                variant="danger"
                                                                size="sm"
                                                                className="remove-file"
                                                                onClick={() =>
                                                                    removeExistingFile(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                ×
                                                            </Button>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <p className="text-muted small">
                                                No files attached
                                            </p>
                                        )}
                                    </div>

                                    <Form.Control
                                        type="file"
                                        accept={[
                                            ...ACCEPTED_FILE_TYPES.images,
                                            ...ACCEPTED_FILE_TYPES.documents,
                                        ].join(",")}
                                        multiple
                                        onChange={handleFileChange}
                                        size="sm"
                                    />
                                </Form.Group>

                                {selectedFiles.length > 0 && (
                                    <div className="selected-files mb-3">
                                        <Form.Label>New Files</Form.Label>
                                        <div className="file-preview-container">
                                            {selectedFiles.map(
                                                (file, index) => (
                                                    <div
                                                        key={index}
                                                        className="file-preview"
                                                    >
                                                        {file.preview ? (
                                                            <Image
                                                                src={
                                                                    file.preview
                                                                }
                                                                thumbnail
                                                                className="preview-image"
                                                            />
                                                        ) : (
                                                            <div className="document-preview">
                                                                <i className="fas fa-file-alt"></i>
                                                                <span>
                                                                    {file.name}
                                                                </span>
                                                            </div>
                                                        )}
                                                        <Button
                                                            variant="danger"
                                                            size="sm"
                                                            className="remove-file"
                                                            onClick={() =>
                                                                removeFile(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            ×
                                                        </Button>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="primary"
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        {loading ? "Updating..." : "Update Bug Report"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BugEditModal;
