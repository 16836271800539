import React, { useState, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import { fireStorage, myelinersCollectionRef } from "../../firebase/fire-app";
import { Spinner } from "react-bootstrap";

const DPUploadPopup = ({ data }) => {
    const [show, setShow] = useState(false);
    const [dpLoading, setDpLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewSrc, setPreviewSrc] = useState(null);
    const canvasRef = useRef(null);

    const onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setSelectedFile(file);
            
            // Create preview
            const reader = new FileReader();
            reader.onload = (event) => {
                setPreviewSrc(event.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const resizeAndCompressImage = (file) => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    // Set target dimensions (max 500px width or height)
                    const MAX_SIZE = 500;
                    let width = img.width;
                    let height = img.height;
                    
                    if (width > height) {
                        if (width > MAX_SIZE) {
                            height = Math.round(height * (MAX_SIZE / width));
                            width = MAX_SIZE;
                        }
                    } else {
                        if (height > MAX_SIZE) {
                            width = Math.round(width * (MAX_SIZE / height));
                            height = MAX_SIZE;
                        }
                    }
                    
                    // Create canvas and resize image
                    const canvas = canvasRef.current;
                    canvas.width = width;
                    canvas.height = height;
                    
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    
                    // Convert to blob with compression
                    canvas.toBlob((blob) => {
                        resolve(blob);
                    }, 'image/jpeg', 0.85); // Compression quality 85%
                };
                img.src = event.target.result;
            };
            reader.readAsDataURL(file);
        });
    };

    const uploadImage = async () => {
        if (!selectedFile) {
            alert("Please select an image first.");
            return;
        }

        setDpLoading(true);

        try {
            // Resize and compress the image
            const compressedBlob = await resizeAndCompressImage(selectedFile);
            
            // Create a storage reference
            const storageRef = fireStorage.ref();
            const fileName = `profile-pictures/${data.email}_${new Date().getTime()}`;
            const fileRef = storageRef.child(fileName);
            
            // Upload the blob
            const snapshot = await fileRef.put(compressedBlob);
            
            // Get the download URL
            const downloadURL = await snapshot.ref.getDownloadURL();
            
            // Update the user's document with the new image URL
            await myelinersCollectionRef.doc(data.email).update({
                img: downloadURL
            });
            
            alert("Profile picture uploaded successfully!");
            setShow(false);
            setSelectedFile(null);
            setPreviewSrc(null);
        } catch (error) {
            console.error("Error uploading image:", error);
            alert("Failed to upload image. Please try again.");
        } finally {
            setDpLoading(false);
        }
    };

    return (
        <>
            <button
                className="btn edit-btn btn-danger btn-smaller"
                onClick={() => setShow(true)}
            >
                Edit
            </button>

            {/* Hidden canvas for image processing */}
            <canvas ref={canvasRef} style={{ display: 'none' }} />

            <Modal
                backdrop="static"
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1" closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Edit Profile Picture
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <InputWrapper col="12" label="Select Image">
                                    <input
                                        id="file-upload"
                                        type="file"
                                        accept="image/*"
                                        onChange={onSelectFile}
                                        className="form-control"
                                    />
                                </InputWrapper>
                            </div>
                            
                            {previewSrc && (
                                <div className="preview-container text-center mt-3">
                                    <h6>Preview</h6>
                                    <img 
                                        src={previewSrc} 
                                        alt="Preview" 
                                        className="img-fluid rounded-circle"
                                        style={{ 
                                            maxWidth: '200px', 
                                            maxHeight: '200px',
                                            objectFit: 'cover',
                                            border: '1px solid #ddd'
                                        }} 
                                    />
                                    <div className="mt-2 text-muted small">
                                        Image will be resized and compressed automatically
                                    </div>
                                </div>
                            )}
                            
                            <div className="text-center mt-3">
                                {dpLoading ? (
                                    <button
                                        disabled
                                        className="btn btn-outline-primary"
                                    >
                                        Uploading <Spinner animation="border" size="sm" />
                                    </button>
                                ) : (
                                    <button
                                        onClick={uploadImage}
                                        className="btn btn-outline-primary"
                                        disabled={!selectedFile}
                                    >
                                        Upload
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="p-4 text-center">
                                <h6>Current Profile Picture</h6>
                                {data?.img ? (
                                    <img
                                        src={data.img}
                                        alt="Current profile"
                                        className="img-fluid rounded-circle"
                                        style={{ 
                                            width: '200px', 
                                            height: '200px', 
                                            objectFit: 'cover',
                                            border: '1px solid #ddd'
                                        }}
                                    />
                                ) : (
                                    <div className="text-center text-muted p-5 border rounded">
                                        No profile picture set
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DPUploadPopup;
