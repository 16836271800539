import React, { useState, useEffect } from 'react';
import { Modal, Form, Button, Badge, Alert } from 'react-bootstrap';
import { bugReportsCollectionRef } from '../../firebase/fire-app';
import { useAuth } from '../../Hooks/useAuth';
import './ModalAssignBug.scss';

const ModalAssignBug = ({ show, onHide, bug, myelinners }) => {
    const [selectedAssignee, setSelectedAssignee] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const { myelinners: currentUser } = useAuth();

    useEffect(() => {
        if (bug) {
            setSelectedAssignee(bug.assignedTo?.email || '');
            setError('');
        }
    }, [bug]);

    const handleSubmit = async () => {
        if (!bug || !selectedAssignee) {
            setError('Please select an assignee');
            return;
        }

        setLoading(true);
        setError('');

        try {
            const selectedUser = myelinners.find(user => user.email === selectedAssignee);
            
            await bugReportsCollectionRef.doc(bug.id).update({
                assignedTo: {
                    name: selectedUser.name,
                    email: selectedUser.email
                },
                status: 'assigned',
                lastUpdated: new Date(),
                updatedBy: {
                    name: currentUser[0].name,
                    email: currentUser[0].email
                }
            });

            onHide();
        } catch (error) {
            console.error('Error updating bug:', error);
            setError('Failed to update bug assignment. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const getSensitivityColor = (sensitivity) => {
        switch (sensitivity) {
            case 'low':
                return 'success';
            case 'medium':
                return 'warning';
            case 'high':
                return 'orange';
            case 'critical':
                return 'danger';
            default:
                return 'secondary';
        }
    };

    if (!bug) return null;

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Assign Bug</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="bug-details mb-4">
                    <h5>{bug.title}</h5>
                    <div className="meta-info">
                        <Badge bg={getSensitivityColor(bug.sensitivity)} className="me-2">
                            {bug.sensitivity}
                        </Badge>
                        <span className="text-muted">Game/App: {bug.gameName}</span>
                    </div>
                    <div className="description mt-3">
                        <h6>Description:</h6>
                        <p>{bug.description}</p>
                    </div>
                    {bug.stepsToReproduce && (
                        <div className="steps mt-3">
                            <h6>Steps to Reproduce:</h6>
                            <ol>
                                {bug.stepsToReproduce.map((step, index) => (
                                    <li key={index}>{step}</li>
                                ))}
                            </ol>
                        </div>
                    )}
                    {bug.expectedBehavior && (
                        <div className="expected mt-3">
                            <h6>Expected Behavior:</h6>
                            <p>{bug.expectedBehavior}</p>
                        </div>
                    )}
                    {bug.actualBehavior && (
                        <div className="actual mt-3">
                            <h6>Actual Behavior:</h6>
                            <p>{bug.actualBehavior}</p>
                        </div>
                    )}
                </div>

                <Form>
                    <Form.Group className="mb-3">
                        <Form.Label>Assign To</Form.Label>
                        <Form.Select
                            value={selectedAssignee}
                            onChange={(e) => {
                                setSelectedAssignee(e.target.value);
                                setError('');
                            }}
                            isInvalid={!!error}
                        >
                            <option value="">Select Assignee</option>
                            {myelinners.map(user => (
                                <option key={user.email} value={user.email}>
                                    {user.name}
                                </option>
                            ))}
                        </Form.Select>
                        {error && (
                            <Form.Control.Feedback type="invalid">
                                {error}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button 
                    variant="primary" 
                    onClick={handleSubmit}
                    disabled={loading || !selectedAssignee}
                >
                    {loading ? 'Assigning...' : 'Assign Bug'}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalAssignBug;