import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import InputWrapper from "../../Components/FormComponents/InputWrapper";
import ReactQuill from "react-quill";
import { assignedTasksCollectionRef } from "../../firebase/fire-app";
import { webServerLive } from "../../firebase/liveServerStatus";
import emailjs from "@emailjs/browser";
import { assignedNewTaskMessage } from "../ViewClaimManagement/messageGenerator";
import { Spinner } from "react-bootstrap";
import CompEmpCardtype from "../../Components/EmployeeCard/CompEmpCardtype";
import { MdGroupAdd } from "react-icons/md";
import { useAuth } from "../../Hooks/useAuth";
import CompTaskComments from "./CompTaskComments";
import {
    convertMillisecondsToDateTime,
    timeStampToDate,
} from "../../utilities";
import {
    defaultFormatDate,
    jsDateToDefaultFormatDate,
} from "../../utilities/convertTime";
import CompAttachmentSection from "./CompAttachmentSection";
import DeleteConfirmationBasic from "../../Components/DeletePopupConfirmation/DeleteConfirmationBasic";
import BasicActionConfirmation from "../../Components/DeletePopupConfirmation/BasicActionConfirmation";
import TaskEmailTrigger from "./TaskEmailTrigger";
import PopupAssignRoleToTask from "./PopupAssignRoleToTask";
import CompEmpOnComments from "../../Components/EmployeeCard/CompEmpOnComments";

const PopupAssignNewTask = ({ selectedEmpID, selectedEmpDetails, data }) => {
    const [show, setShow] = useState(false);
    const [roleWise, setRoleWise] = useState(data.roleBasedAssignment);
    const { myelinners, currentUser, isAdmin } = useAuth();

    const { register, handleSubmit, watch, errors } = useForm();
    const [content, setContent] = useState("");
    const [systemLoading, setSystemLoading] = useState(false);
    const [assignedTo, setAssignedTo] = useState([]);

    const handleChange = (value) => {
        setContent(value);
    };
    const onSubmit = (data, e) => {
        setSystemLoading(true);
        data.startDate = new Date(data.startDate);
        data.createdAt = new Date();
        data.details = content;
        data.assignedTo = selectedEmpID;
        data.status = "New task";

        const mailData = {
            title: data.title,
            details: data.details,
            name: selectedEmpDetails.name,

            status: "Approved",
            mailOne: selectedEmpDetails.id,
            mailTwo: "zied.tayeb@myelinh.com",
            from_name: "MyelinZ HR",
        };
        if (!webServerLive) {
            mailData.mailOne = "shahjahan.swajan@myelinh.com";
            mailData.mailTwo = "shahjahan.swajan@myelinh.com";
        }
        const mailDetails = assignedNewTaskMessage(mailData);
        assignedTasksCollectionRef.add(data).then(() => {
            e.target.reset();
            setSystemLoading(false);
            if (webServerLive) {
                emailjs.send(
                    "service_zinxo6v",
                    "template_eqvm7mf",
                    mailDetails,
                    "ygk5Yo8QhiJUWbVZM"
                );
            }
            setShow(false);
        });

    };
    const [taskDueDate, setTaskDueDate] = useState(null);

    useEffect(() => {
        if (data) {
            if (data.roleBasedAssignment) {
                setRoleWise(true);
            }
        }
        if (data.assignees) {
            setAssignedTo([...data.assignees]);
        }
        if (data) {
            const formDate = defaultFormatDate(data.endDate);
            setTaskDueDate(formDate);
        }
        if (data) {
            setContent(data.details);
        }
    }, [data]);
    const onAssigneeChange = (newEmp, action) => {
        if (action === "add") {
            const newCollection = [...new Set([...assignedTo, newEmp])];
            assignedTasksCollectionRef.doc(data.id).update({
                assignees: newCollection,
            });
            setAssignedTo([...newCollection]);
        } else {
            const newCollection = assignedTo.filter((item) => item !== newEmp);
            assignedTasksCollectionRef.doc(data.id).update({
                assignees: newCollection,
            });
            setAssignedTo([...newCollection]);
        }
    };
    const onAssigneeChangeRoleBased = (newEmp, action) => {
        if (action === "add") {
            const newCollection = [...new Set([...assignedTo, newEmp])];
            assignedTasksCollectionRef.doc(data.id).update({
                assignees: newCollection,
            });
            setAssignedTo([...newCollection]);
        } else {
            const newCollection = assignedTo.filter((item) => item !== newEmp);
            assignedTasksCollectionRef.doc(data.id).update({
                assignees: newCollection,
            });
            setAssignedTo([...newCollection]);
        }
    };

    const onTitleChange = (newTitle) => {
        assignedTasksCollectionRef.doc(data.id).update({
            title: newTitle,
        });
    };
    const onPriorityChange = (newPriority) => {
        assignedTasksCollectionRef.doc(data.id).update({
            priority: newPriority,
        });
    };
    const onProgressChange = (newProgress) => {
        assignedTasksCollectionRef.doc(data.id).update({
            status: newProgress,
        });
    };

    const onDetailsChange = () => {
        assignedTasksCollectionRef.doc(data.id).update({
            details: content,
        });
    };
    const onArchiveTask = () => {
        if (data.liveStatus === "active") {
            assignedTasksCollectionRef.doc(data.id).update({
                liveStatus: "archived",
            });
            setShow(false);
        } else {
            assignedTasksCollectionRef.doc(data.id).update({
                liveStatus: "active",
            });
            setShow(false);
        }
    };

    const onDeleteTask = () => {
        assignedTasksCollectionRef.doc(data.id).delete();
    };
    const onDueDateChanged = (e) => {
        const newJSDate = new Date(e.target.value);
        const defaultFormatDateText = jsDateToDefaultFormatDate(newJSDate);
        setTaskDueDate(defaultFormatDateText);
        assignedTasksCollectionRef
            .doc(data.id)
            .update({
                endDate: newJSDate,
            })
            .then(() => {
                alert("Due date has been updated!");
            });
    };
    return (
        <>
            <button
                onClick={() => setShow(true)}
                className="btn-primary btn btn-xs"
            >
                Details
            </button>

            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-60w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header className="py-1 " closeButton>
                    <h5
                        className="font-weight-bold mt-1"
                        id="example-custom-modal-styling-title"
                    >
                        Task: {data.title}
                    </h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <InputWrapper col="7" label="Title">
                            <input
                                type="text"
                                name="title"
                                onBlur={(e) => onTitleChange(e.target.value)}
                                className="form-control"
                                defaultValue={data?.title}
                            />
                        </InputWrapper>
                        <InputWrapper col="4" label="Priority">
                            <select
                                id="disabledSelect"
                                name="priority"
                                className="form-select"
                                onBlur={(e) => onPriorityChange(e.target.value)}
                            >
                                {data.priority === "High" ||
                                    (data.priority === "Medium" && (
                                        <option value={data.priority}>
                                            {data.priority}
                                        </option>
                                    ))}
                                <option
                                    value={
                                        data.priority === "High"
                                            ? "Medium"
                                            : data.priority === "Medium" &&
                                              "High"
                                    }
                                >
                                    High
                                </option>
                                {!data.priority && (
                                    <option>Select priority</option>
                                )}
                                {!data.priority && (
                                    <option value={"High"}>High</option>
                                )}
                                {!data.priority && (
                                    <option value={"Medium"}>Medium</option>
                                )}
                            </select>
                        </InputWrapper>
                        <InputWrapper col="4" label="Progress">
                            <select
                                id="disabledSelect"
                                name="status"
                                className="form-select"
                                onChange={(e) =>
                                    onProgressChange(e.target.value)
                                }
                            >
                                <option value={data.status}>
                                    {data.status}
                                </option>
                                <option value="In progress">In progress</option>
                                <option value="Completed">Completed</option>
                            </select>
                        </InputWrapper>

                        <InputWrapper col="4" label="Due date">
                            <input
                                onChange={(e) => onDueDateChanged(e)}
                                type="date"
                                name="endDate"
                                className="form-control"
                                defaultValue={taskDueDate}
                            />
                        </InputWrapper>
                        <div className="col-12">
                            <div className="d-flex align-items-center row mb-2 mt-3">
                                <div className="col-md-3 icon-container-lg d-flex align-items-center  text-primary">
                                    <div>
                                        <MdGroupAdd />
                                    </div>

                                    <div className="mx-3">Assignees</div>
                                </div>
                                <div className="col-md-4">
                                    <PopupAssignRoleToTask
                                    myelinners={myelinners}
                                        taskID={data.id}
                                        taskDetails={data}
                                        setRoleWise={setRoleWise}
                                        assignedTo={assignedTo}
                                        roleWise={roleWise}
                                    />
                                </div>
                                <div className="col-md-5 text-right">
                                    {isAdmin && (
                                        <TaskEmailTrigger data={data} />
                                    )}
                                </div>
                                <div className="col-12 py-3 mt-1 bg-light">

                               
                                {roleWise ? (
                                    <div className="row">
                                        {data.roleBasedAssignees &&
                                            data.roleBasedAssignees.length >
                                                0 &&
                                            data.roleBasedAssignees.map(
                                                (item) => (
                                                    <div
                                                        className="col-md-6"
                                                        key={item.email}
                                                    >
                                                       <div className="row d-flex align-items-center">
                                                       <div className="col-6">
                                                            <CompEmpOnComments
                                                                email={
                                                                    item.email
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-6">
                                                            <p className="mb-0 font-14 font-bold">
                                                                {item.role}
                                                            </p>
                                                        </div>
                                                        
                                                       </div>
                                                    </div>
                                                )
                                            )}
                                    </div>
                                ) : (
                                    <div className="col-md-5">
                                        <select
                                            onChange={(e) =>
                                                onAssigneeChange(
                                                    e.target.value,
                                                    "add"
                                                )
                                            }
                                            id="disabledSelect"
                                            className="form-select"
                                        >
                                            <option>Select Employee</option>
                                            {myelinners &&
                                                myelinners.length > 0 &&
                                                myelinners.map((item) => (
                                                    <option value={item.id}>
                                                        {item.id}
                                                    </option>
                                                ))}
                                        </select>
                                    </div>
                                )}
                                 </div>
                            </div>
                        </div>

                       {!roleWise && <div className="col-12 pb-3 pt-1 mb-4">
                            <div className="row d-flex">
                                {assignedTo.length > 0 &&
                                    assignedTo.map((item) => (
                                        <CompEmpCardtype
                                            email={item}
                                            action={onAssigneeChange}
                                        />
                                    ))}
                            </div>
                        </div>}

                        <div className="col-12">
                            <ReactQuill
                                value={content}
                                onChange={handleChange}
                                modules={PopupAssignNewTask.modules}
                                formats={PopupAssignNewTask.formats}
                                placeholder="Write task details..."
                            />
                            <div className="text-right">
                                <button
                                    onClick={onDetailsChange}
                                    className="btn btn-light btn-sm"
                                >
                                    Save details
                                </button>
                            </div>
                        </div>
                        {currentUser && (
                            <CompAttachmentSection
                                data={data}
                                currentUser={currentUser}
                                userEmail={currentUser.email}
                            />
                        )}
                        <div className="col-12 mt-4">
                            <CompTaskComments data={data} />
                        </div>
                        <hr className="my-4" />
                        <div className="text-center">
                            {data.liveStatus === "active" ? (
                                <BasicActionConfirmation
                                    btnText="Archive this task"
                                    onActionFunction={onArchiveTask}
                                    alertText="Make the task archived?"
                                />
                            ) : (
                                <BasicActionConfirmation
                                    btnText="Make this task live!"
                                    onActionFunction={onArchiveTask}
                                    alertText="Make the task live again?"
                                />
                            )}
                            {/* <button onClick={onArchiveTask} className="btn btn-primary btn-sm me-4">
                                Archive this task
                            </button> */}
                            <DeleteConfirmationBasic
                                onDeleteTriggered={onDeleteTask}
                                btnText="Delete this task"
                                btnSize="md"
                            />
                        </div>
                        {/* <div className="col-12 text-center mt-4">
                            {systemLoading ? (
                                <button
                                    disabled
                                    className="btn btn-sm btn-primary"
                                >
                                    Assign Task{" "}
                                    <Spinner animation="grow" size="sm" />
                                </button>
                            ) : (
                                <button className="btn btn-sm btn-primary">
                                    Assign Task
                                </button>
                            )}
                        </div> */}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PopupAssignNewTask;

PopupAssignNewTask.modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["clean"],
    ],
};

PopupAssignNewTask.formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
];
