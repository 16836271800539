import React from "react";
import { Badge } from "react-bootstrap";
import BugDetailsModal from "../BugDetailsModal";
import BugEditModal from "./BugEditModal";

const BugReportSummaryTable = ({ report }) => {
    const getSensitivityColor = (sensitivity) => {
        switch (sensitivity) {
            case "low":
                return "success";
            case "medium":
                return "warning";
            case "high":
                return "orange";
            case "critical":
                return "danger";
            default:
                return "secondary";
        }
    };
    const formatDate = (timestamp) => {
        if (!timestamp) return "N/A";
        try {
            return timestamp.toDate().toLocaleDateString();
        } catch (error) {
            return "Invalid Date";
        }
    };
    return (
        <>
            {report.bugReports?.length > 0 && (
                <div className="bug-reports-section mb-4">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h6 className="mb-0">
                            Reported Bugs ({report.bugReports.length})
                        </h6>
                        <div className="bug-stats">
                            <Badge bg="warning" className="me-2">
                                {
                                    report.bugReports.filter(
                                        (bug) => bug.status === "pending"
                                    ).length
                                }{" "}
                                Pending
                            </Badge>
                            <Badge bg="success">
                                {
                                    report.bugReports.filter(
                                        (bug) => bug.status === "resolved"
                                    ).length
                                }{" "}
                                Resolved
                            </Badge>
                        </div>
                    </div>

                    <div className="table-responsive">
                        <table className="table table-hover">
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Game</th>
                                    <th>Sensitivity</th>
                                    <th>Status</th>
                                    <th>Submitted By</th>
                                    <th>Date</th>
                                    <th>Edit</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {report.bugReports.map((bug, index) => (
                                    <tr key={bug.id || index}>
                                        <td>{bug.title || "Untitled"}</td>
                                        <td>{bug.gameName || "N/A"}</td>
                                        <td>
                                            <Badge
                                                bg={getSensitivityColor(
                                                    bug.sensitivity
                                                )}
                                            >
                                                {bug.sensitivity || "N/A"}
                                            </Badge>
                                        </td>
                                        <td>
                                            <Badge
                                                bg={
                                                    bug.status === "pending"
                                                        ? "warning"
                                                        : "success"
                                                }
                                            >
                                                {bug.status || "pending"}
                                            </Badge>
                                        </td>
                                        <td>
                                            {bug.submittedBy?.name ||
                                                "Unknown User"}
                                        </td>
                                        <td>{formatDate(bug.submittedAt)}</td>
                                        <td>
                                            <BugEditModal bug={bug} reportId={report.id} />
                                        </td>
                                        <td>
                                            {bug && (
                                                <BugDetailsModal
                                                    bug={{
                                                        ...bug,
                                                        submittedBy:
                                                            bug.submittedBy || {
                                                                name: "Unknown User",
                                                                email: "N/A",
                                                            },
                                                        submittedAt:
                                                            bug.submittedAt ||
                                                            new Date(),
                                                        title:
                                                            bug.title ||
                                                            "Untitled",
                                                        description:
                                                            bug.description ||
                                                            "No description provided",
                                                        sensitivity:
                                                            bug.sensitivity ||
                                                            "N/A",
                                                        status:
                                                            bug.status ||
                                                            "pending",
                                                        stepsToReproduce:
                                                            bug.stepsToReproduce ||
                                                            [],
                                                        expectedBehavior:
                                                            bug.expectedBehavior ||
                                                            "Not specified",
                                                        actualBehavior:
                                                            bug.actualBehavior ||
                                                            "Not specified",
                                                        images:
                                                            bug.images || [],
                                                    }}
                                                />
                                            )}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </>
    );
};

export default BugReportSummaryTable;
