import React, { useState, useEffect } from "react";
import {
    Card,
    Table,
    Badge,
    Form,
    Row,
    Col,
    Button,
    Modal,
    Pagination,
    Spinner
} from "react-bootstrap";
import { format } from "date-fns";
import { bugReportsCollectionRef } from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import ModalAssignBug from "./ModalAssignBug";
import "./ViewBugResolution.scss";
import BugDetailsModal from "./BugDetailsModal";

const ViewBugResolution = () => {
    const [bugs, setBugs] = useState([]);
    const [filteredBugs, setFilteredBugs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [selectedBug, setSelectedBug] = useState(null);
    const [newStatus, setNewStatus] = useState("");
    const [myAssignedBugs, setMyAssignedBugs] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("all");
    const { myelinners, currentUser } = useAuth();
    
    // Pagination states
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);

    // Filter states
    const [filters, setFilters] = useState({
        status: "",
        sensitivity: "",
        assignedTo: "",
        gameName: "",
        dateRange: {
            start: "",
            end: "",
        },
        searchTerm: "",
    });

    // Initial data fetch
    useEffect(() => {
        fetchBugs();
    }, []);

    // Function to fetch bugs with pagination
    const fetchBugs = async (loadMore = false) => {
        try {
            if (loadMore) {
                setLoadingMore(true);
            } else {
                setLoading(true);
                setLastVisible(null);
            }

            let query = bugReportsCollectionRef
                .orderBy("submittedAt", "desc")
                .limit(itemsPerPage);

            // If loading more, use the lastVisible document as starting point
            if (loadMore && lastVisible) {
                query = query.startAfter(lastVisible);
            }

            const snapshot = await query.get();
            
            if (snapshot.empty) {
                setHasMore(false);
                setLoading(false);
                setLoadingMore(false);
                return;
            }

            // Get the last visible document for pagination
            const lastDoc = snapshot.docs[snapshot.docs.length - 1];
            setLastVisible(lastDoc);

            const bugsData = snapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            // If loading more, append the new data
            if (loadMore) {
                setBugs(prev => [...prev, ...bugsData]);
                setFilteredBugs(prev => [...prev, ...bugsData]);
            } else {
                setBugs(bugsData);
                setFilteredBugs(bugsData);
            }

            // Check if there are more documents to fetch
            const nextQuery = bugReportsCollectionRef
                .orderBy("submittedAt", "desc")
                .startAfter(lastDoc)
                .limit(1);
                
            const nextSnapshot = await nextQuery.get();
            setHasMore(!nextSnapshot.empty);
            
        } catch (error) {
            console.error("Error fetching bugs:", error);
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    // Load more bugs
    const loadMoreBugs = () => {
        if (!loadingMore && hasMore) {
            fetchBugs(true);
        }
    };

    // Apply filters whenever filters state changes
    useEffect(() => {
        let filtered = [...bugs];

        // Filter by status
        if (filters.status) {
            filtered = filtered.filter((bug) => bug.status === filters.status);
        }

        // Filter by sensitivity
        if (filters.sensitivity) {
            filtered = filtered.filter(
                (bug) => bug.sensitivity === filters.sensitivity
            );
        }

        // Filter by assigned user
        if (filters.assignedTo) {
            filtered = filtered.filter(
                (bug) => bug.assignedTo?.email === filters.assignedTo
            );
        }

        // Filter by game
        if (filters.gameName) {
            filtered = filtered.filter(
                (bug) => bug.gameName === filters.gameName
            );
        }

        // Filter by date range
        if (filters.dateRange.start && filters.dateRange.end) {
            filtered = filtered.filter((bug) => {
                const bugDate = bug.submittedAt?.toDate() || new Date();
                const startDate = new Date(filters.dateRange.start);
                const endDate = new Date(filters.dateRange.end);
                return bugDate >= startDate && bugDate <= endDate;
            });
        }

        // Filter by search term
        if (filters.searchTerm) {
            const searchLower = filters.searchTerm.toLowerCase();
            filtered = filtered.filter(
                (bug) =>
                    bug.title.toLowerCase().includes(searchLower) ||
                    bug.description.toLowerCase().includes(searchLower) ||
                    bug.gameName.toLowerCase().includes(searchLower)
            );
        }

        setFilteredBugs(filtered);
    }, [filters, bugs]);

    // Get bugs assigned to current user
    useEffect(() => {
        const assignedBugs = bugs.filter(
            (bug) => bug.assignedTo?.email === currentUser?.email
        );

        // Filter by selected status
        const filteredBugs =
            selectedStatus === "all"
                ? assignedBugs
                : assignedBugs.filter((bug) => bug.status === selectedStatus);

        setMyAssignedBugs(filteredBugs);
    }, [bugs, currentUser?.email, selectedStatus]);

    // Get unique game names for filter
    const gameNames = [...new Set(bugs.map((bug) => bug.gameName))].sort();

    // Apply search filters and refresh data
    const applyFilters = () => {
        setLoading(true);
        setCurrentPage(1);
        fetchBugs();
    };

    // Reset filters and refresh data
    const resetFilters = () => {
        setFilters({
            status: "",
            sensitivity: "",
            assignedTo: "",
            gameName: "",
            dateRange: {
                start: "",
                end: "",
            },
            searchTerm: "",
        });
        setLoading(true);
        setCurrentPage(1);
        fetchBugs();
    };

    // Handle pagination
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const getSensitivityColor = (sensitivity) => {
        switch (sensitivity) {
            case "low":
                return "success";
            case "medium":
                return "warning";
            case "high":
                return "orange";
            case "critical":
                return "danger";
            default:
                return "secondary";
        }
    };

    const getStatusColor = (status) => {
        switch (status) {
            case "pending":
                return "warning";
            case "assigned":
                return "info";
            case "in_progress":
                return "primary";
            case "resolved":
                return "success";
            default:
                return "secondary";
        }
    };

    const handleFilterChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            [key]: value,
        }));
    };

    const handleDateRangeChange = (key, value) => {
        setFilters((prev) => ({
            ...prev,
            dateRange: {
                ...prev.dateRange,
                [key]: value,
            },
        }));
    };

    const handleStatusUpdate = async () => {
        if (!selectedBug || !newStatus) return;

        try {
            await bugReportsCollectionRef.doc(selectedBug.id).update({
                status: newStatus,
                lastUpdated: new Date(),
                updatedBy: {
                    name: currentUser.name,
                    email: currentUser.email,
                },
            });
            
            // Update local state to reflect the change
            const updatedBugs = bugs.map(bug => 
                bug.id === selectedBug.id 
                    ? { 
                        ...bug, 
                        status: newStatus,
                        lastUpdated: new Date(),
                        updatedBy: {
                            name: currentUser.name,
                            email: currentUser.email,
                        }
                    } 
                    : bug
            );
            
            setBugs(updatedBugs);
            setFilteredBugs(prevFiltered => {
                return prevFiltered.map(bug => 
                    bug.id === selectedBug.id 
                        ? { 
                            ...bug, 
                            status: newStatus,
                            lastUpdated: new Date(),
                            updatedBy: {
                                name: currentUser.name,
                                email: currentUser.email,
                            }
                        } 
                        : bug
                );
            });
            
            setShowStatusModal(false);
            setSelectedBug(null);
            setNewStatus("");
        } catch (error) {
            console.error("Error updating status:", error);
        }
    };

    return (
        <SidebarComponent>
            <section className="page-heading">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="mb-0">Bug Resolution</h3>
                    </div>
                </div>
            </section>

            {/* Bugs Assigned to Me */}
            <div className="my-bugs-section p-4">
                <Card>
                    <Card.Header>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5 className="mb-0">Bugs Assigned to Me</h5>
                            <div className="status-filter-group">
                                <Button
                                    variant={
                                        selectedStatus === "all"
                                            ? "primary"
                                            : "outline-primary"
                                    }
                                    size="sm"
                                    onClick={() => setSelectedStatus("all")}
                                    className="me-2"
                                >
                                    All
                                </Button>
                                <Button
                                    variant={
                                        selectedStatus === "assigned"
                                            ? "primary"
                                            : "outline-primary"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedStatus("assigned")
                                    }
                                    className="me-2"
                                >
                                    Assigned
                                </Button>
                                <Button
                                    variant={
                                        selectedStatus === "in_progress"
                                            ? "primary"
                                            : "outline-primary"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedStatus("in_progress")
                                    }
                                    className="me-2"
                                >
                                    In Progress
                                </Button>
                                <Button
                                    variant={
                                        selectedStatus === "resolved"
                                            ? "primary"
                                            : "outline-primary"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedStatus("resolved")
                                    }
                                >
                                    Resolved
                                </Button>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <div className="table-responsive">
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Title</th>
                                        <th>Game/App</th>
                                        <th>Sensitivity</th>
                                        <th>Status</th>
                                        <th>Submitted By</th>
                                        <th>Date</th>
                                        <th>Details</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {myAssignedBugs.length > 0 ? (
                                        myAssignedBugs.map((bug) => (
                                            <tr key={bug.id}>
                                                <td>{bug.title}</td>
                                                <td>{bug.gameName}</td>
                                                <td>
                                                    <Badge
                                                        bg={getSensitivityColor(
                                                            bug.sensitivity
                                                        )}
                                                    >
                                                        {bug.sensitivity}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    <Badge
                                                        bg={getStatusColor(
                                                            bug.status
                                                        )}
                                                    >
                                                        {bug.status}
                                                    </Badge>
                                                </td>
                                                <td>
                                                    {bug.submittedBy.name}
                                                    <br />
                                                    <small className="text-muted">
                                                        {bug.submittedBy.email}
                                                    </small>
                                                </td>
                                                <td>
                                                    {bug.submittedAt?.toDate() 
                                                        ? format(bug.submittedAt.toDate(), "MMM d, yyyy")
                                                        : "No date"}
                                                </td>
                                                <td>
                                                    <BugDetailsModal bug={bug} />
                                                </td>
                                                <td>
                                                    <Button
                                                        variant="outline-primary"
                                                        size="sm"
                                                        onClick={() => {
                                                            setSelectedBug(bug);
                                                            setNewStatus(
                                                                bug.status
                                                            );
                                                            setShowStatusModal(
                                                                true
                                                            );
                                                        }}
                                                    >
                                                        Update Status
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td
                                                colSpan="8"
                                                className="text-center py-4"
                                            >
                                                <div className="no-data-message">
                                                    {bugs.some(
                                                        (bug) =>
                                                            bug.assignedTo
                                                                ?.email ===
                                                            currentUser?.email
                                                    )
                                                        ? `No bugs found with status "${selectedStatus}"`
                                                        : "No bugs assigned to you"}
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </div>
                    </Card.Body>
                </Card>
            </div>

            <div className="bug-resolution-container p-4">
                <Card>
                    <Card.Header>
                        <div>
                            <h5 className="mb-0 py-3">Assign bugs to developers</h5>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {/* Filters Section */}
                        <div className="filters-section mb-4">
                            <Row className="g-3">
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Status</Form.Label>
                                        <Form.Select
                                            value={filters.status}
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "status",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">
                                                All Statuses
                                            </option>
                                            <option value="pending">
                                                Pending
                                            </option>
                                            <option value="assigned">
                                                Assigned
                                            </option>
                                            <option value="in_progress">
                                                In Progress
                                            </option>
                                            <option value="resolved">
                                                Resolved
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Sensitivity</Form.Label>
                                        <Form.Select
                                            value={filters.sensitivity}
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "sensitivity",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">All Levels</option>
                                            <option value="low">Low</option>
                                            <option value="medium">
                                                Medium
                                            </option>
                                            <option value="high">High</option>
                                            <option value="critical">
                                                Critical
                                            </option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Assigned To</Form.Label>
                                        <Form.Select
                                            value={filters.assignedTo}
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "assignedTo",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">All Users</option>
                                            {myelinners.map((user) => (
                                                <option
                                                    key={user.email}
                                                    value={user.email}
                                                >
                                                    {user.name}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Game/App</Form.Label>
                                        <Form.Select
                                            value={filters.gameName}
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "gameName",
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="">
                                                All Games/Apps
                                            </option>
                                            {gameNames.map((game) => (
                                                <option key={game} value={game}>
                                                    {game}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group>
                                        <Form.Label>Date Range</Form.Label>
                                        <Row className="g-2">
                                            <Col>
                                                <Form.Control
                                                    type="date"
                                                    value={
                                                        filters.dateRange.start
                                                    }
                                                    onChange={(e) =>
                                                        handleDateRangeChange(
                                                            "start",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Form.Control
                                                    type="date"
                                                    value={
                                                        filters.dateRange.end
                                                    }
                                                    onChange={(e) =>
                                                        handleDateRangeChange(
                                                            "end",
                                                            e.target.value
                                                        )
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                                <Col md={3}>
                                    <Form.Group>
                                        <Form.Label>Search</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Search bugs..."
                                            value={filters.searchTerm}
                                            onChange={(e) =>
                                                handleFilterChange(
                                                    "searchTerm",
                                                    e.target.value
                                                )
                                            }
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={3} className="d-flex align-items-end">
                                    <Button
                                        variant="outline-secondary"
                                        onClick={resetFilters}
                                        className="w-100"
                                    >
                                        Reset Filters
                                    </Button>
                                </Col>
                            </Row>
                        </div>

                        {/* Results Summary */}
                        <div className="results-summary mb-3">
                            <Badge bg="info">
                                Showing {filteredBugs.length} of {bugs.length}{" "}
                                bugs
                            </Badge>
                        </div>

                        {/* Loading spinner for initial load */}
                        {loading && (
                            <div className="text-center py-4">
                                <Spinner animation="border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </div>
                        )}

                        {/* Bugs Table */}
                        {!loading && (
                            <div className="table-responsive">
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Game/App</th>
                                            <th>Sensitivity</th>
                                            <th>Status</th>
                                            <th>Assigned To</th>
                                            <th>Submitted By</th>
                                            <th>Date</th>
                                            <th>Details</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredBugs.length > 0 ? (
                                            filteredBugs.map((bug) => (
                                                <tr key={bug.id}>
                                                    <td>{bug.title}</td>
                                                    <td>{bug.gameName}</td>
                                                    <td>
                                                        <Badge
                                                            bg={getSensitivityColor(
                                                                bug.sensitivity
                                                            )}
                                                        >
                                                            {bug.sensitivity}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        <Badge
                                                            bg={getStatusColor(
                                                                bug.status
                                                            )}
                                                        >
                                                            {bug.status}
                                                        </Badge>
                                                    </td>
                                                    <td>
                                                        {bug.assignedTo ? (
                                                            <>
                                                                {bug.assignedTo.name}
                                                                <br />
                                                                <small className="text-muted">
                                                                    {
                                                                        bug.assignedTo
                                                                            .email
                                                                    }
                                                                </small>
                                                            </>
                                                        ) : (
                                                            <span className="text-muted">
                                                                Unassigned
                                                            </span>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {bug.submittedBy.name}
                                                        <br />
                                                        <small className="text-muted">
                                                            {bug.submittedBy.email}
                                                        </small>
                                                    </td>
                                                    <td>
                                                        {bug.submittedAt?.toDate() 
                                                            ? format(bug.submittedAt.toDate(), "MMM d, yyyy")
                                                            : "No date"}
                                                    </td>
                                                    <td>
                                                        <BugDetailsModal bug={bug} />
                                                    </td>
                                                    <td>
                                                        <Button
                                                            variant="outline-primary"
                                                            size="sm"
                                                            onClick={() => {
                                                                setSelectedBug(bug);
                                                                setShowAssignModal(
                                                                    true
                                                                );
                                                            }}
                                                        >
                                                            Assign/Update
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="9" className="text-center py-4">
                                                    <div className="no-data-message">
                                                        No bugs found matching the current filters
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>

                                {/* Load More Button */}
                                {hasMore && (
                                    <div className="d-flex justify-content-center mt-3">
                                        <Button 
                                            variant="outline-primary" 
                                            onClick={loadMoreBugs}
                                            disabled={loadingMore}
                                        >
                                            {loadingMore ? (
                                                <>
                                                    <Spinner 
                                                        as="span" 
                                                        animation="border" 
                                                        size="sm" 
                                                        role="status" 
                                                        aria-hidden="true" 
                                                        className="me-2"
                                                    />
                                                    Loading...
                                                </>
                                            ) : (
                                                'Load More'
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </div>
                        )}
                    </Card.Body>
                </Card>

                {/* Assign Bug Modal */}
                <ModalAssignBug
                    show={showAssignModal}
                    onHide={() => setShowAssignModal(false)}
                    bug={selectedBug}
                    myelinners={myelinners}
                />

                {/* Update Status Modal */}
                <Modal
                    show={showStatusModal}
                    onHide={() => setShowStatusModal(false)}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Update Bug Status</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>New Status</Form.Label>
                                <Form.Select
                                    value={newStatus}
                                    onChange={(e) =>
                                        setNewStatus(e.target.value)
                                    }
                                >
                                    <option value="assigned">Assigned</option>
                                    <option value="in_progress">
                                        In Progress
                                    </option>
                                    <option value="resolved">Resolved</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => setShowStatusModal(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={handleStatusUpdate}
                            disabled={!newStatus}
                        >
                            Update Status
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        </SidebarComponent>
    );
};

export default ViewBugResolution;
