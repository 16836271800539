import React, { useState } from 'react';
import { Form, Button, Card } from 'react-bootstrap';
import './ReviewForm.scss';

const ReviewForm = ({ onSubmit, existingReview = null }) => {
  const [formData, setFormData] = useState({
    score: existingReview?.score || '',
    feedback: existingReview?.feedback || ''
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <Card className="review-form">
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-4">
            <Form.Label>Score (out of 20)</Form.Label>
            <Form.Control
              type="number"
              min="0"
              max="20"
              step="0.5"
              value={formData.score}
              onChange={(e) => setFormData({ ...formData, score: e.target.value })}
              required
            />
          </Form.Group>

          <Form.Group className="mb-4">
            <Form.Label>Feedback</Form.Label>
            <Form.Control
              as="textarea"
              rows={6}
              value={formData.feedback}
              onChange={(e) => setFormData({ ...formData, feedback: e.target.value })}
              placeholder="Provide detailed feedback on the report..."
              required
            />
          </Form.Group>

          <Button variant="primary" type="submit" className="submit-button">
            Submit Review
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default ReviewForm; 