import React from 'react';
import { Card, ProgressBar } from 'react-bootstrap';
import { format } from 'date-fns';
import './MonthlyScoreCard.scss';

const MonthlyScoreCard = ({ monthlyData }) => {
  const { month, year, averageScore, weeklyScores } = monthlyData;

  const getMonthName = (monthNumber) => {
    const months = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return months[monthNumber - 1];
  };

  const getScoreColor = (score) => {
    if (score >= 16) return 'success';
    if (score >= 12) return 'info';
    if (score >= 8) return 'warning';
    return 'danger';
  };

  return (
    <Card className="monthly-score-card">
      <Card.Header>
        <h3>{getMonthName(month)} {year}</h3>
      </Card.Header>
      <Card.Body>
        <div className="score-summary">
          <div className="average-score">
            <h4>Monthly Average</h4>
            <div className="score-display">
              <span className="score">{averageScore.toFixed(1)}</span>
              <span className="total">/20</span>
            </div>
            <ProgressBar
              now={(averageScore / 20) * 100}
              variant={getScoreColor(averageScore)}
              className="score-progress"
            />
          </div>
        </div>

        <div className="weekly-breakdown">
          <h4>Weekly Breakdown</h4>
          {weeklyScores.map((week, index) => (
            <div key={index} className="week-score">
              <div className="week-label">
                Week of {format(week.weekStartDate.toDate(), 'MMM d, yyyy')}
              </div>
              <div className="score-value">
                {week.score.toFixed(1)}/20
              </div>
              <ProgressBar
                now={(week.score / 20) * 100}
                variant={getScoreColor(week.score)}
                className="score-progress"
              />
            </div>
          ))}
          {weeklyScores.length === 0 && (
            <div className="no-scores">
              No weekly scores available for this month
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default MonthlyScoreCard; 