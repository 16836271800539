import React, { useState } from 'react';
import { Modal, Button, Badge, Image } from 'react-bootstrap';
import { format } from 'date-fns';
import ModalTxTFilePreview from './Components/ModalTxTFilePreview';
import './BugDetailsModal.scss';

const BugDetailsModal = ({ bug }) => {
    // Add default values at the component level
    const {
        submittedBy = { name: 'Unknown User', email: 'N/A' },
        submittedAt = null,
        title = 'Untitled',
        description = 'No description provided',
        sensitivity = 'N/A',
        status = 'pending',
        stepsToReproduce = [],
        expectedBehavior = 'Not specified',
        actualBehavior = 'Not specified',
        images = []
    } = bug || {};

    const [show, setShow] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleImageClick = (file) => {
        if (!file.type || file.type.includes('image')) {
            setSelectedImageIndex(file);
        }
    };

    const handleImageModalClose = () => {
        setSelectedImageIndex(null);
    };

    const getSensitivityColor = (sensitivity) => {
        switch (sensitivity) {
            case 'low':
                return 'success';
            case 'medium':
                return 'warning';
            case 'high':
                return 'orange';
            case 'critical':
                return 'danger';
            default:
                return 'secondary';
        }
    };

    // Helper function to check if file is an image by extension
    const isImageByExtension = (filename) => {
        const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.bmp'];
        return imageExtensions.some(ext => 
            filename.toLowerCase().endsWith(ext)
        );
    };

    // Updated getFileComponent function
    const getFileComponent = (file) => {
        // If it's a text file
        if (file.type === 'text/plain') {
            return <ModalTxTFilePreview file={file} />;
        }
        
        // If it's explicitly an image type or has an image extension
        if (
            (file.type && file.type.includes('image')) || 
            (file.originalName && isImageByExtension(file.originalName))
        ) {
            return (
                <div className="image-item" onClick={() => handleImageClick(file)}>
                    <Image src={file.url} thumbnail fluid />
                </div>
            );
        }
        
        // For all other cases (no type or non-image files)
        return (
            <a 
                href={file.url} 
                target="_blank" 
                rel="noopener noreferrer" 
                className="btn btn-outline-primary"
                download={file.originalName}
            >
                <i className="fas fa-external-link-alt me-2"></i>
                {file.originalName || 'Open File'}
            </a>
        );
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return 'N/A';
        try {
            // For Firebase Timestamp
            const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
            return format(date, "MMM d, yyyy 'at' h:mm a");
        } catch (error) {
            console.error('Date formatting error:', error);
            return 'N/A';
        }
    };

    // Helper function to render multiline text
    const renderMultilineText = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };

    return (
        <>
            <Button variant="outline-primary" size="sm" onClick={handleShow}>
                View Details
            </Button>

            <Modal className="bg-light" show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="w-100">
                        <div className="d-flex justify-content-between align-items-center">
                            <span>{title}</span>
                            <div>
                                <Badge bg={getSensitivityColor(sensitivity)} className="me-2">
                                    {sensitivity}
                                </Badge>
                                <Badge bg={status === 'pending' ? 'warning' : status === 'in_progress' ? 'primary' : 'success'}>
                                    {status.replace('_', ' ')}
                                </Badge>
                            </div>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="bug-details-container">
                        {/* Meta Information */}
                        <div className="meta-section mb-4">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="meta-item">
                                        <strong>Game/App:</strong> {bug.gameName}
                                    </div>
                                    <div className="meta-item">
                                        <strong>Submitted By:</strong> {submittedBy.name}
                                        <div className="text-muted small">{submittedBy.email}</div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="meta-item">
                                        <strong>Submitted On:</strong> {formatDate(submittedAt)}
                                    </div>
                                    {bug.updatedBy && (
                                        <div className="meta-item">
                                            <strong>Last Updated By:</strong> {bug.updatedBy.name}
                                            <div className="text-muted small">
                                                {formatDate(bug.lastUpdated)}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {/* Description */}
                        <div className="content-section mb-4">
                            <h6 className="section-title">Description</h6>
                            <div className="content-box multiline-text">
                                {renderMultilineText(description)}
                            </div>
                        </div>

                        {/* Steps to Reproduce */}
                        <div className="content-section mb-4">
                            <h6 className="section-title">Steps to Reproduce</h6>
                            <div className="content-box">
                                {Array.isArray(stepsToReproduce) && stepsToReproduce.length > 0 ? (
                                    <ol className="steps-list">
                                        {stepsToReproduce.map((step, index) => (
                                            <li key={index}>{renderMultilineText(step)}</li>
                                        ))}
                                    </ol>
                                ) : (
                                    <p className="text-muted">No steps provided</p>
                                )}
                            </div>
                        </div>

                        {/* Expected vs Actual Behavior */}
                        <div className="row mb-4">
                            <div className="col-md-12">
                                <div className="content-section">
                                    <h6 className="section-title">Expected Behavior</h6>
                                    <div className="content-box multiline-text">
                                        {renderMultilineText(expectedBehavior)}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="content-section">
                                    <h6 className="section-title">Actual Behavior</h6>
                                    <div className="content-box multiline-text">
                                        {renderMultilineText(actualBehavior)}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Attachments */}
                        {images?.length > 0 && (
                            <div className="content-section">
                                <h6 className="section-title">Attachments ({images.length})</h6>
                                <div className="files-grid">
                                    {images.map((file, index) => (
                                        <div key={index} className="file-preview-item">
                                            <div className="file-name mb-2">
                                                {file.originalName}
                                            </div>
                                            <div className="file-content">
                                                {getFileComponent(file)}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            {/* Image Preview Modal */}
            <Modal 
                show={selectedImageIndex !== null} 
                onHide={handleImageModalClose}
                size="lg"
                centered
                className="image-preview-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {selectedImageIndex?.originalName || 'Image Preview'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedImageIndex && (
                        <div className="full-image-container">
                            <Image 
                                src={selectedImageIndex.url} 
                                fluid 
                            />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleImageModalClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default BugDetailsModal;