import { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import fireApp, { myelinersCollectionRef } from "../../firebase/fire-app";
import { useAuth } from "../../Hooks/useAuth";
import InputWrapper from "../../Components/FormComponents/InputWrapper";

const MyelinerRegistrationPopup = () => {
    const [showModal, setShowModal] = useState(false);

    const { setCurrentUser } = useAuth();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const [processLoading, setProcessLoading] = useState(null);

    const onHideModal = () => {
        setShowModal(!showModal);
    };

    const onFormSubmit = (data, e) => {
        setProcessLoading(true);
        const userPass = data.password;
        const confirmPass = data.confirmPass;

        const email = data.email;

        if (userPass !== confirmPass) {
            alert("Password does not match 😪");
            setProcessLoading(false);
            return;
        } else {
            myelinersCollectionRef
                .doc(email)
                .get()
                .then((doc) => {
                    if (!doc.exists) {
                        alert(
                            "Your admin has not listed your email yet! Ask your admin and register again!"
                        );
                        setProcessLoading(false);
                    } else {
                        fireApp
                            .auth()
                            .createUserWithEmailAndPassword(email, userPass)
                            .then((userCredential) => {
                                const user = userCredential.user;
                                myelinersCollectionRef
                                    .doc(email)
                                    .get()
                                    .then((doc) => {
                                        setCurrentUser(doc.data());
                                        setProcessLoading(false);
                                        onHideModal();
                                    });
                                setProcessLoading(false);
                            })
                            .catch((error) => {
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                alert(errorMessage);
                                setProcessLoading(false);
                            });
                    }
                });
        }

        // fireApp
        //     .auth()
        //     .createUserWithEmailAndPassword(email, userPass)
        //     .then((userCredential) => {
        //         alert(JSON.stringify(userCredential.user));
        //         const user = userCredential.user;
        //         const userData = { uid: user.uid, ...data };
        //     })
        //     .catch((error) => {
        //         const errorCode = error.code;
        //         const errorMessage = error.message;
        //         alert(errorMessage);
        //     });
    };

    return (
        <>
            <div className="d-flex align-items-center">
                <p className="mb-0 me-4">
                    New to MyelinZ People, Performance & Culture Portal?{" "}
                </p>
                <button
                    onClick={() => setShowModal(true)}
                    className="btn btn-sm btn-primary"
                >
                    Register now!
                </button>
            </div>
            <Modal
                show={showModal}
                onHide={onHideModal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-little-vcenter">
                        Register now!
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row d-flex justify-content-center">
                        <div className="col-md-10">
                            <form onSubmit={handleSubmit(onFormSubmit)}>
                                <div className="mb-3">
                                    {/* <div className="row">
                                        <InputWrapper col={7} label="Name">
                                            <input
                                                type="text"
                                                {...register("name", {
                                                    required: true,
                                                })}
                                                className="form-control"
                                                name="name"
                                                id="name"
                                            />
                                        </InputWrapper>
                                        <InputWrapper col={7} label="Name">
                                            <input
                                                type="text"
                                                {...register("name", {
                                                    required: true,
                                                })}
                                                className="form-control"
                                                name="name"
                                                id="name"
                                            />
                                        </InputWrapper>
                                    </div> */}

                                    <InputWrapperComponent
                                        required
                                        htmlFor={"email"}
                                        label="Email"
                                    >
                                        <input
                                            type="email"
                                            {...register("email", {
                                                required: true,
                                            })}
                                            className="form-control"
                                            name="email"
                                            id="email"
                                        />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent
                                        required
                                        htmlFor={"password"}
                                        label="Password"
                                    >
                                        <input
                                            {...register("password", {
                                                required: true,
                                            })}
                                            type="password"
                                            className="form-control"
                                            name="password"
                                            id="password"
                                        />
                                    </InputWrapperComponent>
                                    <InputWrapperComponent
                                        required
                                        htmlFor={"confirmPass"}
                                        label="Confirm Password"
                                    >
                                        <input
                                            {...register("confirmPass", {
                                                required: true,
                                            })}
                                            type="password"
                                            className="form-control"
                                            name="confirmPass"
                                            id="confirmPass"
                                        />
                                    </InputWrapperComponent>

                                    <div className="col-12 text-center">
                                        {processLoading ? (
                                            <button
                                                disabled
                                                className="btn btn-primary btn-sm"
                                            >
                                                Register
                                            </button>
                                        ) : (
                                            <button className="btn btn-primary btn-sm">
                                                Register
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHideModal}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MyelinerRegistrationPopup;

const InputWrapperComponent = ({ children, label, htmlFor, required }) => {
    return (
        <div className="mb-3 row">
            <label htmlFor={htmlFor} className="col-sm-3 col-form-label">
                {label}{" "}
                {required && <span className="text-danger font-bold">*</span>}
            </label>
            <div className="col-sm-9">{children}</div>
        </div>
    );
};
