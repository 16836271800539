import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { fireStorage } from '../../../firebase/fire-app';

const ModalTxTFilePreview = ({ file }) => {
    const [show, setShow] = useState(false);

    return (
        <>
            <div className="d-flex flex-column gap-2">
                <Button variant="primary" onClick={() => setShow(true)}>
                    View Text File
                </Button>
                <a 
                    href={file.url} 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="btn btn-outline-secondary btn-sm"
                    download={file.originalName}
                >
                    Download File
                </a>
            </div>

            <Modal 
                show={show} 
                onHide={() => setShow(false)} 
                size="xl" 
                fullscreen="lg-down"
                dialogClassName="txt-preview-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{file.originalName}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0">
                    <div className="iframe-container">
                        <iframe
                            src={file.url}
                            title={file.originalName}
                            width="100%"
                            height="600px"
                            className="txt-preview-iframe"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        Close
                    </Button>
                    <a 
                        href={file.url} 
                        target="_blank" 
                        rel="noopener noreferrer" 
                        className="btn btn-primary"
                        download={file.originalName}
                    >
                        Download File
                    </a>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalTxTFilePreview;