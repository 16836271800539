import React, { useState } from "react";
import { Modal, Button, Form, Image, Badge, Accordion } from "react-bootstrap";
import gameNames from "./data-game-names";
import "./ViewGameReviewModal.scss";
import ModalTxTFilePreview from "./Components/ModalTxTFilePreview";
import BugDetailsModal from "./BugDetailsModal";
import BugReportSummaryTable from "./Components/BugReportSummaryTable";

const ViewGameReviewModal = ({ show, onHide, report }) => {
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const getSensitivityColor = (sensitivity) => {
        switch (sensitivity) {
            case "low":
                return "success";
            case "medium":
                return "warning";
            case "high":
                return "orange";
            case "critical":
                return "danger";
            default:
                return "secondary";
        }
    };

    const handleImageClick = (index) => {
        setSelectedImageIndex(index);
    };

    const handleImageModalClose = () => {
        setSelectedImageIndex(null);
    };

    const getFileComponent = (file) => {
        if (file.type === "text/plain") {
            return <ModalTxTFilePreview file={file} />;
        }

        if (!file.type || file.type.includes("image")) {
            return (
                <div
                    className="image-item"
                    onClick={() => handleImageClick(file)}
                >
                    <Image src={file.url} thumbnail fluid />
                </div>
            );
        }

        return (
            <a
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-outline-primary"
            >
                Open {file.originalName}
            </a>
        );
    };

    const formatDate = (timestamp) => {
        if (!timestamp) return "N/A";
        try {
            return timestamp.toDate().toLocaleDateString();
        } catch (error) {
            return "Invalid Date";
        }
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Game/App Review Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {report.bugReports?.length > 0 && (
                    <BugReportSummaryTable report={report} />
                )}
               

                <div className="review-details">
                    <div className="meta-info mb-4">
                        <div className="row">
                            <div className="col-md-6">
                                <p>
                                    <strong>Week Range:</strong>{" "}
                                    {report.weekRange}
                                </p>
                                <p>
                                    <strong>Selected Games/Apps:</strong>
                                </p>
                                <ul className="list-unstyled">
                                    {report.selectedGames?.map(
                                        (gameName, index) => (
                                            <li key={index}>
                                                {gameName}
                                                <Badge
                                                    bg="info"
                                                    className="ms-2"
                                                >
                                                    {gameNames.find(
                                                        (g) =>
                                                            g.name === gameName
                                                    )?.type === "app"
                                                        ? "App"
                                                        : "Game"}
                                                </Badge>
                                            </li>
                                        )
                                    )}
                                </ul>
                                <p>
                                    <strong>Created At:</strong>{" "}
                                    {report.createdAt
                                        ?.toDate()
                                        .toLocaleDateString()}
                                </p>
                            </div>
                            <div className="col-md-6">
                                <p>
                                    <strong>Status:</strong> {report.status}
                                </p>
                                <p>
                                    <strong>Last Updated:</strong>{" "}
                                    {report.lastUpdated
                                        ?.toDate()
                                        .toLocaleDateString()}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="feedback-sections">
                        <div className="feedback-section mb-4">
                            <h6>Feedback on Game Tutorial</h6>
                            <div className="feedback-content">
                                {report.tutorialFeedback ||
                                    "No feedback provided"}
                            </div>
                        </div>

                        <div className="feedback-section mb-4">
                            <h6>Feedback on Game Levels & Difficulty</h6>
                            <div className="feedback-content">
                                {report.levelsFeedback ||
                                    "No feedback provided"}
                            </div>
                        </div>

                        <div className="feedback-section mb-4">
                            <h6>Positive Aspects</h6>
                            <div className="feedback-content">
                                {report.positiveAspects ||
                                    "No feedback provided"}
                            </div>
                        </div>

                        <div className="feedback-section mb-4">
                            <h6>Suggestions for Improvement</h6>
                            <div className="feedback-content">
                                {report.improvementSuggestions ||
                                    "No feedback provided"}
                            </div>
                        </div>

                        <div className="feedback-section mb-4">
                            <h6>Overall Experience</h6>
                            <div className="feedback-content">
                                {report.overallExperience ||
                                    "No feedback provided"}
                            </div>
                        </div>
                    </div>

                    {/* Report Files Section */}
                    {report.images?.length > 0 && (
                        <div className="files-section mb-4">
                            <h6>Supporting Files</h6>
                            <div className="files-grid">
                                {report.images.map((file, index) => (
                                    <div
                                        key={index}
                                        className="file-preview-item"
                                    >
                                        <div className="file-name mb-2">
                                            {file.originalName}
                                        </div>
                                        <div className="file-content">
                                            {getFileComponent(file)}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>

            {/* Image Preview Modal */}
            <Modal
                show={selectedImageIndex !== null}
                onHide={() => setSelectedImageIndex(null)}
                size="lg"
                centered
                className="image-preview-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>File Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedImageIndex && (
                        <div className="full-image-container">
                            <Image src={selectedImageIndex.url} fluid />
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setSelectedImageIndex(null)}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </Modal>
    );
};

export default ViewGameReviewModal;
