import React, { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { customDateFormat, getVacationDays } from "../../utilities";
import {
    leavesCollectionRef,
    myelinersCollectionRef,
} from "../../firebase/fire-app";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { StatusBadge } from "../ViewClaimManagement/FormElements";
import emailjs from "@emailjs/browser";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import LeaveBalanceManagement from "./LeaveBalanceManagement";
import firebase from "firebase/app";
import { webServerLive } from "../../firebase/liveServerStatus";

const AdminLeaveApplications = () => {
    const [status, setStatus] = useState("Pending");
    const [leaveQuery, setLeaveQuery] = useState(
        leavesCollectionRef.where("status", "==", status)
    );
    const [myLeaves, loading, error] = useCollectionData(leaveQuery, {
        idField: "id",
    });
    const [selectedEmpID, setSelectedEmpID] = React.useState("all");
    const [myeliners, loadingmyeliner, errorMy] = useCollectionData(
        myelinersCollectionRef.orderBy("empID", "desc"),
        { idField: "id" }
    );
    const [empArray, setEmpArray] = useState(false);
    useEffect(() => {
        if (selectedEmpID === "all") {
            const queryText = leavesCollectionRef.where("status", "==", status);
            setLeaveQuery(queryText);
        } else {
            const queryText = leavesCollectionRef
                .where("status", "==", status)
                .where("empEmail", "==", selectedEmpID);
            setLeaveQuery(queryText);
        }
    }, [selectedEmpID, status]);
    return (
        <>
            <SidebarComponent>
                <section className="container d-md-flex d-block justify-content-between">
                    <div>
                        <h3 className="mt-3">Leave Applications </h3>
                    </div>
                    <ButtonGroup
                        size="sm"
                        className="mt-4"
                        aria-label="Basic example"
                    >
                        <Button
                            onClick={() => setStatus("Pending")}
                            size="sm"
                            variant={`${
                                status === "Pending" ? "warning" : "light"
                            }`}
                        >
                            Pending
                        </Button>
                        <Button
                            onClick={() => setStatus("Approved")}
                            size="sm"
                            variant={`${
                                status === "Approved" ? "primary" : "light"
                            }`}
                        >
                            Approved
                        </Button>
                        <Button
                            onClick={() => setStatus("Rejected")}
                            size="sm"
                            variant={`${
                                status === "Rejected" ? "danger" : "light"
                            }`}
                        >
                            Rejected
                        </Button>
                    </ButtonGroup>
                </section>
                <hr />
                <section className="">
                    <div className="container ">
                        <div className="d-md-flex d-block justify-content-between">
                            <LeaveBalanceManagement />
                            <form className="row mt-4 mt-md-0">
                                <InputWrapper col="12" label="Employee ID">
                                    <select
                                        onChange={(e) =>
                                            setSelectedEmpID(e.target.value)
                                        }
                                        id="disabledSelect"
                                        className="form-select"
                                    >
                                        <option>Select Employee</option>
                                        <option value={"all"}>All</option>
                                        {myeliners &&
                                            myeliners.length > 0 &&
                                            myeliners.map((item) => (
                                                <option value={item.id}>
                                                    {item.id}
                                                </option>
                                            ))}
                                    </select>
                                </InputWrapper>
                            </form>
                        </div>

                        <div className="table-responsive">
                            {myLeaves && myLeaves.length > 0 && (
                                <table className="table mt-4  pr-5 table-bordered border-primary table-hover table-striped">
                                    <thead>
                                        <tr>
                                            <th>Sl.</th>
                                            <th>EmpID</th>
                                            <th>Leave Type</th>
                                            <th>Start</th>
                                            <th>End</th>
                                            <th>Days</th>
                                            <th>Leave Taken</th>
                                            <th>Leave Balance</th>
                                            <th>Remarks</th>
                                            <th>Status</th>
                                            <th>Reject</th>
                                            <th>Approve</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {myLeaves &&
                                            myLeaves.map((claim, index) => (
                                                <LeaveRow
                                                    selectedEmpID={
                                                        selectedEmpID
                                                    }
                                                    claim={claim}
                                                    index={index}
                                                />
                                            ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default AdminLeaveApplications;

const LeaveRow = ({ claim, index, selectedEmpID }) => {
    const [empArray, setEmpArray] = useState(selectedEmpID);
    const [showRow, setShowRow] = useState(true);
    useEffect(() => {
        if (selectedEmpID !== "all" && claim.empEmail !== selectedEmpID) {
            setShowRow(false);
        }
    }, [selectedEmpID]);

    const onApproveApplication = () => {
        if (claim.leaveDays) {
            leavesCollectionRef
                .doc(claim.id)
                .update({ status: "Approved" })
                .then(() => {
                    if (claim.leaveDays) {
                        myelinersCollectionRef.doc(claim.empEmail).update({
                            leaveBalance:
                                firebase.firestore.FieldValue.increment(
                                    -1 * claim.leaveDays
                                ),
                            leaveTaken: firebase.firestore.FieldValue.increment(
                                claim.leaveDays
                            ),
                        });
                    }
                    const mailData = {
                        mailSubject: "Leave Application Approved",
                        message: "Your leave application has been approved.",
                        empID: claim.empID,
                        name: claim.name,
                        type: claim.leaveType,
                        remarks: claim.leaveRemarks,
                        duration:
                            customDateFormat(claim.startDate) +
                            " to " +
                            customDateFormat(claim.endDate),
                        status: "Approved",
                        mailOne: claim.empEmail,
                        mailTwo: "zied.tayeb@myelinh.com",
                        from_name: "MyelinZ HR",
                    };
                    if (webServerLive) {
                        emailjs.send(
                            "service_zinxo6v",
                            "template_vhzaawx",
                            mailData,
                            "ygk5Yo8QhiJUWbVZM"
                        );
                    }

                    alert("Leave Approved");
                });
        }
    };
    const onRejectApplication = () => {
        leavesCollectionRef
            .doc(claim.id)
            .update({ status: "Rejected" })
            .then(() => {
                alert("Leave Rejected");
                const mailData = {
                    mailSubject: "Leave Application Rejected",
                    message: "Your leave application has been rejected.",
                    empID: claim.empID,
                    name: claim.name,
                    type: claim.leaveType,
                    duration:
                        customDateFormat(claim.startDate) +
                        " to " +
                        customDateFormat(claim.endDate),
                    remarks: claim.leaveRemarks,
                    status: "Rejected",
                    mailSubject: "Leave Application Approved",
                    message: "Your leave application has been approved.",
                };
                if (webServerLive) {
                    emailjs.send(
                        "service_zinxo6v",
                        "template_vhzaawx",
                        mailData,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                }
            });
    };
    const logInfo = () => {
        const startDate = claim.startDate.toDate();
        const endDate = claim.endDate.toDate();
        const vacationDays = getVacationDays(startDate, endDate);
        leavesCollectionRef.doc(claim.id).update({
            leaveDays: vacationDays,
        });
    };
    return (
        <>
            {showRow && (
                <tr>
                    <td>{index + 1}</td>
                    <td>{claim.empID}</td>
                    <td>{claim.leaveType}</td>
                    <td>{customDateFormat(claim.startDate)}</td>
                    <td>{customDateFormat(claim.endDate)}</td>
                    <td> {claim.leaveDays}</td>
                    <td>{claim.preLeaveTaken}</td>
                    <td>{claim.preLeaveBalance}</td>
                    <td>{claim.leaveRemarks} </td>
                    <td>
                        <StatusBadge status={claim.status} />
                    </td>
                    <td>
                        <button
                            onClick={onRejectApplication}
                            className="btn btn-outline-danger btn-sm"
                        >
                            REJECT
                        </button>
                    </td>
                    <td>
                        <button
                            onClick={onApproveApplication}
                            className="btn btn-outline-primary btn-sm"
                        >
                            APPROVE
                        </button>
                    </td>
                </tr>
            )}
        </>
    );
};

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div className={"col-md-" + col + " " + addClass}>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">{label} </span>
                </div>
                {children}
            </div>
        </div>
    );
};
