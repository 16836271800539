const gameNames = [
    {
        id: 'myelinface',
        name: 'MyelinFace',
        slug: 'myelinface',
        type: 'app'
    },
    {
        id: 1,
        name: "Athena",
        slug: "athena",
        image: ""
    },
    {
        id: 2,
        name: "Sophia",
        slug: "sophia",
        image: ""
    },
    // similarly complete the list with - Clionia, Nemomica,Sophonia, Dromia, Aeolia, Artemisia, Kratoria, Harmonia, Physia, Orphia, Eduaimonia, Phantasia, Graphia, Lexia

    {
        id: 3,
        name: "Clionia",
        slug: "clionia",
        image: ""
    },
    {
        id: 4,
        name: "Nemomica",
        slug: "nemomica",
        image: ""
    },
    {
        id: 5,
        name: "Sophonia",
        slug: "sophonia",
        image: ""
    },
    {
        id: 6,
        name: "Dromia",
        slug: "dromia",
        image: ""
    },
    {
        id: 7,
        name: "Aeolia",
        slug: "aeolia",
        image: ""
    },
    {
        id: 8,
        name: "Artemisia",
        slug: "artemisia",
        image: ""
    },
    {
        id: 9,
        name: "Kratoria",
        slug: "kratoria",
        image: ""
    },
    {
        id: 10,
        name: "Harmonia",
        slug: "harmonia",
        image: ""
    },
    {
        id: 11,
        name: "Physia",
        slug: "physia",
        image: ""
    },
    {
        id: 12,
        name: "Orphia",
        slug: "orphia",
        image: ""
    },
    {
        id: 13,
        name: "Eduaimonia",
        slug: "eduaimonia",
        image: ""
    },
    {
        id: 14,
        name: "Phantasia",
        slug: "phantasia",
        image: ""
    },
    {
        id: 15,
        name: "Graphia",
        slug: "graphia",
        image: ""
    },
    {
        id: 16,
        name: "Lexia",
        slug: "lexia",
        image: ""
    }
];

export default gameNames;