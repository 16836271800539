import firebase from "firebase";
import "firebase/auth";
import firebaseConfig from "../firebase-config";
import { webServerLive } from "./liveServerStatus";

// fbConfig = {
//     apiKey: "AIzaSyAnY7FO7_M5uEuak_L6oEU7TRZiwHFcIQc",
//     authDomain: "ec-myelinh.firebaseapp.com",
//     projectId: "ec-myelinh",
//     storageBucket: "ec-myelinh.appspot.com",
//     messagingSenderId: "310651316230",
//     appId: "1:310651316230:web:d67da11f86d7ad9335bb87",
//     measurementId: "G-9GJMP15Q9S",
// };


let fbConfig;
if (webServerLive) {
    fbConfig = {
        apiKey: "AIzaSyAnY7FO7_M5uEuak_L6oEU7TRZiwHFcIQc",
        authDomain: "ec-myelinh.firebaseapp.com",
        projectId: "ec-myelinh",
        storageBucket: "ec-myelinh.appspot.com",
        messagingSenderId: "310651316230",
        appId: "1:310651316230:web:d67da11f86d7ad9335bb87",
        measurementId: "G-9GJMP15Q9S"
    };
} else {
    fbConfig = {
        apiKey: "AIzaSyCfIEFfIJ7_pzKFxIfClu50HUPZ-_eOd-A",
        authDomain: "test-ec-myelinh.firebaseapp.com",
        projectId: "test-ec-myelinh",
        storageBucket: "test-ec-myelinh.appspot.com",
        messagingSenderId: "993379322956",
        appId: "1:993379322956:web:cc9062469cdc87060eb8e9",
    };
}

const fireApp = firebase.initializeApp(fbConfig);
fireApp
    .firestore()
    .enablePersistence({ synchronizeTabs: true })
    .catch((err) => {
        if (err.code === "failed-precondition") {
            console.log(err);
        } else console.log(err);
    });

export default fireApp;
export const fireStoreDB = fireApp.firestore();
export const fireDatabase = fireApp.database();
export const fireStorage = fireApp.storage();

export const authUsersCollection = fireStoreDB.collection("authUsers");

export const myelinersCollectionRef = fireStoreDB.collection("myeliners");
export const compensationCollectionRef =
    fireStoreDB.collection("compensationInfo");
export const employmentInfoCollectionRef =
    fireStoreDB.collection("employmentInfo");   
export const claimsCollectionRef = fireStoreDB.collection("claims");
export const leavesCollectionRef = fireStoreDB.collection("leaves");
export const payslipsCollectionRef = fireStoreDB.collection("payslips");
export const feedbackCollectionRef = fireStoreDB.collection("feedback");
// export const documentsCollectionRef = fireStoreDB.collection("documents");
export const wfhApplicationCollectionRef = fireStoreDB.collection("wfh");

export const bankAccountCollectionRef = fireStoreDB.collection("bankInfo");
// export const customMailCollectionRef = fireStoreDB.collection("customMail");
export const archivedUsersCollectionRef =
    fireStoreDB.collection("archivedUsers");

// export const dataControlCollectionRef = fireStoreDB.collection("dataControl");

export const meetingNotesCollectionRef = fireStoreDB.collection("meetingNotes");


export const additionalMyelinnerDetailsCollectionRef = fireStoreDB.collection('additionalDetails');

export const advisorsCollectionRef = fireStoreDB.collection("myelinAdvisors")

export const assignedTasksCollectionRef = fireStoreDB.collection("assignedTasks");

export const portalFeedbacksCollectionRef = fireStoreDB.collection("portalFeedbacks");

export const taskCommentsCollectionRef = fireStoreDB.collection("otherDBs").doc("taskComments").collection("taskComments")
export const taskAttachmentsCollectionRef = fireStoreDB.collection("otherDBs").doc("taskAttachments").collection("taskAttachments")


export const blogsCollectionRef = fireStoreDB.collection("blogs");
export const jobPostsCollectionRef = fireStoreDB.collection("recruitmentSystem").doc("jobPosts").collection("JobPosts");

export const webConfigDocRef = fireStoreDB.collection("otherDBs").doc("webGalleryConfig");
export const ppcVersionDocRef = fireStoreDB.collection("otherDBs").doc("ppcVersionControl");

export const webGalleryCollectionRef = fireStoreDB.collection("otherDBs").doc("webGalleryConfig").collection("webGalleryImages");
export const documentsManagerCollectionRef = fireStoreDB.collection("otherDBs").doc("documentsManager").collection("documentsManager")
export const documentsManagerDocRef = fireStoreDB.collection("otherDBs").doc("documentsManager")
export const recruitmentCollectionRef = fireStoreDB.collection("otherDBs").doc("recruitments").collection("recruitments");

export const progressReportsCollectionRef = fireStoreDB.collection("progressReports");
export const monthlyScoresCollectionRef = fireStoreDB.collection("monthlyScores");
export const notificationsCollectionRef = fireStoreDB.collection("notifications");

export const gameTestingCollectionRef = fireStoreDB.collection("gameTesting");
export const weeklyGameTestingCollectionRef = fireStoreDB.collection("gameTesting").doc("weeklyGameTesting").collection("weeklyGameTesting");
export const bugReportsCollectionRef = fireStoreDB.collection("gameTesting").doc("bugReports").collection("bugReports");

// New collections for testing workflow
export const testingReportsCollectionRef = fireStoreDB.collection("gameTesting").doc("testingReports").collection("testingReports");
export const testingStatusCollectionRef = fireStoreDB.collection("gameTesting").doc("testingStatus").collection("testingStatus");