import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import {
    assignedTasksCollectionRef,
    myelinersCollectionRef,
} from "../../firebase/fire-app";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import CompTaskCard from "./CompTaskCard";
import OverlayAssignTask from "./OverlayAssignTask";
import { Spinner, Alert, Badge } from "react-bootstrap";

const AdminAssignTask = () => {
    const [selectedEmpID, setSelectedEmpID] = useState("");
    const [liveStatus, setLiveStatus] = useState("active");
    const [selectedEmpDetails, setSelectedEmpDetails] = useState(null);
    const [taskStatus, setTaskStatus] = useState("New task");
    
    // Task data state
    const [tasksList, setTasksList] = useState([]);
    const [myeliners, setMyeliners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingMyeliners, setLoadingMyeliners] = useState(true);
    const [error, setError] = useState(null);
    
    // Pagination state
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [itemsPerPage] = useState(15);
    
    // Task counts for display
    const [taskCounts, setTaskCounts] = useState({
        new: 0,
        inProgress: 0,
        completed: 0
    });

    // Fetch employees on initial load
    useEffect(() => {
        fetchMyeliners();
    }, []);
    
    // Fetch tasks based on selected filters
    useEffect(() => {
        setLastVisible(null);
        fetchTasks();
    }, [liveStatus, selectedEmpID]);
    
    // Update selected employee details
    useEffect(() => {
        if (selectedEmpID && myeliners.length > 0 && selectedEmpID !== "all") {
            const employeeDetails = myeliners.find(item => item.id === selectedEmpID);
            setSelectedEmpDetails(employeeDetails);
        } else {
            setSelectedEmpDetails(null);
        }
    }, [selectedEmpID, myeliners]);
    
    // Update task counts whenever list changes
    useEffect(() => {
        if (tasksList) {
            setTaskCounts({
                new: tasksList.filter(item => item.status === "New task").length,
                inProgress: tasksList.filter(item => item.status === "In progress").length,
                completed: tasksList.filter(item => item.status === "Completed").length
            });
        }
    }, [tasksList]);

    // Fetch employees data
    const fetchMyeliners = async () => {
        try {
            setLoadingMyeliners(true);
            const snapshot = await myelinersCollectionRef.orderBy("empID", "desc").get();
            
            const myelinersData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            setMyeliners(myelinersData);
        } catch (err) {
            console.error("Error fetching employees:", err);
            setError("Failed to load employees data");
        } finally {
            setLoadingMyeliners(false);
        }
    };

    // Fetch tasks data with pagination
    const fetchTasks = async (loadMore = false) => {
        try {
            if (loadMore) {
                setLoadingMore(true);
            } else {
                setLoading(true);
                setError(null);
            }

            // Base query with liveStatus filter
            let query = assignedTasksCollectionRef
                .orderBy("createdAt", "desc")
                .where("liveStatus", "==", liveStatus);
            
            // Add pagination
            if (loadMore && lastVisible) {
                query = query.startAfter(lastVisible);
            }
            
            // Limit the number of documents fetched
            query = query.limit(itemsPerPage);
            
            const snapshot = await query.get();
            
            if (snapshot.empty && !loadMore) {
                setTasksList([]);
                setHasMore(false);
                setLoading(false);
                setLoadingMore(false);
                return;
            }

            if (snapshot.empty && loadMore) {
                setHasMore(false);
                setLoadingMore(false);
                return;
            }

            // Get the last document for pagination
            const lastDoc = snapshot.docs[snapshot.docs.length - 1];
            setLastVisible(lastDoc);
            
            // Check if there are more documents
            const moreQuery = assignedTasksCollectionRef
                .orderBy("createdAt", "desc")
                .where("liveStatus", "==", liveStatus)
                .startAfter(lastDoc)
                .limit(1);
                
            const moreSnapshot = await moreQuery.get();
            setHasMore(!moreSnapshot.empty);
            
            // Map the documents to data objects
            let tasksData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            
            // Filter by employee if selected
            if (selectedEmpID && selectedEmpID !== "all") {
                tasksData = tasksData.filter(item => 
                    item.assignees && item.assignees.includes(selectedEmpID)
                );
            }
            
            // Update the tasks list
            if (loadMore) {
                setTasksList(prev => [...prev, ...tasksData]);
            } else {
                setTasksList(tasksData);
            }
        } catch (err) {
            console.error("Error fetching tasks:", err);
            setError("Failed to load tasks. Please try again.");
        } finally {
            setLoading(false);
            setLoadingMore(false);
        }
    };

    const loadMoreTasks = () => {
        if (!hasMore || loadingMore) return;
        fetchTasks(true);
    };

    // Filter tasks by status
    const filterTasksByStatus = (status) => {
        return tasksList.filter(item => item.status === status);
    };

    return (
        <SidebarComponent>
            <section className="page-heading sticky-top">
                <div className="container d-md-flex d-block justify-content-between">
                    <div>
                        <h3 className="text-center text-md-left">Assign a task - {taskStatus}</h3>
                    </div>
                    <form className="row mt-md-0">
                        <InputWrapper col="12" label="Employee ID">
                            <select
                                onChange={(e) => setSelectedEmpID(e.target.value)}
                                id="disabledSelect"
                                className="form-select"
                                disabled={loadingMyeliners}
                            >
                                <option>Select Employee</option>
                                <option value="all">All</option>
                                {myeliners.map((item) => (
                                    <option key={item.id} value={item.id}>
                                        {item.id}
                                    </option>
                                ))}
                            </select>
                        </InputWrapper>
                    </form>
                </div>
            </section>
            
            {error && (
                <div className="container mt-3">
                    <Alert variant="danger">{error}</Alert>
                </div>
            )}
            
            <section className="container mt-3">
                <div className="d-flex justify-content-between">
                    <OverlayAssignTask />
                    <ButtonGroup
                        size="sm"
                        className=""
                        aria-label="Basic example"
                    >
                        <Button
                            onClick={() => setLiveStatus("active")}
                            size="sm"
                            variant={`${
                                liveStatus === "active"
                                    ? "primary"
                                    : "light"
                            }`}
                        >
                            Active
                        </Button>
                        <Button
                            onClick={() => setLiveStatus("archived")}
                            size="sm"
                            variant={`${
                                liveStatus === "archived"
                                    ? "danger"
                                    : "light"
                            }`}
                        >
                            Archived
                        </Button>
                    </ButtonGroup>
                </div>
            </section>
            
            <div className="container mt-3 d-none d-md-block">
                <div className="row">
                    <div className="col-md-4">
                        <h5 className="mb-0 font-bold py-">
                            New Tasks <Badge bg="danger">{taskCounts.new}</Badge>
                        </h5>
                    </div>
                    <div className="col-md-4">
                        <h5 className="mb-0 font-bold py-">
                            In progress <Badge bg="warning" text="dark">{taskCounts.inProgress}</Badge>
                        </h5>
                    </div>
                    <div className="col-md-4">
                        <h5 className="mb-0 font-bold py-">
                            Completed <Badge bg="primary">{taskCounts.completed}</Badge>
                        </h5>
                    </div>
                </div>
            </div>
            
            {loading ? (
                <div className="container mt-5 text-center">
                    <Spinner animation="border" role="status" />
                    <p className="mt-3">Loading tasks...</p>
                </div>
            ) : (
                <section className="task-container pt-4 bg-light mt-3">
                    <div className="container">
                        {tasksList.length === 0 ? (
                            <div className="text-center py-5">
                                <p className="mb-0">No tasks found for the selected filters.</p>
                            </div>
                        ) : (
                            <div className="row">
                                <div className="col-md-4">
                                    {filterTasksByStatus("New task").length > 0 ? (
                                        filterTasksByStatus("New task").map((item) => (
                                            <CompTaskCard
                                                data={item}
                                                key={item.id}
                                            />
                                        ))
                                    ) : (
                                        <div className="p-3 text-center text-muted">
                                            <p>No new tasks</p>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-4">
                                    {filterTasksByStatus("In progress").length > 0 ? (
                                        filterTasksByStatus("In progress").map((item) => (
                                            <CompTaskCard
                                                data={item}
                                                key={item.id}
                                            />
                                        ))
                                    ) : (
                                        <div className="p-3 text-center text-muted">
                                            <p>No tasks in progress</p>
                                        </div>
                                    )}
                                </div>
                                <div className="col-md-4">
                                    {filterTasksByStatus("Completed").length > 0 ? (
                                        filterTasksByStatus("Completed").map((item) => (
                                            <CompTaskCard
                                                data={item}
                                                key={item.id}
                                            />
                                        ))
                                    ) : (
                                        <div className="p-3 text-center text-muted">
                                            <p>No completed tasks</p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        
                        {hasMore && (
                            <div className="text-center py-4">
                                <Button 
                                    variant="outline-primary" 
                                    onClick={loadMoreTasks}
                                    disabled={loadingMore}
                                >
                                    {loadingMore ? (
                                        <>
                                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="me-2" />
                                            Loading more...
                                        </>
                                    ) : (
                                        'Load More Tasks'
                                    )}
                                </Button>
                            </div>
                        )}
                    </div>
                </section>
            )}
        </SidebarComponent>
    );
};

export default AdminAssignTask;

const InputWrapper = ({ col, label, addClass, children }) => {
    return (
        <div className={"col-md-" + col + " " + addClass}>
            <div className="input-group">
                <div className="input-group-prepend">
                    <span className="input-group-text">{label} </span>
                </div>
                {children}
            </div>
        </div>
    );
};
