import React, { useEffect, useState } from "react";
import SidebarComponent from "../../Components/Shared/SidebarComponent";
import { useAuth } from "../../Hooks/useAuth";
import {
    assignedTasksCollectionRef,
    portalFeedbacksCollectionRef,
} from "../../firebase/fire-app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import CompTaskCard from "../AdminAssignTask/CompTaskCard";
import PopupAddPortalFeedback from "./PopupAddPortalFeedback";
import CompPortalFeedback from "./CompPortalFeedback";

const ViewPortalFeedback = () => {
    const { currentUser } = useAuth();
    const [empEmail, setEmpEmail] = useState("");

    useEffect(() => {
        if (currentUser) {
            setEmpEmail(currentUser.email);
        }
    }, [currentUser]);
    const [tasksList, setTasksList] = useState(null);

    const [assignedTasksList, listLoading, listError] = useCollectionData(
        portalFeedbacksCollectionRef
            .where("assignedBy", "==", empEmail)
            .orderBy("createdAt", "desc"),
        { idField: "id" }
    );

    useEffect(() => {
        if (assignedTasksList) {
            setTasksList(assignedTasksList);
        }
    }, [assignedTasksList]);

    return (
        <>
            <SidebarComponent>
                <section className="sticky-top bg-white ">
                    <div className="container">
                        <div className="d-flex align-items-center justify-content-space-between    ">
                            <h3 className="mt-3">User Feedback</h3>
                            <div className="mt-3">

                            </div>
                        </div>
                    </div>
                </section>

                <section className="profile-card-container bg-light mt-3">
                    <div className="container">
                        <div className="row">
                            {tasksList &&
                                tasksList.map((item) => (
                                    <CompPortalFeedback data={item} key={item.id} />
                                ))}
                        </div>
                    </div>
                </section>
            </SidebarComponent>
        </>
    );
};

export default ViewPortalFeedback;
