import React from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { payslipsCollectionRef } from '../../firebase/fire-app';

const CompPersonalPayslips = ({ empID }) => {
    // Only query if empID exists
    const query = empID ? payslipsCollectionRef.where('empID', '==', empID) : null;
    const [allPayslips, loading, error] = useCollectionData(query, { idField: 'id' });

    if (!empID) {
        return <div className="alert alert-warning">Employee ID not found</div>;
    }

    if (loading) {
        return <div className="alert alert-info">Loading payslips...</div>;
    }

    if (error) {
        return <div className="alert alert-danger">Error loading payslips: {error.message}</div>;
    }

    return (
        <div className="row">
            <div className="col-12">
                {allPayslips && allPayslips.length > 0 ? (
                    allPayslips.map((data, index) => (
                        <PayslipCard key={index} data={data} />
                    ))
                ) : (
                    <div className="alert alert-info">No payslips found</div>
                )}
            </div>
        </div>
    );
};

const PayslipCard = ({ data }) => {
    return (
        <a 
            className='btn btn-sm btn-primary me-2 mb-2' 
            target='_blank' 
            rel="noopener noreferrer" 
            href={data.url}
        >
            {data.payMonth}
        </a>
    );
};

export default CompPersonalPayslips;