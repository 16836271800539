import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Table } from "react-bootstrap";
import { monthlyScoresCollectionRef } from "../../../firebase/fire-app";
import { monthlyScoreMessage } from "../../../utilities/emailGenerator";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";
import { startOfWeek, endOfWeek } from "date-fns";

const MonthlyScoreEmailPopup = ({ myelinners }) => {
    const [show, setShow] = useState(false);
    const [employeeScores, setEmployeeScores] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (show) {
            fetchLatestMonthlyScores();
        }
    }, [show]);

    const fetchLatestMonthlyScores = async () => {
        setLoading(true);
        try {
            const scores = await Promise.all(
                myelinners.map(async (employee) => {
                    const scoresSnapshot = await monthlyScoresCollectionRef
                        .where("email", "==", employee.email)
                        .orderBy("year", "desc")
                        .orderBy("month", "desc")
                        .limit(1)
                        .get();

                    const latestScore = scoresSnapshot.docs[0]?.data();
                    const currentWeekStart = startOfWeek(new Date(), { weekStartsOn: 1 });
                    const currentWeekEnd = endOfWeek(currentWeekStart, { weekStartsOn: 1 });

                    return {
                        ...employee,
                        score: latestScore?.averageScore || 0,
                        month: latestScore?.month || "-",
                        year: latestScore?.year || "-",
                        weekStart: currentWeekStart,
                        weekEnd: currentWeekEnd
                    };
                })
            );
            setEmployeeScores(scores);
        } catch (error) {
            console.error("Error fetching scores:", error);
            toast.error("Failed to fetch monthly scores");
        } finally {
            setLoading(false);
        }
    };

    const handleClose = () => {
        setShow(false);
        setSelectedEmployees([]);
    };

    const handleShow = () => setShow(true);

    const handleEmployeeSelect = (email) => {
        setSelectedEmployees((prev) => {
            if (prev.includes(email)) {
                return prev.filter((e) => e !== email);
            }
            return [...prev, email];
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            setSelectedEmployees(employeeScores.map((emp) => emp.email));
        } else {
            setSelectedEmployees([]);
        }
    };

    const sendMonthlyScoreEmails = async () => {
        if (selectedEmployees.length === 0) {
            toast.warning("Please select at least one employee");
            return;
        }

        setLoading(true);
        try {
            await Promise.all(
                selectedEmployees.map(async (email) => {
                    const employee = employeeScores.find(
                        (emp) => emp.email === email
                    );
                    if (!employee) return;

                    const mailDetails = monthlyScoreMessage({
                        name: employee.name,
                        email: employee.email,
                        score: employee.score.toFixed(1),
                        mailOne: employee.email,
                        mailTwo:
                            employee.email === "zied.tayeb@myelinh.com"
                                ? ""
                                : "zied.tayeb@myelinh.com",
                        mailThree:
                            employee.email === "samaher.garbaya@myelinh.com"
                                ? ""
                                : "samaher.garbaya@myelinh.com",
                        mailFour:
                            employee.email === "shahjahan.swajan@myelinh.com"
                                ? ""
                                : "shahjahan.swajan@myelinh.com",
                    });

                    await emailjs.send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetails,
                        "ygk5Yo8QhiJUWbVZM"
                    );
                })
            );

            toast.success("Monthly score emails sent successfully");
            handleClose();
        } catch (error) {
            console.error("Error sending emails:", error);
            toast.error("Failed to send some emails");
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Button
                variant="outline-primary"
                size="sm"
                onClick={handleShow}
                className="ms-2"
            >
                Send Monthly Score Emails
            </Button>

            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Send Monthly Score Emails</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <div className="text-center py-4">Loading...</div>
                    ) : (
                        <>
                            <Form.Check
                                type="checkbox"
                                label="Select All"
                                onChange={handleSelectAll}
                                checked={
                                    selectedEmployees.length ===
                                    employeeScores.length
                                }
                                className="mb-3"
                            />
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th width="50"></th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Latest Score</th>
                                        <th>Month/Year</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeScores.map((employee) => (
                                        <tr key={employee.email}>
                                            <td className="text-center">
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={selectedEmployees.includes(
                                                        employee.email
                                                    )}
                                                    onChange={() =>
                                                        handleEmployeeSelect(
                                                            employee.email
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>{employee.name}</td>
                                            <td>{employee.email}</td>
                                            <td>
                                                {employee.score.toFixed(1)}/20
                                            </td>
                                            <td>
                                                {employee.month !== "-"
                                                    ? `${employee.month}/${employee.year}`
                                                    : "No score"}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={sendMonthlyScoreEmails}
                        disabled={loading || selectedEmployees.length === 0}
                    >
                        {loading ? "Sending..." : "Send Emails"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default MonthlyScoreEmailPopup;
