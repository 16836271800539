import React, { useState, useRef, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Overlay from "react-bootstrap/Overlay";
import { FaTasks } from "react-icons/fa";
import { BsListTask } from "react-icons/bs";
import { AiFillHdd } from "react-icons/ai";
import { FcCalendar } from "react-icons/fc";
import { FcList } from "react-icons/fc";
import { MdGroupAdd } from "react-icons/md";
import { useAuth } from "../../Hooks/useAuth";
import {
    fireStorage,
    taskAttachmentsCollectionRef,
} from "../../firebase/fire-app";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Spinner } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { webServerLive } from "../../firebase/liveServerStatus";
import { newAttachmentAddedNotification } from "../ViewClaimManagement/messageGenerator";
import BasicActionConfirmation from "../../Components/DeletePopupConfirmation/BasicActionConfirmation";

const CompAttachmentSection = ({ data, userEmail, currentUser }) => {
    const [show, setShow] = useState(false);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [fileUrl, setFileUrl] = useState(null);
    const [attachmentTitle, setAttachmentTitle] = useState("");
    const target = useRef(null);
    const attachmentDocRef = taskAttachmentsCollectionRef.doc();
    const [activeSubmit, setActiveSubmit] = useState(false);
    const [systemLoading, setSystemLoading] = useState(false);
    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        const reader = new FileReader();

        reader.onload = () => {
            console.log(reader.result);
            setUploadedFile(reader.result);
        };

        reader.readAsDataURL(file);
    }, []);

    const onFileUpload = (e) => {
        setSystemLoading(true);
        const uploadedFile = e.target.files[0];
        const fileType = uploadedFile.type;
        const fileRef = fireStorage
            .ref()
            .child("task-attachments/" + data.id)
            .child(attachmentDocRef.id + "." + fileType.split("/")[1]);
        fileRef.put(uploadedFile).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url) => {
                setFileUrl(url);
                setSystemLoading(false);
            });
        });
    };

    const [loadAttachments, loading, error] = useCollectionData(
        taskAttachmentsCollectionRef.where("taskID", "==", data.id), {idField: "id"}
    );

    useEffect(() => {
        if (fileUrl && attachmentTitle) {
            setActiveSubmit(true);
        }
    }, [fileUrl, attachmentTitle]);

    const onAttachmentAdded = () => {
        const attachmentDetails = {
            title: attachmentTitle,
            file: fileUrl,
            createdAt: new Date(),
            uploadedBy: userEmail,
            taskID: data.id,
        };
        const mailData = {
            commenter: currentUser.name,
            taskTitle: data.title,
            attachmentUrl: fileUrl,
            mailOne: data.assignees,
            mailTwo: "shahjahan.swajan@myelinh.com",
        };
        const mailDetails = newAttachmentAddedNotification(mailData);
        setSystemLoading(true);
        taskAttachmentsCollectionRef.add(attachmentDetails).then(() => {
            setShow(false);
            if (webServerLive) {
                emailjs
                    .send(
                        "service_zinxo6v",
                        "template_eqvm7mf",
                        mailDetails,
                        "ygk5Yo8QhiJUWbVZM"
                    )
                    .then(() => {
                        //
                    });
            } else {
                setSystemLoading(false);
            }
        });
    };
    return (
        <>
            <p className="font-14 text-bold mb-0">
                Attachments:{" "}
                <Button
                    variant="primary"
                    ref={target}
                    size="sm"
                    onClick={() => setShow(!show)}
                >
                    Add new attachment
                </Button>
            </p>

            {show && (
                <div className="p-3">
                    <div className="row">
                        <div className="d-flex col-md-5 mt-3 align-items-center">
                            <input
                                type="text"
                                placeholder="Enter a task name * (required)"
                                className="form-control"
                                name="title"
                                onBlur={(e) =>
                                    setAttachmentTitle(e.target.value)
                                }
                            />
                        </div>
                        <div className="d-flex col-md-4 mt-3 align-items-center">
                            <input
                                type="file"
                                placeholder="Enter a task name * (required)"
                                className="form-control"
                                onChange={(e) => onFileUpload(e)}
                                name="attFile"
                                id="attFile"
                            />
                        </div>
                        <div className="col-md-3">
                            {activeSubmit ? (
                                <button
                                    onClick={onAttachmentAdded}
                                    className="btn mt-3 btn-primary btn-xs"
                                >
                                    Add attachment
                                </button>
                            ) : (
                                <button
                                    disabled
                                    className="btn mt-3 btn-primary btn-sm"
                                >
                                    Add attachment{" "}
                                    {systemLoading && (
                                        <Spinner size="sm" animation="grow" />
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            <div className="mt-3 row">
                {loadAttachments &&
                    loadAttachments.length > 0 &&
                    loadAttachments.map((item) => (
                        <AttachmentCard data={item} />
                    ))}
            </div>
        </>
    );
};

export default CompAttachmentSection;

const AttachmentCard = ({ data }) => {
    const onAttachmentDelete =()=>{
        taskAttachmentsCollectionRef.doc(data.id).delete()
    }
    return (
        <>
            <div className="attachment-card col-md-4 mb-2  p-2 mx-2 font-14">
                <p className="font-14 mb-0">{data.title}</p>
                <a
                    href={data.file}
                    className="btn btn-xs btn-outline-primary ms-auto me-2"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    View
                </a>
                <BasicActionConfirmation onActionFunction={onAttachmentDelete} alertText="Are you sure to delete?" btnText={"Delete"} className={"btn-xs"} btnVariant={"danger"}/>
                {/* <button onClick={onAttachmentDelete} className="btn btn-xs btn-danger">Delete</button> */}
            </div>
        </>
    );
};
